import React, { useEffect } from 'react'
import useFetchStrategies from './hooks/useFetchStrategies'
import Table from '../../components/Table'
import { useMessages } from '../../utils/MessageProvider/MessagesContext'
import { Col, Container, ProgressBar, Row } from 'react-bootstrap'
import ColumnFilters from '../../components/Table/Filters/Column'
import RowFilters from '../../components/Table/Filters/Row'

const Strategies: React.FC = () => {
  const { addMessage } = useMessages()
  const {
    table: { headers, rows },
    states: { error, loading, progress },
    fetchStrategies
  } = useFetchStrategies()
  useEffect(() => {
    (async () => {
      await fetchStrategies()
    })()
  }, [fetchStrategies])

  useEffect(() => {
    if (!error?.code) return
    addMessage({
      type: 'error',
      title: 'Error',
      message: error?.data?.message || error?.data?.error?.details || error?.message || 'Something went wrong...'
    })
  }, [error?.code])
  return (
    <>
      {loading && <ProgressBar now={progress} label={`${parseInt(progress.toString())}%`} />}
      <Table headers={headers} rows={rows} rowFilterLabel={'Strategies'} />
    </>
  )
}

export default Strategies
