import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import MessagesProvider from './utils/MessageProvider'
import WalletProvider from 'src/utils/WalletProvider'
import AuthenticationPage from './page/AuthenticationPage'
import Main from './layouts/Main'

const App: React.FC = () => {
  return (
    <Router>
      <MessagesProvider>
        <WalletProvider
          ConnectPage={<AuthenticationPage />}
          LoadingPage={
            <div className="loading-container">
              <div className="loadingio-spinner-eclipse-80ful76r3w3">
                <div className="ldio-qmca8kj37i">
                  <div></div>
                </div>
              </div>
            </div>
          }
        >
          <Switch>
            <Route path="/">
              <Main />
            </Route>
          </Switch>
        </WalletProvider>
      </MessagesProvider>
    </Router>
  )
}

export default App
