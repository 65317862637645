import React from 'react'
import BridgeLayout from './BridgeLayout'
import BridgeClass, { Validator } from './BridgeClass'
import { useConfig } from '../../utils/ConfigProvider/ConfigContext'
import { useWallet } from '../../utils/WalletProvider/WalletContext'
import useProtectedState from '../../utils/hooks/useProtectedState'
import { RecordField } from '../../contractHooks/useFieldReducer'
import { ethers } from 'ethers'
import { AccountConfig } from '../../utils/ConfigProvider'
import { useQuery } from '../../utils/url'
import { useMessages } from '../../utils/MessageProvider/MessagesContext'
import MultiCall from '../../utils/Multicall/MultiCall'

type RecordFieldProp = {
  disableAt: Array<{
    network: number
    bridgeName: string
  }>
}

export type BridgeListProps = Array<{
  name: string
  label: string
  network: number
  selected?: boolean
}>

export function toFixed(num: any) {
  // const re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
  // return num.toString().match(re)[0];
  if (num < 1) {
    const re = new RegExp('^-?\\d+(?:.\\d{0,' + (5 || -1) + '})?')
    return num.toString().match(re)[0]
  } else if (num < 100) {
    const re = new RegExp('^-?\\d+(?:.\\d{0,' + (3 || -1) + '})?')
    return num.toString().match(re)[0]
  } else if (num < 1000000) {
    const re = new RegExp('^-?\\d+(?:.\\d{0,' + (2 || -1) + '})?')
    return num.toString().match(re)[0]
  } else if (num > 1000000) {
    const re = new RegExp('^-?\\d+(?:.\\d{0,' + (0 || -1) + '})?')
    return num.toString().match(re)[0]
  }
}

const bridgeFieldsConfig: RecordField<RecordFieldProp> = {
  feeManagerContract: {
    value: '',
    method: 'setFeeManagerContract',
    prop: {
      disableAt: [
        {
          network: 1,
          bridgeName: 'bscEth'
        },
        {
          network: 56,
          bridgeName: 'polyBsc'
        },
        {
          network: 1,
          bridgeName: 'BSC_ETH_RUSD'
        },
        {
          network: 1,
          bridgeName: 'AVAX_ETH_RUSD'
        },
        {
          network: 1,
          bridgeName: 'POLY_ETH_RUSD'
        },
        {
          network: 43114,
          bridgeName: 'POLY_AVAX_RUSD'
        }
      ]
    }
  },
  gasPrice: {
    value: '',
    unit: 'gwei',
    method: 'setGasPrice',
    prop: {
      disableAt: [
        {
          network: 56,
          bridgeName: 'polyBsc'
        },
        {
          network: 137,
          bridgeName: 'polyBsc'
        },
        {
          network: 56,
          bridgeName: 'BSC_ETH_RUSD'
        },
        {
          network: 1,
          bridgeName: 'BSC_ETH_RUSD'
        },
        {
          network: 137,
          bridgeName: 'POLY_AVAX_RUSD'
        },
        {
          network: 43114,
          bridgeName: 'POLY_AVAX_RUSD'
        },
        {
          network: 1,
          bridgeName: 'POLY_ETH_RUSD'
        },
        {
          network: 137,
          bridgeName: 'POLY_ETH_RUSD'
        }
      ]
    }
  },
  dailyLimit: {
    value: '',
    unit: 'ether',
    commify: true,
    method: 'setDailyLimit'
  },
  executionDailyLimit: {
    value: '',
    unit: 'ether',
    commify: true,
    method: 'setExecutionDailyLimit'
  },
  executionMaxPerTx: {
    value: '',
    unit: 'ether',
    commify: true,
    method: 'setExecutionMaxPerTx'
  },
  requiredBlockConfirmations: {
    value: '',
    method: 'setRequiredBlockConfirmations',
    prop: {
      disableAt: [
        {
          network: 56,
          bridgeName: 'polyBsc'
        },
        {
          network: 137,
          bridgeName: 'polyBsc'
        },
        {
          network: 56,
          bridgeName: 'BSC_ETH_RUSD'
        },
        {
          network: 1,
          bridgeName: 'BSC_ETH_RUSD'
        },
        {
          network: 137,
          bridgeName: 'POLY_AVAX_RUSD'
        },
        {
          network: 43114,
          bridgeName: 'POLY_AVAX_RUSD'
        },
        {
          network: 137,
          bridgeName: 'POLY_ETH_RUSD'
        },
        {
          network: 1,
          bridgeName: 'POLY_ETH_RUSD'
        }
      ]
    }
  },
  maxPerTx: {
    value: '',
    unit: 'ether',
    commify: true,
    method: 'setMaxPerTx'
  },
  minPerTx: {
    value: '',
    unit: 'ether',
    commify: true,
    method: 'setMinPerTx'
  },
  getHomeFee: {
    value: '',
    unit: 'ether',
    method: 'setHomeFee',
    prop: {
      disableAt: [
        {
          network: 56,
          bridgeName: 'polyBsc'
        },
        {
          network: 1,
          bridgeName: 'bscEth'
        },
        {
          network: 1,
          bridgeName: 'BSC_ETH_RUSD'
        },
        {
          network: 1,
          bridgeName: 'POLY_ETH_RUSD'
        },
        {
          network: 1,
          bridgeName: 'AVAX_ETH_RUSD'
        },
        {
          network: 43114,
          bridgeName: 'POLY_AVAX_RUSD'
        }
      ]
    }
  },
  getForeignFee: {
    value: '',
    unit: 'ether',
    method: 'setForeignFee',
    prop: {
      disableAt: [
        {
          network: 56,
          bridgeName: 'polyBsc'
        },
        {
          network: 1,
          bridgeName: 'bscEth'
        },
        {
          network: 1,
          bridgeName: 'BSC_ETH_RUSD'
        },
        {
          network: 1,
          bridgeName: 'POLY_ETH_RUSD'
        }
      ]
    }
  }
}

const bridgeSampleList: BridgeListProps = [
  // {
  //   name: 'bscEth',
  //   label: 'Ethereum (Ramp)',
  //   network: 1
  // },
  // {
  //   name: 'bscEth',
  //   label: 'BSC (Ramp)',
  //   network: 56
  // },
  // {
  //   name: 'polyBsc',
  //   label: 'BSC (rUSD)',
  //   network: 56
  // },
  {
    name: 'BSC_ETH_RUSD',
    label: 'BSC ETH RUSD HOME',
    network: 56
  },
  {
    name: 'BSC_ETH_RUSD',
    label: 'BSC ETH RUSD FOREIGN',
    network: 1
  },
  {
    name: 'AVAX_ETH_RUSD',
    label: 'AVAX ETH RUSD HOME',
    network: 43114
  },
  {
    name: 'AVAX_ETH_RUSD',
    label: 'AVAX ETH RUSD FOREIGN',
    network: 1
  },
  {
    name: 'POLY_ETH_RUSD',
    label: 'POLY ETH RUSD HOME',
    network: 137
  },
  {
    name: 'POLY_ETH_RUSD',
    label: 'POLY ETH RUSD FOREIGN',
    network: 1
  },
  {
    name: 'POLY_AVAX_RUSD',
    label: 'POLY AVAX RUSD HOME',
    network: 137
  },
  {
    name: 'POLY_AVAX_RUSD',
    label: 'POLY AVAX RUSD FOREIGN',
    network: 43114
  }
  // {
  //   name: 'polyBsc',
  //   label: 'Polygon (rUSD)',
  //   network: 137
  // },
  // {
  //   name: 'avaxBsc',
  //   label: 'Avalanche (rUSD)',
  //   network: 43114
  // }
]

const Bridge: React.FC = () => {
  const { wallet, sdk } = useWallet()
  const { config } = useConfig()
  const { addMessage } = useMessages()
  const { query, setQuery } = useQuery()

  const configBridge = Object.keys(config?.bridge ?? {}).map((value, index, array) => {
    return value
  })
  const queryConfigBridgeHandler = React.useMemo(() => {
    return config.bridge?.[query.name] ? config.bridge?.[query.name] : config.bridge?.[configBridge[0]]
  }, [config, wallet.network])

  const validatorsAccountList: Record<string, AccountConfig> = {}
  for (const name in config.accounts) {
    if (config.accounts[name].type === 'validator') validatorsAccountList[name] = config.accounts[name]
  }

  React.useEffect(() => {
    if (!config.bridge?.[query.name]) {
      setQuery('name', configBridge[0])
    }
  }, [wallet.network])

  const bridgeClass = new BridgeClass(
    sdk,
    queryConfigBridgeHandler.address,
    queryConfigBridgeHandler.abi,
    validatorsAccountList
  )
  const [isValidatorsLoading, setIsValidatorsLoading] = React.useState<boolean>(false)
  const [isFieldsLoading, setIsFieldsLoading] = React.useState<boolean>(false)
  const [validators, setValidators, cancelSetValidators] = useProtectedState<Record<string, Validator>>({})
  const bridgeInfos = bridgeClass.outputBridgeInfo()
  const ethereum = (window as any).ethereum
  const provider = new ethers.providers.Web3Provider(ethereum)
  const [fallbackResults, setFallbackResults] = React.useState<Record<string, any>>({})
  const [onFailTrigger, setOnFailTrigger] = React.useState<boolean>(false)

  const newFields = React.useMemo(() => {
    return bridgeFieldsConfig
  }, [fallbackResults, isFieldsLoading, onFailTrigger])

  function validatorsSetter() {
    setIsValidatorsLoading(true)
    bridgeClass
      .fetchValidatorsRampBalance(wallet.network, config.tokens?.Ramp?.address)
      .then((_validators) => {
        console.log('_v: ', _validators)
        setValidators(_validators)
      })
      .finally(() => {
        setIsValidatorsLoading(false)
        // loading to false
      })
    return () => {
      cancelSetValidators()
    }
  }

  React.useEffect(() => {
    let mounted = true

    if (mounted) {
      validatorsSetter()
    }

    return function cleanup() {
      mounted = false
    }
  }, [query.name])

  function valueConverter(unit: string | undefined, value: string, commify: boolean | undefined) {
    let convertedValue: any
    if (unit) {
      if (unit === 'gwei') {
        convertedValue = parseFloat(ethers.utils.formatUnits(value, 'gwei')).toFixed(1)
      }
      if (unit === 'ether') {
        convertedValue = parseFloat(ethers.utils.formatUnits(value, 'ether'))
      }
    } else {
      convertedValue = value
    }

    if (commify) {
      try {
        return toFixed(convertedValue)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      } catch (e) {
        return convertedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
    } else {
      return convertedValue
    }
  }

  function resetFields() {
    Object.entries(bridgeFieldsConfig).map(([fieldLabel, infos], index) => {
      infos.value = ''
    })
  }

  function fieldSetter() {
    const txs: any = []
    setIsFieldsLoading(true)
    resetFields()

    // Get only enabled fields
    const filteredConfig = Object.entries(bridgeFieldsConfig).filter(([configLabel, infos], config_index) => {
      if (query?.name === undefined) return false
      const finder = infos.prop?.disableAt.find((r) => wallet.network === r.network && query.name === r.bridgeName)
      if (finder) {
        infos.disabled = true
      } else {
        infos.disabled = false
      }
      return !finder
    })

    // loop through filtered list to create a tx call
    filteredConfig.map(([label, info], index) => {
      bridgeFieldsConfig[label].loading = true
      txs.push({
        address: queryConfigBridgeHandler.address,
        abi: queryConfigBridgeHandler.abi,
        method: label
      })
    })

    const multiCallPromise = MultiCall(provider, wallet.network, txs)

    multiCallPromise
      .then((result: any) => {
        // Using filtered list, set value with its corresponding result via index
        filteredConfig.map(([label, field], index: number) => {
          if (!result[index]) {
            bridgeFieldsConfig[label].disabled = true
          }
          const newRes = valueConverter(field.unit, result[index], field.commify)
          bridgeFieldsConfig[label].loading = false
          bridgeFieldsConfig[label].value = newRes ? newRes : result[index]
          // console.log('the result: ',label,  result[index])
        })
      })
      .catch((err) => {
        console.error('Multicall error')
        console.error(err)
        const fallbackTx: any = {}
        txs.map((tx: any, index: number) => {
          const fallbackContract = new ethers.Contract(tx.address, tx.abi, provider)
          const fulfilledTx: any = []
          fallbackContract[tx.method]().then((r: any) => {
            setFallbackResults((prevState: any) => ({
              ...prevState,
              [tx.method]: r
            }))
          })
          console.log('fulfilledTx: ', fulfilledTx)
          // setFallbackResults((prevState: any) => ({
          //   ...prevState,
          //   [tx.method]: fulfilledTx
          // }))
        })

        setOnFailTrigger(true)
      })
      .finally(() => {
        setIsFieldsLoading(false)
        setOnFailTrigger(false)
      })
  }

  function methodApply(methodName: any, value: any) {
    console.log('methodName: ', methodName, ' value: ', value)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    bridgeClass[methodName](value).catch((err: any) => {
      console.error(err)
      addMessage({
        type: 'error',
        title: 'Error on action',
        message: `${err.data?.message || err.message} => {method: ${methodName}, value: ${value}} `
      })
    })
  }

  React.useEffect(() => {
    fieldSetter()
  }, [query.name])

  function onRefresh() {
    validatorsSetter()
    fieldSetter()
  }

  React.useEffect(() => {
    Object.entries(fallbackResults).map(([label, value], index) => {
      console.log('THE INFO: ', value)
      if (value !== undefined) {
        bridgeFieldsConfig[label].value = valueConverter(
          bridgeFieldsConfig[label].unit,
          value,
          bridgeFieldsConfig[label].commify
        )
      }
    })
  }, [fallbackResults, onFailTrigger])

  return (
    <>
      <BridgeLayout
        validators={validators}
        fields={newFields}
        isValidatorsLoading={isValidatorsLoading}
        isFieldsLoading={isFieldsLoading}
        onRefresh={onRefresh}
        bridgeInfos={{
          ...bridgeInfos
          // TODO: ADD HOME AND FOREIGN BRIDGE IDENTIFIER HERE
        }}
        onApply={methodApply}
        bridgeSampleList={bridgeSampleList}
      />
    </>
  )
}

const Index = () => {
  const { config } = useConfig()
  if (!config.bridge)
    return (
      <div>
        <h4>Bridge not yet set up</h4>
        <span className="mb-4">The bridges are still under construction for this network</span>
      </div>
    )

  return <Bridge />
}

export default Index
