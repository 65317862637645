import { useConfig } from '../../utils/ConfigProvider/ConfigContext'
import useProtectedState from '../../utils/hooks/useProtectedState'
import React from 'react'
import { useWallet } from '../../utils/WalletProvider/WalletContext'
import ScanSdk, { EventLog } from './ScanSdk'
import { ethers } from 'ethers'
import { useMessages } from '../../utils/MessageProvider/MessagesContext'

export type SearchParams = {
  userAddr: string
  tokenAddr: string
  daysBefore: number
}

export const TOPICS = {
  Deposit: '0xe172bdfb015d23d2f483f0d54914dd2d4a7d6fe181476343880d299d827566ae',
  Withdraw: '0xf341246adaac6f497bc2a656f546ab9e182111d630394f0c57c710a59a2cb567',
  Borrow: '0xc1561b330e73faa7d5d1ac03c968d8f359b0191ccdb9cc002cf7d8eb6ae038cb',
  Repay: '0x0c09dc759d12ec625ff730cb4f6eaf6030a78f5eebe668b1017ea3955583016a',
  Liquidate: '0x81749dfaca15de9da21f7b95c96f27ca0cd78a345932577022746848573cb861'
}

const useScan = () => {
  const { addMessage } = useMessages()
  const { wallet } = useWallet()
  const { config } = useConfig()
  const [isFetching, setFetching] = React.useState(false)
  const [completion, setCompletion] = React.useState(100)
  const [daysBefore, setDaysBefore] = React.useState<number>(60)
  const [eventLogs, setEventLogs, cancelEventLogs] = useProtectedState<EventLog[]>([])
  const scanSdk = new ScanSdk(wallet.network, config.contracts.Bank.address, config.contracts.Vault.address)

  function onComplete(res: any) {
    setCompletion((prevState) => prevState + 20)
    return res
  }

  function onFail(err: any, message: string) {
    console.error(err)
    setCompletion((prevState) => prevState + 20)
    addMessage({
      title: 'Fetching Event Logs',
      type: 'error',
      message
    })
    return []
  }

  async function fetchEventLogs(search: SearchParams) {
    console.log('fetching event logs')
    setFetching(true)
    setCompletion(0)
    let userAddr
    if (search.userAddr) {
      if (ethers.utils.isHexString(search.userAddr)) {
        userAddr = ethers.utils.hexZeroPad(search.userAddr, 32)
      } else {
        addMessage({
          title: 'Fetching Event Logs',
          type: 'warning',
          message: 'User Address is not valid!'
        })
        setCompletion(100)
        setFetching(false)
        return
      }
    }
    Promise.all([
      await scanSdk
        .fetchDepositLogs(search.daysBefore, ethers.utils.hexZeroPad(search.tokenAddr, 32), userAddr)
        .then(onComplete),
      await scanSdk
        .fetchWithdrawLogs(search.daysBefore, ethers.utils.hexZeroPad(search.tokenAddr, 32), userAddr)
        .then(onComplete),
      await scanSdk
        .fetchBorrowLogs(search.daysBefore, ethers.utils.hexZeroPad(search.tokenAddr, 32), userAddr)
        .then(onComplete),
      await scanSdk
        .fetchRepayLogs(search.daysBefore, ethers.utils.hexZeroPad(search.tokenAddr, 32), userAddr)
        .then(onComplete),
      await scanSdk
        .fetchLiquidateLogs(search.daysBefore, ethers.utils.hexZeroPad(search.tokenAddr, 32), userAddr)
        .then(onComplete)
    ])
      .then((eventLogs) => ([] as EventLog[]).concat(...eventLogs))
      .then((eventLogs) => ScanSdk.arrangeEventLogsByBlockNumber(eventLogs))
      .then((eventLogs) => {
        setEventLogs(eventLogs)
      })
      .catch((err: Error) => {
        addMessage({
          title: 'Fetching Event Logs',
          type: 'error',
          message: err?.message || 'Error fetching logs'
        })
      })
      .finally(() => {
        // setCompletion(100)
        setFetching(false)
      })

    return () => {
      cancelEventLogs()
    }
  }

  return {
    eventLogs,
    fetchEventLogs,
    completion,
    isFetching,
    daysBefore,
    setDaysBefore
  }
}

export default useScan
