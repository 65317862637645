import { ListGroup } from 'react-bootstrap'
import React from 'react'
import { useQuery } from '../../utils/url'

interface Props {
  tokenIndex: number
  strategyLabel:
    | {
        strategyName: string
        strategyId: string
      }
    | undefined
  tokenName: string
  tokenStrategy: string
}

const StrategyListItem: React.FC<Props> = ({ tokenIndex, tokenName, strategyLabel, tokenStrategy }) => {
  const { setQuery } = useQuery()
  return (
    <ListGroup.Item
      className="strategy-list-item"
      href={tokenIndex + ''}
      onClick={() => {
        setQuery('tokenId', tokenIndex + '')
      }}
    >
      {strategyLabel ? (
        <div className="strategy-list-title">
          <span className="strategy-list-id">{strategyLabel.strategyId}</span>
          <span className="strategy-list-name">{strategyLabel.strategyName}</span>
        </div>
      ) : (
        <span>
          {tokenStrategy} ({tokenName})
        </span>
      )}
    </ListGroup.Item>
  )
}

export default StrategyListItem
