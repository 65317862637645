import React from 'react'
import ContainerLabeler from '../../components/ContainerLabeler'
import { Col, Dropdown, FormLabel, InputGroup, Row } from 'react-bootstrap'
import SearchContainer from './SearchContainer'
import Events from './Events'
import { useQuery } from '../../utils/url'
import { EventLog } from '../../helpers/scanEvents/ScanSdk'

type TokenInfo = {
  name: string
  address: string
}

type Props = {
  tokenOptions: TokenInfo[]
  isLoading: boolean
  eventLogs: EventLog[]
  completion: number
}

const ExplorerLayout: React.FC<Props> = ({ tokenOptions, isLoading, eventLogs, completion }) => {
  const { query, setQuery } = useQuery()
  const tokenAddr = query.tokenAddr
  const tokenName = tokenOptions.find((t) => t.address === tokenAddr)?.name || ''

  React.useEffect(() => {
    if (!query.tokenAddr) setQuery('tokenAddr', tokenOptions[0].address)
    const foundToken = tokenOptions.find((t) => t.address === query.tokenAddr)
    if (foundToken) return
    setQuery('tokenAddr', tokenOptions[0].address)
  }, [query.tokenAddr, tokenOptions])

  return (
    <Row className="explorer-container">
      <ContainerLabeler label={'Input Token'}>
        <InputGroup className="explorer-strategy-toggle-group">
          <FormLabel className="explorer-strategy-toggle-group_label me-3">Token Address: </FormLabel>
          <Dropdown className="ms-3">
            <Dropdown.Toggle className="dropdown-toggle">
              <span className="px-2">
                {tokenAddr} ({tokenName})
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {}
              {tokenOptions.map(({ name, address }, token_index) => {
                return (
                  <Dropdown.Item
                    key={token_index}
                    onClick={() => {
                      setQuery('tokenAddr', address)
                    }}
                  >
                    {name}
                  </Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
        </InputGroup>
      </ContainerLabeler>
      <Col>
        <ContainerLabeler label={'Events'}>
          <SearchContainer tokenAddr={tokenAddr} />
        </ContainerLabeler>
        <div>{isLoading ? <div>Loading ... {completion}%</div> : <Events events={eventLogs} />}</div>
      </Col>
    </Row>
  )
}

export default ExplorerLayout
