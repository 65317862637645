import React from 'react'

export type Message = {
  type: 'success' | 'info' | 'warning' | 'error'
  title?: string
  message: string
}

export const MessagesContext = React.createContext({
  messages: [] as Message[],
  addMessage: (message: Message): void => {
    // silent
  },
  removeMessage: (index: number): void => {
    // silent
  }
})

export const useMessages = () => {
  const context = React.useContext(MessagesContext)
  //
  return context
}
