import { IWallet, Options, Wallet } from './IWallet'
import SafeSdk, { SafeInfo } from '@gnosis.pm/safe-apps-sdk'
import { SafeAppProvider } from '@gnosis.pm/safe-apps-provider'

class Gnosis implements IWallet {
  private readonly _sdk: SafeSdk

  constructor() {
    this._sdk = new SafeSdk({
      whitelistedDomains: [/gnosis-safe.com/]
    })
  }

  connect(): Promise<void> {
    return Promise.resolve(undefined)
  }

  async connected(timeout?: number): Promise<Wallet | undefined> {
    const _safeInfo = await Promise.race([
      this._sdk.getSafeInfo(),
      new Promise((resolve) => {
        setTimeout(resolve, timeout || 1000)
      })
    ])

    if (!_safeInfo) return
    const safeInfo = _safeInfo as SafeInfo

    // @ts-ignore
    const provider = new SafeAppProvider(safeInfo as SafeInfo, this._sdk)

    return {
      type: 'sdk',
      name: 'Gnosis Safe',
      address: safeInfo.safeAddress,
      network: provider.chainId,
      provider
    }
  }

  send(address: string, abi: any[], method: string, args: any[], opts?: Options): Promise<any | boolean> {
    console.log('Gnosis Safe send, Coming Soon!')
    return Promise.resolve(undefined)
    // const data = this.encodeData(abi, method, args)
    // return this._sdk.txs.send({
    //   txs: [
    //     {
    //       to: address,
    //       value: '0',
    //       data
    //     }
    //   ]
    // }).then(() => {
    //
    // })
  }

  // private encodeData(abi: any[], method: string, args: any[]): string {
  //   return '0x00'
  // }

  async getLatestBlockNr(): Promise<number> {
    return 0
  }
}

export default Gnosis
