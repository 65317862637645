import React, { ChangeEvent } from 'react'
import ContainerLabeler from '../../../components/ContainerLabeler'
import Input from '../../../components/Input'
import { Button, Col, Dropdown, FormLabel, InputGroup, Row } from 'react-bootstrap'
import { values } from 'lodash'
import { useConfig } from '../../../utils/ConfigProvider/ConfigContext'

interface LiquidationEventsFilterProps {
  account: string
  token: string
  onAccountChange: (account: string) => void
  onTokenChange: (token: string) => void
}

interface FieldProps<V = string | number | boolean> {
  label: string
  onChange: (value: string) => void
  value: string
  options?: Record<any, V>
}

interface TokenDropDownProps {
  onSelect: (value: string) => void
  value: string
}

const Field: React.FC<FieldProps> = ({ label, onChange, value, options }) => {
  const labelReplacer = label.replace(/([A-Z])/g, ' $1')
  const convertedLabel = labelReplacer.charAt(0).toUpperCase() + labelReplacer.slice(1)
  return (
    <div className="container-fluid mb-3 fieldContainer mt-3">
      <Col className="mb-2 ">
        <label className="label-custom-text">{convertedLabel}</label>
      </Col>
      <Col>
        <Input label={convertedLabel} value={value} onChange={(value: string) => onChange(value)} options={options} />
      </Col>
    </div>
  )
}

const TokenDropDown: React.FC<TokenDropDownProps> = ({ onSelect, value }) => {
  const { config } = useConfig()
  const tokenOptions = Object.entries(config.tokens).map(([name, { address }]) => {
    return { name, address }
  })

  const tokenAddr = value
  const tokenName = tokenOptions.find((t) => t.address === tokenAddr)?.name || 'All'

  return (
    <div className="container-fluid mb-3 fieldContainer mt-3">
      <InputGroup className="explorer-strategy-toggle-group">
        <FormLabel className="explorer-strategy-toggle-group_label">Token Address: </FormLabel>
        <Dropdown className="ms-3">
          <Dropdown.Toggle className="dropdown-toggle">
            <span className="px-2">
              {tokenAddr} ({tokenName})
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {}
            {tokenOptions.map(({ name, address }, token_index) => {
              return (
                <Dropdown.Item
                  key={token_index}
                  onClick={() => {
                    onSelect(address)
                  }}
                >
                  {name}
                </Dropdown.Item>
              )
            })}
          </Dropdown.Menu>
        </Dropdown>
      </InputGroup>
    </div>
  )
}

const LiquidationEventsFilter: React.FC<LiquidationEventsFilterProps> = ({
  account,
  token,
  onAccountChange,
  onTokenChange
}) => {
  return (
    <div className={'mt-3'}>
      <ContainerLabeler label={'Liquidation Event Filter'}>
        <Field label={'Account'} onChange={(value) => onAccountChange(value)} value={account} />
        <TokenDropDown onSelect={onTokenChange} value={token} />
        <div className="container-fluid mb-3 fieldContainer mt-3">
          <Button
            onClick={() => {
              onTokenChange('')
              onAccountChange('')
            }}
          >
            Reset Filters
          </Button>
        </div>
      </ContainerLabeler>
    </div>
  )
}

export default LiquidationEventsFilter
