import { ethers } from 'ethers'
import settings from '../api/settings.json'
import { fetchLogs, fetchLogsMaxHandler } from '../api'
import { Omit } from 'react-bootstrap/helpers'
import { getBlockNumberFromTimestamp, getCurrentTimeStamp } from '../utils'
import moment from 'moment'

export type FormatType = 'address' | 'boolean' | 'int' | 'ether' | 'gwei'

export type ParsedHexValue = string | number | boolean | undefined

export type EventDataLog = Record<string, ParsedHexValue>

export interface EventLog {
  eventName: string
  account: string
  data: EventDataLog
  token: string
  timestamp: string
  blockNumber: number
}

export const TOPICS = {
  Deposit: '0xe172bdfb015d23d2f483f0d54914dd2d4a7d6fe181476343880d299d827566ae',
  Withdraw: '0xf341246adaac6f497bc2a656f546ab9e182111d630394f0c57c710a59a2cb567',
  Borrow: '0xc1561b330e73faa7d5d1ac03c968d8f359b0191ccdb9cc002cf7d8eb6ae038cb',
  Repay: '0x0c09dc759d12ec625ff730cb4f6eaf6030a78f5eebe668b1017ea3955583016a',
  Liquidate: '0x0661566a836c2d45c02e28816c5d4958d7684b29ab0cfcb1025ff7eed3000e7a'
}

class ScanSdk {
  private _network: number
  private _bankAddr: string
  private _vaultAddr: string
  private _fromBlock: string
  private _toBlock = 'latest'

  constructor(network: number, bankAddress: string, vaultAddress: string) {
    this._network = network
    this._bankAddr = bankAddress
    this._vaultAddr = vaultAddress
    this._fromBlock = (settings as Record<
      number,
      {
        fromBlock: number
      }
    >)[network].fromBlock.toString()
  }

  private static formatHexValue(hexValue: string, format: string): string | number | boolean {
    if (format === 'address') {
      return '0x' + hexValue.substring(26, 66)
    } else if (format === 'boolean') {
      return ethers.utils.hexStripZeros(hexValue) === '0x1'
    } else if (format === 'int') {
      return Number.parseInt(ethers.utils.hexStripZeros(hexValue))
    } else if (['ether', 'gwei'].includes(format)) {
      if (ethers.utils.hexStripZeros(hexValue) === '0x') return '0'
      const bn = ethers.BigNumber.from(ethers.utils.hexStripZeros(hexValue))
      return ethers.utils.formatUnits(bn, format)
    } else {
      return hexValue
    }
  }

  private static formatArrayHexValues(
    rawEventData: any[],
    format: FormatType[],
    keys?: string[]
  ): Array<ParsedHexValue> | Record<string, ParsedHexValue> {
    const arrValues: ParsedHexValue[] = []
    for (const index in rawEventData) {
      if (format[index]) arrValues[index] = ScanSdk.formatHexValue(rawEventData[index], format[index])
      else arrValues[index] = undefined
    }
    if (keys) {
      const objValues: Record<string, ParsedHexValue> = {}
      for (const index in arrValues) {
        if (keys[index]) objValues[keys[index]] = arrValues[index]
      }
      return objValues
    }
    return arrValues
  }

  private static formatISOTimestamp(hexTimestamp: string): string {
    const time = (ScanSdk.formatHexValue(hexTimestamp, 'int') as number) * 1000
    const momentDate = moment(time).format('L')
    const momentTime = moment(time).format('LTS')
    return `${momentDate} ${momentTime}`
  }

  private static parseEventLog(
    rawEventLog: any,
    eventName?: string,
    data?: EventDataLog
  ): Omit<EventLog, 'data' | 'eventName'> {
    return {
      eventName: eventName || '',
      data: data || {},
      account: ScanSdk.formatHexValue(rawEventLog.topics[2], 'address') as string,
      token: ScanSdk.formatHexValue(rawEventLog.topics[1], 'address') as string,
      timestamp: ScanSdk.formatISOTimestamp(rawEventLog.timeStamp).replace('T', ' '),
      blockNumber: ScanSdk.formatHexValue(rawEventLog.blockNumber, 'int') as number
    }
  }

  static arrangeEventLogsByBlockNumber(eventLogs: EventLog[]) {
    return eventLogs.sort((eventLogA, eventLogB) => {
      return eventLogB.blockNumber - eventLogA.blockNumber
    })
  }

  static chunkHexString(str: string, length: number) {
    return str.match(new RegExp('.{1,' + length + '}', 'g'))?.map((str) => '0x' + str)
  }

  async getDelayedBlockNumber(daysBefore: number) {
    const delayedTimestamp = getCurrentTimeStamp(daysBefore)

    return await getBlockNumberFromTimestamp(this._network, delayedTimestamp)
  }

  async fetchDepositLogs(daysBefore: number, tokenAddr?: string, userAddr?: string) {
    return fetchLogsMaxHandler(
      this._network,
      this._vaultAddr,
      await this.getDelayedBlockNumber(daysBefore),
      this._toBlock,
      [TOPICS.Deposit, tokenAddr, userAddr]
    )
      .then((rawEventLogs: any[]): EventLog[] => {
        return rawEventLogs.map((rawEventLog) => {
          return {
            ...ScanSdk.parseEventLog(rawEventLog),
            eventName: 'Deposit',
            data: ScanSdk.formatArrayHexValues(
              ScanSdk.chunkHexString(rawEventLog.data.substr(2, rawEventLog.data.length), 64) || [],
              ['address', 'ether', 'boolean'],
              ['Mint Target', 'Amount', 'Auto Stake']
            ) as EventDataLog
          }
        })
      })
      .then((eventLogs) => ScanSdk.arrangeEventLogsByBlockNumber(eventLogs))
  }

  async fetchWithdrawLogs(daysBefore: number, tokenAddr?: string, userAddr?: string) {
    return fetchLogsMaxHandler(
      this._network,
      this._vaultAddr,
      await this.getDelayedBlockNumber(daysBefore),
      this._toBlock,
      [TOPICS.Withdraw, tokenAddr, userAddr]
    )
      .then((rawEventLogs: any[]): EventLog[] => {
        return rawEventLogs.map((rawEventLog) => {
          return {
            ...ScanSdk.parseEventLog(rawEventLog),
            eventName: 'Withdraw',
            data: ScanSdk.formatArrayHexValues(
              ScanSdk.chunkHexString(rawEventLog.data.substr(2, rawEventLog.data.length), 64) || [],
              ['ether', 'ether'],
              ['RToken Burned', 'Amount Withdrawn']
            ) as EventDataLog
          }
        })
      })
      .then((eventLogs) => ScanSdk.arrangeEventLogsByBlockNumber(eventLogs))
  }

  async fetchBorrowLogs(daysBefore: number, tokenAddr?: string, userAddr?: string) {
    return fetchLogsMaxHandler(
      this._network,
      this._bankAddr,
      await this.getDelayedBlockNumber(daysBefore),
      this._toBlock,
      [TOPICS.Borrow, tokenAddr, userAddr]
    )
      .then((rawEventLogs: any[]): EventLog[] => {
        return rawEventLogs.map((rawEventLog) => {
          return {
            ...ScanSdk.parseEventLog(rawEventLog),
            eventName: 'Borrow',
            data: ScanSdk.formatArrayHexValues(
              ScanSdk.chunkHexString(rawEventLog.data.substr(2, rawEventLog.data.length), 64) || [],
              ['ether', 'ether'],
              ['Amount', 'Price']
            ) as EventDataLog
          }
        })
      })
      .then((eventLogs) => ScanSdk.arrangeEventLogsByBlockNumber(eventLogs))
  }

  async fetchRepayLogs(daysBefore: number, tokenAddr?: string, userAddr?: string) {
    return fetchLogsMaxHandler(
      this._network,
      this._bankAddr,
      await this.getDelayedBlockNumber(daysBefore),
      this._toBlock,
      [TOPICS.Repay, tokenAddr, userAddr]
    )
      .then((rawEventLogs: any[]): EventLog[] => {
        return rawEventLogs.map((rawEventLog) => {
          return {
            ...ScanSdk.parseEventLog(rawEventLog),
            eventName: 'Repay',
            data: ScanSdk.formatArrayHexValues(
              ScanSdk.chunkHexString(rawEventLog.data.substr(2, rawEventLog.data.length), 64) || [],
              ['ether', 'ether', 'ether', 'ether'],
              ['RToken Returned', 'RUsd Burned', 'Interest Paid', 'Price']
            ) as EventDataLog
          }
        })
      })
      .then((eventLogs) => ScanSdk.arrangeEventLogsByBlockNumber(eventLogs))
  }

  async fetchLiquidateLogs(daysBefore: number, tokenAddr?: string, userAddr?: string) {
    return fetchLogsMaxHandler(this._network, this._bankAddr, await this.getDelayedBlockNumber(daysBefore), 'latest', [
      TOPICS.Liquidate,
      tokenAddr,
      userAddr
    ])
      .then((rawEventLogs: any[]): EventLog[] => {
        return rawEventLogs.map((rawEventLog) => {
          return {
            ...ScanSdk.parseEventLog(rawEventLog),
            eventName: 'Liquidate',
            data: ScanSdk.formatArrayHexValues(
              ScanSdk.chunkHexString(rawEventLog.data.substr(2, rawEventLog.data.length), 64) || [],
              ['ether', 'gwei', 'int', 'ether'],
              ['Usd Amount', 'Asset Price', 'Collateral Ratio', 'Assets Liquidated']
            ) as EventDataLog
          }
        })
      })
      .then((eventLogs) => ScanSdk.arrangeEventLogsByBlockNumber(eventLogs))
  }
}

export default ScanSdk
