import React from 'react'
import LiquidationLayout from './LiquidationLayout'
import { useWallet } from '../../../utils/WalletProvider/WalletContext'
import { useConfig } from '../../../utils/ConfigProvider/ConfigContext'
import { useQuery } from '../../../utils/url'
import useProtectedState from '../../../utils/hooks/useProtectedState'
import { useMessages } from '../../../utils/MessageProvider/MessagesContext'
import ScanSdk, { EventLog } from '../../../helpers/scanEvents/ScanSdk'
import ContainerLabeler from '../../../components/ContainerLabeler'
import LiquidationSettings from './LiquidationSettings'
import { RecordField } from '../../../contractHooks/useFieldReducer'
import { ethers } from 'ethers'
import LiquidationEventsFilter from './LiquidationEventsFilter'

const LIQUIDATED_RECEIVER_ADDRESS = '0x58092273a044D6e1d23B5095AE873F6E24E906ed'

const liquidationSettingsConfig: RecordField = {
  liquidatedReceiverBalance: {
    value: '',
    infoText: '',
    optionalLabel: 'Treasury rUSD Balance'
  },
  burnLiquidatedRUsd: {
    value: '',
    method: 'burnRUsd',
    unit: 'ether',
    optionalLabel: 'Burn RUSD'
  }
}

const Liquidation: React.FC = () => {
  const { addMessage } = useMessages()
  const { wallet, sdk } = useWallet()
  const { config } = useConfig()
  const { query } = useQuery()
  const scanSdk = new ScanSdk(wallet.network, config.contracts.Bank.address, config.contracts.Vault.address)
  const [events, setEvents] = useProtectedState<EventLog[]>([])
  const [isLoading, setLoading] = React.useState(false)
  const [localLiquidationSettings, setLocalLiquidationSettings] = React.useState<RecordField>(liquidationSettingsConfig)
  const [liquidatedReceiverBalanceValue, setLiquidatedReceiverBalanceValue] = React.useState<string>(
    LIQUIDATED_RECEIVER_ADDRESS
  )
  const [liquidatedReceiverAddress, setLiquidatedReceiverAddress] = React.useState<string>(LIQUIDATED_RECEIVER_ADDRESS)
  const [liquidatedAccount, setLiquidatedAccount] = React.useState('')
  const [liquidatedToken, setLiquidatedToken] = React.useState('')

  React.useEffect(() => {
    setLoading(true)
    setEvents([])
    scanSdk
      .fetchLiquidateLogs(60, query.tokenAddr, '')
      .then((events) => {
        setEvents(events)
      })
      .catch((err) => {
        addMessage({
          title: 'Liquidation Tool',
          type: 'error',
          message: err.data?.message || err.message
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [wallet.network])

  React.useEffect(() => {
    console.log('liquid: ', liquidatedReceiverBalanceValue)
  }, [liquidatedReceiverBalanceValue])

  React.useEffect(() => {
    if (!liquidatedReceiverAddress) return
    getBalance()
  }, [wallet, sdk, liquidatedReceiverBalanceValue])

  function getBalance() {
    sdk
      .send(config.contracts.RUSD.address, 'balanceOf', [liquidatedReceiverAddress], {
        abi: config.contracts.RUSD.abi
      })
      .then((res) => {
        console.log('Liquidated Receiver rUSD Balance: ', ethers.utils.formatUnits(res, 'ether'))
        setLocalLiquidationSettings((prevState) => {
          return {
            ...prevState,
            liquidatedReceiverBalance: {
              infoText: liquidatedReceiverBalanceValue,
              value: ethers.utils.formatUnits(res.toString(), 'ether'),
              optionalLabel: localLiquidationSettings.liquidatedReceiverBalance.optionalLabel
            }
          }
        })
      })
  }

  React.useEffect(() => {
    console.log('local: ', localLiquidationSettings)
  }, [localLiquidationSettings])

  let filteredEvents = events

  if (liquidatedAccount.trim()) {
    filteredEvents = filteredEvents.filter(
      ({ account }) => ethers.utils.getAddress(account) === ethers.utils.getAddress(liquidatedAccount)
    )
  }

  if (liquidatedToken.trim()) {
    filteredEvents = filteredEvents.filter(
      ({ token }) => ethers.utils.getAddress(token) === ethers.utils.getAddress(liquidatedToken)
    )
  }
  console.log(events)

  return (
    <div>
      <ContainerLabeler label={'Liquidation Settings'}>
        <LiquidationSettings liquidationSettingsConfig={localLiquidationSettings} />
      </ContainerLabeler>
      <LiquidationEventsFilter
        account={liquidatedAccount}
        token={liquidatedToken}
        onAccountChange={setLiquidatedAccount}
        onTokenChange={setLiquidatedToken}
      />
      <LiquidationLayout isLoading={isLoading} eventLog={filteredEvents} />
    </div>
  )
}

export default Liquidation
