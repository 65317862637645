import { ethers } from 'ethers'

const multicallAbi = [
  {
    constant: true,
    inputs: [
      {
        components: [
          {
            name: 'target',
            type: 'address'
          },
          {
            name: 'callData',
            type: 'bytes'
          }
        ],
        name: 'calls',
        type: 'tuple[]'
      }
    ],
    name: 'aggregate',
    outputs: [
      {
        name: 'blockNumber',
        type: 'uint256'
      },
      {
        name: 'returnData',
        type: 'bytes[]'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  }
]

const networks = {
  1: '0xeefba1e63905ef1d7acba5a8513c70307c1ce441',
  56: '0x41263cba59eb80dc200f3e2544eda4ed6a90e76c',
  137: '0x11ce4B23bD875D7F5C6a31084f55fDe1e9A87507',
  43114: '0xa00FB557AA68d2e98A830642DBbFA534E8512E5f'
}

const multiCall = async (provider, network, txs) => {
  console.log('provider, wallet.network, txs: ', provider, network, txs)

  // eslint-disable-next-line no-useless-catch
  try {
    const multi = new ethers.Contract(networks[network], multicallAbi, provider)
    const interfaces = []
    const callData = await txs.map((tx, tx_index) => {
      if (!interfaces[tx_index]) interfaces[tx_index] = new ethers.utils.Interface(tx.abi)
      return [tx.address, interfaces[tx_index].encodeFunctionData(tx.method, tx.args || [])]
    })

    console.log('callData: ', callData)

    return multi
      .aggregate(callData)
      .then(({ returnData }) => {
        console.log('went here')
        return returnData.map((data, res_index) => {
          return interfaces[res_index].decodeFunctionResult(txs[res_index].method, data)
        })
      })
      .then((data) => {
        return data.map((d) => (d.length > 1 ? d : d[0] !== undefined ? d[0] : undefined))
      })
  } catch (e) {
    throw e
  }
}

export default multiCall
