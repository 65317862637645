import React from 'react'
import { NavLink } from 'react-router-dom'
import { Col, ListGroup, Row } from 'react-bootstrap'
import { useWallet } from '../../utils/WalletProvider/WalletContext'
import ScanLink from '../ScanLink'

const networkNames: Record<number, string> = {
  1: 'Ethereum Network',
  56: 'Binance Smart Chain',
  137: 'Polygon Network',
  43114: 'Avalanche Network',
  1284: 'Moonbeam Network'
}
const navLinks = [
  {
    to: {
      pathname: '/protocol',
      state: {
        appId: 'appv2'
      }
    },
    title: 'Protocol'
  },
  {
    to: {
      pathname: '/account-balances',
      state: {
        appId: 'appv2'
      }
    },
    title: 'Account Balances'
  },
  {
    to: {
      pathname: '/protocol/strategies',
      state: {
        appId: 'appv2'
      }
    },
    title: 'Strategies'
  },
  {
    to: {
      pathname: '/protocol/explorer',
      state: {
        appId: 'appv2'
      },
      search: 'userAddr=&tokenAddr='
    },
    title: 'User Explorer'
  },
  {
    to: {
      pathname: '/bridge',
      state: {
        appId: 'appv2'
      }
    },
    title: 'Bridge'
  },
  {
    to: {
      pathname: '/bridge/demo',
      state: {
        appId: 'demo'
      }
    },
    title: 'Bridge (Demo)'
  },
  {
    to: {
      pathname: '/protocol/demo',
      state: {
        appId: 'demo'
      }
    },
    title: 'Protocol (Demo)'
  },
  {
    to: {
      pathname: '/protocol/explorer/demo',
      state: {
        appId: 'demo'
      },
      search: 'userAddr=&tokenAddr='
    },
    title: 'User Explorer (Demo)'
  },
  {
    to: {
      pathname: '/protocol/tools/liquidation',
      state: {
        appId: 'appv2'
      }
    },
    title: 'Liquidation Tool'
  }
]

const Drawer: React.FC = () => {
  const { wallet } = useWallet()

  return (
    <div className="d-flex flex-column p-3 drawer-inner-container">
      <Row className="drawer-logo">
        <img className="ramp-logo" src="https://appv2.rampdefi.com/assets/icon/ramp-with-text.svg" alt="ramp-logo" />
      </Row>
      <Row className="drawer-wallet-infos">
        <Col>
          <h5 className="drawer-wallet-infos_heading">Wallet Information</h5>
          <div className="drawer-wallet-infos_items">
            <div className="mb-3">
              <h6 className="drawer-wallet-infos_items_title">Network:</h6>
              <label>{networkNames[wallet.network]}</label>
            </div>
            <div>
              <h6 className="drawer-wallet-infos_items_title">Wallet Address:</h6>
              <label>
                <ScanLink search={wallet.address} type="address">
                  {wallet.address}
                </ScanLink>
              </label>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="drawer-dashboards">
        <Col className="m-0 p-0">
          <h5 className="drawer-dashboards_heading">Dashboards</h5>
        </Col>
        <Col>
          <ListGroup variant="flush">
            {navLinks.map((link, link_index) => {
              return (
                <ListGroup.Item className="list-group-item" key={link_index}>
                  <NavLink
                    strict
                    exact
                    to={link?.to}
                    className="drawer-dashboards_link"
                    activeStyle={{
                      color: 'blue',
                      fontWeight: 600
                    }}
                  >
                    {link.title.replace('(Demo)', '')}
                    {link.title.includes('(Demo)') ? <span className="drawer-demo-span">Demo</span> : undefined}
                  </NavLink>
                </ListGroup.Item>
              )
            })}
          </ListGroup>
        </Col>
      </Row>
    </div>
  )
}

export default Drawer
