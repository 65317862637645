import React from 'react'

type ContainerLabelerProps = {
  label: string
}

const ContainerLabeler: React.FC<ContainerLabelerProps> = ({ label, children }) => {
  return (
    <div className="container-labeler-container">
      <div className="container-labeler-label">
        <label className="labeler-label">{label}</label>

        <div className="container-labeler-main">
          <div className="container-detailer">{children}</div>
        </div>
      </div>
    </div>
  )
}

export default ContainerLabeler
