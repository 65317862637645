import React from 'react'
import { ethers } from 'ethers'
import { camelcaseConverter } from '../Field'
import { Dropdown } from 'react-bootstrap'
import DropdownToggle from 'react-bootstrap/DropdownToggle'
import DropdownMenu from 'react-bootstrap/DropdownMenu'
import DropdownItem from 'react-bootstrap/DropdownItem'

type Props<V = string | number | boolean> = {
  value: V
  label?: string
  options?: Record<any, V>
  hint?: string
  readOnly?: boolean
  onChange?(value: V): void
  description?: string
  isLoading?: boolean
}

const Input: React.FC<Props> = ({ value, label, options, readOnly, onChange, description, isLoading }) => {
  const inputType = typeof value
  const [loadingTest, setLoadingTest] = React.useState<boolean>(false)

  const checkboxHandle = (val: boolean) => {
    onChange?.(val)
  }
  const inputChange = (val: typeof value) => {
    onChange?.(val)
  }

  return (
    <div className="input-container">
      {inputType === 'boolean' ? (
        <div className="form-check form-switch mt-0 mb-3">
          <input
            type="checkbox"
            className="form-input form-check-input checkbox-input"
            checked={value as boolean}
            readOnly={readOnly}
            onChange={(event) => {
              checkboxHandle(event.target.checked)
            }}
          />
          <label>{description ? description : camelcaseConverter(label as string)}</label>
        </div>
      ) : options ? (
        <Dropdown className="input-dropdown">
          <DropdownToggle className="input-dropdown-toggle">
            <span>{options[value as string]}</span>
          </DropdownToggle>
          <DropdownMenu>
            {Object.entries(options).map(([value, name], opt_index) => {
              return (
                <DropdownItem
                  key={opt_index}
                  onClick={() => {
                    inputChange(value)
                  }}
                >
                  {name}
                </DropdownItem>
              )
            })}
          </DropdownMenu>
        </Dropdown>
      ) : inputType === 'number' ? (
        <input
          type="number"
          className={`input-number-string ${!readOnly ? undefined : 'input-disabled'}`}
          value={value as number}
          readOnly={readOnly}
          onChange={(event) => {
            inputChange(Number.parseInt(event.target.value))
          }}
        />
      ) : ethers.utils.isHexString(value as string) ? (
        <input
          type="text"
          className={`input-number-string ${!readOnly ? undefined : 'input-disabled'}`}
          value={value as string}
          readOnly={readOnly}
          onChange={(event) => {
            inputChange(event.target.value)
          }}
        />
      ) : (
        <>
          <span>{isLoading ? 'hello test' : ''}</span>
          <input
            type="text"
            className={`input-number-string ${!readOnly ? undefined : 'input-disabled'}`}
            value={value as string}
            readOnly={readOnly}
            onChange={(event) => {
              inputChange(event.target.value)
            }}
          />
        </>
      )}
    </div>
  )
}

export default Input
