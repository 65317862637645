import React from 'react'
import { useWallet } from '../../utils/WalletProvider/WalletContext'

const HOSTS_URL: Record<number, string> = {
  1: 'https://etherscan.io',
  56: 'https://bscscan.com',
  137: 'https://polygonscan.com',
  43114: 'https://snowtrace.io'
}

type Props = {
  search: string
  type: keyof typeof types
  showAll?: boolean
}

const types = {
  block: 'block',
  tx: 'tx',
  address: 'address'
}

const ScanLink: React.FC<Props> = ({ search, type, showAll, children }) => {
  const { wallet } = useWallet()
  const host = HOSTS_URL[wallet.network]
  const address: string = (children as string) || search

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a className="scan-link-element" href={`${host}/${type}/${search}`} target="_blank">
      {search && showAll ? search : (search + '').substr(0, 14) + '...'}
    </a>
  )
}

export default ScanLink
