import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Input from './Input'

type ValueType = string | boolean

type FieldProps = {
  id: string | number
  label: string
  value: ValueType
  btnText?: string
  onApply?: (value: ValueType) => void
  onChange?: any
  isToggle?: boolean
  isLoading?: boolean
  description?: string | undefined
  options?: Record<any, any>
  isCustomLoading?: boolean
  optionalLabel?: string
  infoText?: string
  alternativeButtonText?: string
}

export const camelcaseConverter = (text: string) => {
  const labelReplacer = text.replace(/([A-Z])/g, ' $1')
  return labelReplacer.charAt(0).toUpperCase() + labelReplacer.slice(1)
}

const Field: React.FC<FieldProps> = ({
  id,
  label,
  value,
  btnText,
  onApply,
  isToggle,
  isLoading,
  description,
  options,
  isCustomLoading,
  optionalLabel,
  infoText,
  alternativeButtonText
}) => {
  const [localValue, setLocalValue] = React.useState<any>('')

  React.useEffect(() => {
    setLocalValue(value)
  }, [value, isLoading])

  const handleButton = () => {
    onApply?.(localValue)
  }

  // TEXT CAMELCASE CONVERTER
  const labelReplacer = label.replace(/([A-Z])/g, ' $1')
  const convertedLabel = labelReplacer.charAt(0).toUpperCase() + labelReplacer.slice(1)

  if (isToggle) {
    if (localValue == 3) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.getElementById(id + '').checked = false
    }
  }

  if (value) {
    return (
      <div className="container-fluid mb-3 fieldContainer mt-3">
        <Col className="mb-2 ">
          <label className="label-custom-text">
            {optionalLabel ? optionalLabel : convertedLabel}{' '}
            {infoText && <label className="info-text">{infoText}</label>}
          </label>
        </Col>
        <Col>
          <Row>
            <Col>
              <Input
                value={localValue}
                label={convertedLabel}
                options={options}
                readOnly={!onApply || isLoading}
                onChange={(value) => {
                  setLocalValue(value)
                }}
                description={description}
                isLoading={isCustomLoading}
              />
            </Col>
            <Col className={'col-2 fieldButton'}>
              {onApply ? (
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    handleButton()
                  }}
                >
                  {alternativeButtonText
                    ? alternativeButtonText
                    : btnText || typeof localValue === 'boolean'
                    ? 'Apply'
                    : localValue
                    ? 'Apply'
                    : 'Pending'}
                </button>
              ) : undefined}
            </Col>
          </Row>
        </Col>
      </div>
    )
  } else {
    return null
  }
}

export default Field
