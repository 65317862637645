import { IWallet, Options as WalletOpts } from './IWallet'

class Sdk {
  private readonly wallet?: IWallet

  constructor(wallet?: IWallet) {
    this.wallet = wallet
  }

  send(address: string, method: string, args: any[], opts: WalletOpts): Promise<any | undefined> {
    // TODO: delegate send/get function
    if (!this.wallet?.network) throw new Error('Cannot identify network, connect to wallet first')

    if (!this.wallet) return new Promise((resolve) => resolve(undefined))

    return this.wallet.send(address, opts.abi, method, args, opts)
  }

  async getLatestBlockNr(): Promise<number> {
    if (!this.wallet?.network) throw new Error('Cannot identify network, connect to wallet first')
    return this.wallet.getLatestBlockNr()
  }
}

export default Sdk
