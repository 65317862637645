import React, { Reducer } from 'react'
import useFieldReducer, {
  FieldAction,
  FieldValue,
  LoadingAction,
  parseToFieldAction,
  RecordField,
  RecordFieldMethods
} from '../../contractHooks/useFieldReducer'
import { useMessages } from '../../utils/MessageProvider/MessagesContext'
import Field from '../../components/Field'
import { Button } from 'react-bootstrap'
import { useWallet } from '../../utils/WalletProvider/WalletContext'

type Props = {
  fields: RecordField
  setFields?: React.Dispatch<FieldAction | FieldAction[]>
  methods: RecordFieldMethods
  reset(): void
  setLoading?: React.Dispatch<LoadingAction>
}

const StrategyFields: React.FC<Props> = ({ fields, setFields, methods, reset, setLoading }) => {
  const { addMessage } = useMessages()
  const { wallet } = useWallet()
  const [loadState, setLoadState] = React.useState(false)
  const send = (method: string, value: FieldValue, fieldLabel: string) => {
    if (setLoading)
      setLoading({
        prop: fieldLabel,
        loading: true
      })

    try {
      methods[method](value)
        .then(() => {
          setLoadState(false)
        })
        .catch((err) => {
          setLoadState(false)
          addMessage({
            type: 'error',
            title: 'Sending Error:',
            message: err.data?.message || err.message || err
          })
        })
    } catch (err: any) {
      setLoadState(false)
      addMessage({
        type: 'error',
        title: 'Sending Error:',
        message: err.data?.message || err.message
      })
    }
    if (setLoading)
      setLoading({
        prop: fieldLabel,
        loading: false
      })
  }

  return (
    <div className="strategy-field-container">
      <Button className="refresh-button" onClick={reset}>
        <i className="bi bi-arrow-repeat" />
      </Button>
      <div className="strategyFieldContainer">
        {Object.entries(fields)
          .filter(([prop]) => prop !== 'settingsEnabled' || wallet.network !== 1)
          .map(([prop, field], field_index) => {
            return (
              <React.Fragment key={field_index}>
                <Field
                  id={field_index}
                  label={prop}
                  value={field.value}
                  options={field.prop?.options}
                  onApply={
                    field.method
                      ? (value) => {
                          send(field.method as string, value, prop)
                        }
                      : undefined
                  }
                  description={field.description}
                  isCustomLoading={loadState}
                  optionalLabel={field.optionalLabel}
                />
              </React.Fragment>
            )
          })}
      </div>
    </div>
  )
}

export default StrategyFields
