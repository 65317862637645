import { ethers } from 'ethers'
import { useCallback, useState } from 'react'
import Token from '../models/token'
import { pushDefaultTransactions } from '../helpers/pushDefaultTransactions'
import Multicall from '../models/Mutlicall'

const networks = {
  eth: 'https://mainnet-nethermind.blockscout.com/',
  bsc: 'https://bsc-dataseed.binance.org',
  polygon: 'https://polygon-rpc.com',
  avax: 'https://api.avax.network/ext/bc/C/rpc'
}

const nativeTokens = {
  eth: 'ETH',
  bsc: 'BNB',
  polygon: 'MATIC',
  avax: 'AVAX'
}

const formatEther = (bigNumber: any) => ethers.utils.formatEther(bigNumber)

const bigNumberArrayToParsedEthersFormat = (bigNumberArray: Array<any>) => {
  return bigNumberArray.map((bigNumber) => formatEther(bigNumber))
}

const useFetchTokens = (network: keyof typeof networks, tokens: Array<{ address: string; name: string }>) => {
  const [data, setData] = useState<Array<Token>>([
    {
      token: {
        name: nativeTokens[network],
        address: '',
        decimals: 18
      },
      balances: {}
    }
  ])
  const [error, setError] = useState<Error | null>(null)
  const [loading, setLoading] = useState(false)

  const provider = new ethers.providers.JsonRpcProvider(networks[network])

  const queryNativeBalance = useCallback((accounts: Array<{ name: string; address: string }>) => {
    const mappedBalances: Record<string, string> = {}
    accounts.forEach(({ address, name }) => {
      provider
        .getBalance(address)
        .then((result) => {
          mappedBalances[name] = formatEther(result)
          setData((prevData) => {
            const newData = {
              token: {
                name: nativeTokens[network],
                address: '',
                decimals: 18
              },
              balances: mappedBalances
            }
            if (prevData.findIndex((pd) => pd.token.name === nativeTokens[network]) === -1) {
              return [...prevData, newData]
            }
            return prevData.map((pd) => {
              if (pd.token.name !== nativeTokens[network]) return pd
              return newData
            })
          })
          if (data.length === tokens.length + 1) {
            setLoading(false)
            setError(null)
          }
        })
        .catch((e: any) => {
          setError(e)
          setLoading(false)
        })
    })
  }, [])

  const queryData = useCallback((accounts: Array<{ name: string; address: string }>) => {
    setLoading(true)
    setData([])
    setError(null)
    const addresses = accounts.map(({ address }) => address)
    for (const { name, address } of tokens) {
      const multicall = new Multicall(ethers, provider, network)
      pushDefaultTransactions(address, addresses, multicall)
      multicall
        .execute()
        .then((result) => {
          const balances = bigNumberArrayToParsedEthersFormat(result)
          const mappedBalances: Record<string, string> = {}
          for (let i = 0; i < balances.length; i++) {
            mappedBalances[accounts[i].name] = balances[i]
          }

          const newData = {
            token: {
              name,
              address,
              decimals: 18
            },
            balances: mappedBalances
          }
          setData((d) => [...d, newData])
          if (data.length === tokens.length + 1) {
            setLoading(false)
            setError(null)
          }
        })
        .catch((e: any) => {
          setError(e)
          setLoading(false)
        })
    }
  }, [])
  return {
    data: {
      network,
      tokens: data
    },
    error,
    loading,
    queryData,
    queryNativeBalance
  }
}

export default useFetchTokens
