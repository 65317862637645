interface PartialData {
  address: string
  abi?: any[] | any
  interface?: any
  method: string
  args?: any[]
}

class Data implements PartialData {
  interface: any
  address: string
  abi: any[]
  method: string
  args?: any[]
  encodedData: [string, string]

  constructor(ethers: any, data: PartialData) {
    this.address = data.address
    this.method = data.method
    this.args = data.args
    this.abi = data.abi
    this.interface = data.interface ?? new ethers.utils.Interface(data.abi)

    //try encoding
    this.encodedData = this.getEncodedData()
  }
  getEncodedData(): [string, string] {
    return [
      this.address,
      this.args
        ? this.interface.encodeFunctionData(this.method, this.args)
        : this.interface.encodeFunctionData(this.method)
    ]
  }

  decodeData(data: any) {
    const decodedData = this.interface.decodeFunctionResult(this.method, data)
    return decodedData.length > 1 ? decodedData : decodedData[0]
  }
}

// Multicall
const networks = {
  eth: '0xeefba1e63905ef1d7acba5a8513c70307c1ce441',
  bsc: '0x41263cba59eb80dc200f3e2544eda4ed6a90e76c',
  polygon: '0x11ce4B23bD875D7F5C6a31084f55fDe1e9A87507',
  avax: '',
  moonbeam: '0x6477204E12A7236b9619385ea453F370aD897bb2'
}

class Multicall {
  private contract: any
  network: keyof typeof networks
  datas: Data[] = []
  ethers: any

  constructor(ethers: any, signer: any, network: keyof typeof networks) {
    this.ethers = ethers
    this.network = network
    this.contract = new ethers.Contract(networks[network], ABI, signer).connect(signer)
  }

  add(data: PartialData): void {
    const created = new Data(this.ethers, data)
    this.datas.push(created)
  }

  async execute(): Promise<any> {
    if (!this.datas.length) return []
    const decodedData: any[] = []
    try {
      const callData = this.datas.map((d) => d.encodedData)

      const { returnData } = await this.contract.aggregate(callData)
      let index = -1
      for (const data of returnData) {
        index++
        decodedData[index] = this.datas[index].decodeData(data)
      }
    } catch (e) {
      console.error(e)
    }
    return decodedData
  }

  clear(): void {
    this.datas = []
  }
}

export default Multicall

const ABI = [
  {
    constant: true,
    inputs: [
      {
        components: [
          {
            name: 'target',
            type: 'address'
          },
          {
            name: 'callData',
            type: 'bytes'
          }
        ],
        name: 'calls',
        type: 'tuple[]'
      }
    ],
    name: 'aggregate',
    outputs: [
      {
        name: 'blockNumber',
        type: 'uint256'
      },
      {
        name: 'returnData',
        type: 'bytes[]'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  }
]
