import React from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import MainLayout from './MainLayout'
import Drawer from '../components/Drawer'
import ConfigProvider from '../utils/ConfigProvider'
import Protocol from '../page/Protocol'
import Explorer from '../page/Explorer'
import Liquidation from '../page/Tools/Liquidation'
import Bridge from '../page/Bridge'
import AccountBalances from '../page/AccountBalances'
import Strategies from '../page/Strategies'

const pageTitles: Record<string, string> = {
  '/protocol': 'Ramp Protocol',
  '/account-balances': 'Account Balances',
  '/protocol/demo': 'Ramp Protocol Demo',
  '/bridge': 'Bridge Dashboard',
  '/bridge/demo': 'Bridge Dashboard Demo',
  '/protocol/explorer': 'User Explorer',
  '/protocol/explorer/demo': 'User Explorer Demo',
  '/protocol/tools/liquidation': 'Liquidation Tool',
  '/protocol/strategies': 'Strategies'
}

const Main: React.FC = () => {
  const { pathname, state } = useLocation<any>()
  const [appType, setAppType] = React.useState<string>('')
  const appTypeDecider = state ? state.appId : appType

  React.useEffect(() => {
    let isMounted = true

    if (isMounted) {
      if (pathname.includes('demo')) {
        setAppType('demo')
      } else {
        setAppType('appv2')
      }
    }

    return function cleanup() {
      isMounted = false
    }
  }, [pathname])

  return (
    <>
      {!pathname.includes('demo') ? (
        <ConfigProvider appId={'appv2'}>
          <MainLayout renderDrawer={<Drawer />} title={pageTitles[pathname]}>
            <Route exact path="/">
              <Redirect to="/protocol" />
            </Route>
            <Route exact path="/protocol">
              <Protocol />
            </Route>
            <Route exact path="/protocol/strategies">
              <Strategies />
            </Route>
            <Route exact path="/account-balances">
              <AccountBalances />
            </Route>
            <Route exact path="/bridge">
              <Bridge />
            </Route>
            <Route exact path="/protocol/explorer">
              <Explorer />
            </Route>
            <Route exact path="/protocol/tools/liquidation">
              <Liquidation />
            </Route>
          </MainLayout>
        </ConfigProvider>
      ) : (
        <ConfigProvider appId={'demo'}>
          <MainLayout renderDrawer={<Drawer />} title={pageTitles[pathname]}>
            <Route exact path="/bridge/demo">
              <Bridge />
            </Route>
            <Route exact path="/protocol/demo">
              <Protocol />
            </Route>
            <Route exact path="/protocol/explorer/demo">
              <Explorer />
            </Route>
          </MainLayout>
        </ConfigProvider>
      )}
    </>
  )
}

export default Main
