import React from 'react'
import useFieldReducer, {
  parseToFieldAction,
  RecordField,
  RecordFieldMethods,
  resetFieldsValue
} from './useFieldReducer'
import { useWallet } from '../utils/WalletProvider/WalletContext'
import { ethers } from 'ethers'
import { useMessages } from '../utils/MessageProvider/MessagesContext'
import { StrategyProps } from './types'
import useContractRole from './useContractRole'
import { useConfig } from '../utils/ConfigProvider/ConfigContext'

const fieldConfigs: RecordField = {
  rTokenAddress: {
    value: ''
  },
  lifecycleState: {
    value: '',
    method: 'updateTokenStatus',
    prop: {
      options: {
        0: 'Deploying',
        1: 'Paused',
        2: 'Inactive',
        3: 'Active',
        4: 'MassWithdraw',
        5: 'Withdrawing'
      }
    }
  },
  mintCapacity: {
    value: '',
    unit: 'ether',
    commify: true,
    method: 'updateMintCapacity'
  },
  totalBorrowed: {
    value: '',
    unit: 'ether',
    commify: true
  },
  minCollateralRatio: {
    value: '',
    commify: true,
    method: 'updateCollateralRatio'
  },
  liquidationRatio: {
    value: '',
    commify: true,
    method: 'updateLiquidationRatio'
  },
  devFeePercentage: {
    value: '',
    commify: true,
    method: 'setDevFeePercentage'
  },
  settingsInterest: {
    value: '',
    unit: '6',
    commify: true,
    method: 'setSettingsInterest',
    optionalLabel: 'Interest (5mins after setting, do a borrow/repay on the strategy for it to apply)'
  },
  settingsEnabled: {
    value: false,
    method: 'setSettingsEnabled',
    description: 'Disable Strategy'
  },
  rewardsPerYearBank: {
    value: '',
    unit: 'ether',
    method: 'setRewardsPerYearBank',
    optionalLabel: 'Bonus Pool Bank Rewards (per year)'
  },
  _devFeePercentageBank: {
    value: '',
    unit: 'wei',
    method: '_setDevFeePercentageBank',
    optionalLabel: 'Bonus Pool Bank Dev Fee'
  },
  rewardsPerYearVault: {
    value: '',
    unit: 'ether',
    method: 'setRewardsPerYearVault',
    optionalLabel: 'Bonus Pool Vault Rewards (per year)'
  },
  _devFeePercentageVault: {
    value: '',
    unit: 'wei',
    method: '_setDevFeePercentageVault',
    optionalLabel: 'Bonus Pool Vault Dev Fee'
  }
}

const BaseStrategy: React.FC<StrategyProps> = ({ tokenConfig, data }) => {
  const { sdk, wallet } = useWallet()
  const {
    config: { contracts }
  } = useConfig()
  const { addMessage } = useMessages()
  const { hasRole } = useContractRole()
  const [fields, setFields, cancelSetFields, setLoading] = useFieldReducer(fieldConfigs)

  const interestTokenId = React.useMemo(() => {
    return ethers.utils.id(`interest_${tokenConfig.address.toLowerCase()}`)
  }, [tokenConfig.address])

  const enabledTokenId = React.useMemo(() => {
    return ethers.utils.id(`enabled_${tokenConfig.address.toLowerCase()}`)
  }, [tokenConfig.address])

  const fetchTokenInfo = React.useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { devFeePercentage, settingsInterest, ...otherFields } = fields
    resetFieldsValue(otherFields, setFields)
    Promise.all([
      sdk.send(contracts.Bank.address, 'tokens', [tokenConfig.address], {
        abi: contracts.Bank.abi,
        mapOutput: true
      }),
      sdk.send(contracts.Vault.address, 'tokens', [tokenConfig.address], {
        abi: contracts.Vault.abi,
        mapOutput: true
      })
    ])
      .then(([res1, res2]) => {
        console.log('res1 and res2', res1, res2)
        setFields(
          parseToFieldAction({
            ...res1,
            ...res2
          })
        )
      })
      .catch((err) => {
        addMessage({
          type: 'error',
          title: 'Fetching Token Info Error:',
          message: 'Token Info: ' + err.data?.message || err.message
        })
      })
  }, [sdk, tokenConfig.address])

  const fetchDevFeePercentage = React.useCallback(() => {
    resetFieldsValue(
      {
        devFeePercentage: fields.devFeePercentage
      },
      setFields
    )
    sdk
      .send(tokenConfig.strategy.address, 'devFeePercentage', [], {
        abi: tokenConfig.strategy.abi
      })
      .then((devFeePercentage) => {
        setFields({
          prop: 'devFeePercentage',
          value: devFeePercentage
        })
      })
      .catch((err) => {
        addMessage({
          type: 'error',
          title: 'Fetching Dev Fee Error:',
          message: 'Dev Fee Percentage: ' + err.data?.message || err.message
        })
      })
  }, [sdk, tokenConfig.strategy.address])

  const fetchAppSetting = React.useCallback(() => {
    resetFieldsValue(
      {
        settingsInterest: fields.settingsInterest
      },
      setFields
    )
    Promise.all([
      sdk.send(contracts.AppSettings.address, 'uintStorage', [interestTokenId], {
        abi: contracts.AppSettings.abi
      }),
      sdk.send(contracts.AppSettings.address, 'boolStorage', [enabledTokenId], {
        abi: contracts.AppSettings.abi
      })
    ])
      .then(([settingsInterest, settingsEnabled]) => {
        setFields([
          {
            prop: 'settingsInterest',
            value: settingsInterest
          },
          {
            prop: 'settingsEnabled',
            value: settingsEnabled
          }
        ])
      })
      .catch((err) => {
        addMessage({
          type: 'error',
          title: 'Fetching Settings Error:',
          message: err.data?.message || err.message
        })
      })
  }, [sdk, interestTokenId])

  const fetchBonusPoolInfo = React.useCallback(() => {
    if (!contracts.BonusPool_Bank && !contracts.BonusPool_Vault) return
    resetFieldsValue(fields, setFields)
    const bonusPools = {
      Bank: contracts.BonusPool_Bank,
      Vault: contracts.BonusPool_Vault
    }

    for (const [type, contract] of Object.entries(bonusPools)) {
      sdk
        .send(contract.address, 'poolInfo', [tokenConfig.address], {
          abi: contract.abi,
          mapOutput: true
        })
        .then((res) => {
          if (res.enabled) {
            console.log('log', `rewardsPerYear${type}`)
            setFields([
              {
                prop: `rewardsPerYear${type}`,
                value: res.rewardsPerYear
              },
              {
                prop: `_devFeePercentage${type}`,
                value: res.devFeePercentage
              }
            ])
          }
        })
    }
  }, [sdk, tokenConfig.strategy.address, tokenConfig.address])

  const reset = () => {
    fetchTokenInfo()
    fetchDevFeePercentage()
    if (contracts.AppSettings) fetchAppSetting()
    fetchBonusPoolInfo()
  }

  React.useEffect(() => {
    hasRole(tokenConfig.strategy.address, tokenConfig.strategy.abi, 'OPERATOR_ROLE').then(
      ({ hasRole, walletAddress, role }) => {
        if (!hasRole) {
          addMessage({
            type: 'warning',
            title: 'Not the contract owner:',
            message: `${walletAddress} has no ${role}`
          })
        }
      }
    )
  }, [tokenConfig.strategy.address, hasRole])

  React.useEffect(() => {
    fetchTokenInfo()
    fetchBonusPoolInfo()
    return () => {
      cancelSetFields()
    }
  }, [fetchTokenInfo, fetchBonusPoolInfo])

  React.useEffect(() => {
    if (wallet.network !== 1) {
      fetchDevFeePercentage()
    }
    return () => {
      cancelSetFields()
    }
  }, [fetchTokenInfo])

  React.useEffect(() => {
    if (wallet.network !== 1 && contracts.AppSettings) {
      console.log('wat')
      fetchAppSetting()
    }
    return () => {
      cancelSetFields()
    }
  }, [fetchAppSetting])

  const updateTokenStatus = React.useCallback(
    (value: string) => {
      // TODO: hotfix remove this once RAMP-1197 issue is closed
      // Using the MassWithdraw lifecycle state causes an error with liquidation, so we'll disable it for now
      if (value === '4')
        return new Promise((resolve, reject) => {
          reject(
            'SETTING LIFECYCLE STATE TO MASS WITHDRAW IS CURRENTLY DISABLED, PLEASE SEE RAMP-1197 FOR MORE DETAILS'
          )
        })
      return sdk.send(contracts.Controller.address, 'updateTokenStatus', [tokenConfig.address, value], {
        abi: contracts.Controller.abi
      })
    },
    [sdk, tokenConfig.address]
  )

  const updateMintCapacity = React.useCallback(
    (value: string) => {
      console.log('mint Capacity: ', value)
      value = value.replace(/,/gm, '')
      const parsedValue = ethers.utils.parseUnits(value, fields.mintCapacity.unit)
      console.log('mint Capacity parsed: ', parsedValue)
      return sdk.send(contracts.Controller.address, 'updateMintCapacity', [tokenConfig.address, parsedValue], {
        abi: contracts.Controller.abi
      })
    },
    [fields.mintCapacity.unit, sdk, tokenConfig.address]
  )

  const updateCollateralRatio = React.useCallback(
    (value: number) => {
      return sdk.send(contracts.Controller.address, 'updateCollateralRatio', [tokenConfig.address, value], {
        abi: contracts.Controller.abi
      })
    },
    [sdk, tokenConfig.address]
  )

  const updateLiquidationRatio = React.useCallback(
    (value: number) => {
      return sdk.send(contracts.Controller.address, 'updateLiquidationRatio', [tokenConfig.address, value], {
        abi: contracts.Controller.abi
      })
    },
    [sdk, tokenConfig.address]
  )

  const setDevFeePercentage = React.useCallback(
    (value: number) => {
      return sdk.send(tokenConfig.strategy.address, 'setDevFeePercentage', [value], {
        abi: tokenConfig.strategy.abi
      })
    },
    [sdk, tokenConfig.strategy.address]
  )

  const setSettingsInterest = React.useCallback(
    (value: string) => {
      const parsedValue = ethers.utils.parseUnits(value, fields.settingsInterest.unit)
      return sdk.send(contracts.AppSettings.address, 'setUint', [interestTokenId, parsedValue], {
        abi: contracts.AppSettings.abi
      })
    },
    [fields.settingsInterest.unit, sdk, interestTokenId]
  )

  const setSettingsEnabled = React.useCallback(
    (value: boolean) => {
      return sdk.send(contracts.AppSettings.address, 'setBool', [enabledTokenId, value], {
        abi: contracts.AppSettings.abi
      })
    },
    [fields.settingsEnabled.unit, sdk, enabledTokenId]
  )

  const setRewardsPerYear = React.useCallback(
    (value: string, type: 'Bank' | 'Vault') => {
      // value = value.replace(/,/gm, '')
      const parsedValue = ethers.utils.parseUnits(value, fields[`rewardsPerYear${type}`].unit)
      console.log('pv', parsedValue)
      const contract = type === 'Bank' ? contracts.BonusPool_Bank : contracts.BonusPool_Vault
      return sdk.send(contract.address, 'setRewardsPerYear', [tokenConfig.address, parsedValue], {
        abi: contract.abi
      })
    },
    [sdk, tokenConfig.strategy.address]
  )

  const setRewardsPerYearBank = React.useCallback((value: string) => setRewardsPerYear(value, 'Bank'), [
    setRewardsPerYear
  ])

  const setRewardsPerYearVault = React.useCallback((value: string) => setRewardsPerYear(value, 'Vault'), [
    setRewardsPerYear
  ])

  const _setDevFeePercentage = React.useCallback(
    (value: string, type: 'Bank' | 'Vault') => {
      const parsedValue = ethers.utils.parseUnits(value, fields[`_devFeePercentage${type}`].unit)
      const contract = type === 'Bank' ? contracts.BonusPool_Bank : contracts.BonusPool_Vault

      return sdk.send(contract.address, 'setDevFeePercentage', [tokenConfig.address, parsedValue], {
        abi: contract.abi
      })
    },
    [sdk, tokenConfig.strategy.address]
  )

  const _setDevFeePercentageBank = React.useCallback((value: string) => _setDevFeePercentage(value, 'Bank'), [
    _setDevFeePercentage
  ])

  const _setDevFeePercentageVault = React.useCallback((value: string) => _setDevFeePercentage(value, 'Vault'), [
    _setDevFeePercentage
  ])

  const methods: RecordFieldMethods = React.useMemo(() => {
    return {
      updateTokenStatus,
      updateMintCapacity,
      updateCollateralRatio,
      updateLiquidationRatio,
      setDevFeePercentage,
      setSettingsInterest,
      setSettingsEnabled,
      setRewardsPerYearBank,
      setRewardsPerYearVault,
      _setDevFeePercentageBank,
      _setDevFeePercentageVault
    }
  }, [
    updateTokenStatus,
    updateMintCapacity,
    updateCollateralRatio,
    updateLiquidationRatio,
    setDevFeePercentage,
    setSettingsInterest,
    setSettingsEnabled,
    setRewardsPerYearBank,
    setRewardsPerYearVault,
    _setDevFeePercentageBank,
    _setDevFeePercentageVault
  ])

  return (
    <>
      {data({
        fields,
        setFields,
        methods,
        reset,
        setLoading
      })}
    </>
  )
}

export default BaseStrategy
