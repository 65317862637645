import fetch from 'node-fetch'

const delay = (ms) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, ms)
  })
}

export default async function retryFetch(url, fetchOptions = {}, retries = 3, retryDelay = 1000) {
  return new Promise((resolve, reject) => {
    const wrapper = (n) => {
      fetch(url, fetchOptions)
        .then((res) => {
          resolve(res)
        })
        .catch(async (err) => {
          if (n > 0) {
            await delay(retryDelay)
            wrapper(--n)
          } else {
            reject(err)
          }
        })
    }

    wrapper(retries)
  })
}
