import Sdk from '../../../utils/WalletProvider/Sdk'
import { ethers } from 'ethers'
import { ContractConfig } from '../../../utils/ConfigProvider'

export const Setting_IDs = {
  generalPageId: ethers.utils.id('ramp_webapp_page'),
  mintPageId: ethers.utils.id('ramp_webapp_swap_page'),
  swapPageId: ethers.utils.id('ramp_webapp_mint_page'),
  liquidityPageId: ethers.utils.id('ramp_webapp_liquidity_page'),
  bridgePageId: ethers.utils.id('ramp_webapp_bridge_page'),
  depositFunctionId: ethers.utils.id('ramp_webapp_deposit_function'),
  withdrawFunctionId: ethers.utils.id('ramp_webapp_withdraw_function'),
  borrowFunctionId: ethers.utils.id('ramp_webapp_borrow_function'),
  repayFunctionId: ethers.utils.id('ramp_webapp_repay_function')
}

class SettingClass {
  public _sdk: Sdk
  public _appSettingAddr: string
  public _appSettingAbi: any[]

  constructor(sdk: Sdk, AppSettingConfig: ContractConfig) {
    this._sdk = sdk
    this._appSettingAddr = AppSettingConfig.address
    this._appSettingAbi = AppSettingConfig.abi
  }

  async getBool(settingId: string): Promise<boolean> {
    return this._sdk.send(this._appSettingAddr, 'boolStorage', [settingId], {
      abi: this._appSettingAbi
    })
  }

  async setBool(settingId: string, value: boolean): Promise<boolean> {
    return this._sdk
      .send(this._appSettingAddr, 'setBool', [settingId, value], {
        abi: this._appSettingAbi
      })
      .then(() => {
        return this.getBool(settingId) as Promise<boolean>
      })
  }
}

export default SettingClass
