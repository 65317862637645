import React from 'react'
import { ConfigResult } from './index'

export interface ConfigContext {
  config: ConfigResult
}

export const ConfigContext = React.createContext<ConfigContext>({} as ConfigContext)

export const useConfig = () => {
  return React.useContext(ConfigContext)
}
