import React from 'react'
import RampAppSettingLayout from './RampAppSettingLayout'
import RampAppSettingClass, { Ramp_Setting_IDs } from './RampAppSettingClass'
import { useWallet } from '../../../utils/WalletProvider/WalletContext'
import { useConfig } from '../../../utils/ConfigProvider/ConfigContext'
import useFieldReducer from '../../../contractHooks/useFieldReducer'
import { useMessages } from '../../../utils/MessageProvider/MessagesContext'
import { fetchTokenSupply } from '../../../helpers/api'
import { logger } from 'ethers'

const styles = {
  rampSettingContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(600px, 1fr))'
  }
}

export const rampAppSettingConfig = {
  circulatingSupply: {
    value: '',
    unit: 'ether',
    method: 'setCirculatingSupply',
    commify: true,
    prop: {
      id: Ramp_Setting_IDs.circulatingSupplyId
    }
  },
  currentMinted: {
    value: '',
    unit: 'ether',
    method: '',
    commify: true
  },
  totalMinted: {
    value: '',
    unit: 'ether',
    method: '',
    commify: true
  },
  uncollateralizedRusd: {
    value: '',
    unit: 'ether',
    method: '',
    commify: true
  }
}

const RampAppSetting: React.FC = () => {
  const { wallet, sdk } = useWallet()
  const { config } = useConfig()
  const { addMessage } = useMessages()
  const rampClass = new RampAppSettingClass(sdk, config.contracts.AppSettings, wallet.network)
  const [fields, setFields] = useFieldReducer(rampAppSettingConfig)
  const [isResultSuccess, setIsResultSuccess] = React.useState<boolean>(false)

  function onApply(method: string, settingId: string, value: any) {
    console.log('onApply: ', method, value)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    rampClass[method](settingId, value)
      .then(() => {
        addMessage({
          type: 'success',
          title: 'Success',
          message: `${method} applied`
        })
      })
      .catch((err: any) => {
        addMessage({
          title: 'Error',
          type: 'error',
          message: err.data?.message || err.message
        })
      })
  }

  async function getRusdTotalSupply(currentRusd: number) {
    const bscRusd = await fetchTokenSupply(56, '0x07663837218A003e66310a01596af4bf4e44623D')
    const polygonRusd = await fetchTokenSupply(137, '0xfC40a4F89b410a1b855b5e205064a38fC29F5eb5')
    const totalRusd = parseInt(bscRusd) + parseInt(polygonRusd)
    setFields({ prop: 'totalMinted', value: totalRusd.toString() })
    setFields({ prop: 'uncollateralizedRusd', value: (totalRusd - currentRusd).toString() })
  }

  React.useEffect(() => {
    rampClass
      .getInt(Ramp_Setting_IDs.circulatingSupplyId)
      .then((res: any) => {
        console.log('the result: ', res)
        setIsResultSuccess(true)
        setFields({
          prop: 'circulatingSupply',
          value: res
        })
      })
      .catch((err: any) => {
        addMessage({
          type: 'error',
          title: 'Fetching Circulating Supply Error',
          message: err.data?.message || err.message
        })
      })
  }, [sdk, rampAppSettingConfig])

  React.useEffect(() => {
    fetch('https://config.rampdefi.com/config/appv2/priceinfo')
      .then((res) => res.json())
      .then((stats) => {
        let total = 0
        for (const [, network] of Object.entries(stats as { network: { tokens: Record<string, any> } })) {
          for (const [, token] of Object.entries(
            network.tokens as { token: { strategy: Record<string, any>; stats: { totalBorrowed: number } } }
          )) {
            if (token?.strategy === undefined) continue
            total += token?.stats.totalBorrowed
          }
        }
        setFields({ prop: 'currentMinted', value: total.toString() })
        return total
      })
      .then((currentMinted) => {
        getRusdTotalSupply(currentMinted)
      })
      .catch((err: any) => {
        addMessage({
          type: 'error',
          title: 'Fetching Circulating Supply Error',
          message: err.data?.message || err.message
        })
      })
  }, [sdk, rampAppSettingConfig])

  return (
    <div style={styles.rampSettingContainer}>
      <RampAppSettingLayout fields={fields} onApply={onApply} />
    </div>
  )
}

export default RampAppSetting
