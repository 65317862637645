import React from 'react'
import { useWallet } from '../utils/WalletProvider/WalletContext'
import { useMessages } from '../utils/MessageProvider/MessagesContext'
import useFieldReducer, { RecordField, resetFieldsValue } from './useFieldReducer'
import { ethers } from 'ethers'
import { StrategyProps } from './types'
import BaseStrategy from './BaseStrategy'

const fieldConfigs: RecordField = {
  withdrawalFeePercentage: {
    value: '',
    unit: 'ether',
    commify: true,
    method: 'setWithdrawalFeePercentage'
  }
}

const CakeAccruingStrategy: React.FC<StrategyProps> = ({ tokenConfig, data }) => {
  const { sdk } = useWallet()
  const { addMessage } = useMessages()
  const [_fields, setFields, cancelSetFields, setLoading] = useFieldReducer(fieldConfigs)

  const fetchWithdrawalFee = React.useCallback(() => {
    resetFieldsValue(fieldConfigs, setFields)
    sdk
      .send(tokenConfig.strategy.address, 'withdrawalFeePercentage', [], {
        abi: tokenConfig.strategy.abi
      })
      .then((withdrawalFeePercentage) => {
        setFields({
          prop: 'withdrawalFeePercentage',
          value: withdrawalFeePercentage
        })
      })
      .catch((err) => {
        addMessage({
          type: 'error',
          title: 'Error: ',
          message: err.data?.message || err.message
        })
      })
  }, [sdk, tokenConfig.strategy.address])

  const reset = () => {
    fetchWithdrawalFee()
  }

  React.useEffect(() => {
    fetchWithdrawalFee()
    return () => {
      cancelSetFields()
    }
  }, [fetchWithdrawalFee])

  const setWithdrawalFeePercentage = React.useCallback(
    (value: string) => {
      value = value.replace(/,/gm, '')
      const parsedValue = ethers.utils.parseUnits(value as string, _fields.withdrawalFeePercentage.unit)
      return sdk.send(tokenConfig.strategy.address, 'setWithdrawalFeePercentage', [parsedValue], {
        abi: tokenConfig.strategy.abi
      })
    },
    [sdk, _fields.withdrawalFeePercentage.unit, tokenConfig.strategy.address]
  )

  return (
    <>
      <BaseStrategy
        tokenConfig={tokenConfig}
        data={(props) => {
          return data({
            fields: {
              ...props.fields,
              ..._fields
            },
            setFields,
            methods: {
              setWithdrawalFeePercentage,
              ...props.methods
            },
            reset: () => {
              props.reset()
              reset()
            },
            setLoading
          })
        }}
      />
    </>
  )
}

export default CakeAccruingStrategy
