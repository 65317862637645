import StrategyListItem from './StrategyListItem'
import { ListGroup } from 'react-bootstrap'
import React from 'react'
import { TokenContractConfig } from '../../utils/ConfigProvider'

interface Props {
  network: number
  tokens: Record<string, TokenContractConfig>
  activeToken: string
}

const newBscStrategyLabels: Record<string, { strategyId: string; strategyName: string }> = {
  Bsc_1: {
    strategyId: 'BSC 1',
    strategyName: 'Ramp Staking Strategy'
  },
  Bsc_2: {
    strategyId: 'BSC 2',
    strategyName: 'RAMP BUSD LP Strategy'
  },
  Bsc_3: {
    strategyId: 'BSC 3',
    strategyName: 'rUSD BUSD LP Strategy'
  },
  Bsc_4: {
    strategyId: 'BSC 4',
    strategyName: 'NRV BNB Strategy'
  },
  Bsc_5: {
    strategyId: 'BSC 5',
    strategyName: 'NRV rUSD Strategy'
  },
  Bsc_6: {
    strategyId: 'BSC 6',
    strategyName: 'INJ BNB Strategy'
  },
  Bsc_7: {
    strategyId: 'BSC 7',
    strategyName: 'Cake Accruing Strategy'
  },
  Bsc_8: {
    strategyId: 'BSC 8',
    strategyName: 'NULS BUSD LP Strategy'
  },
  Bsc_9: {
    strategyId: 'BSC 9',
    strategyName: 'MCoin UST Strategy'
  },
  Bsc_10: {
    strategyId: 'BSC 10',
    strategyName: 'MIR UST Strategy'
  },
  Bsc_11: {
    strategyId: 'BSC 11',
    strategyName: 'UST BUSD Strategy'
  },
  Bsc_12: {
    strategyId: 'BSC 12',
    strategyName: 'IOTX BUsd Strategy'
  },
  Bsc_13: {
    strategyId: 'BSC 13',
    strategyName: 'WBNB Alpaca Accruing Strategy'
  },
  Bsc_14: {
    strategyId: 'BSC 14',
    strategyName: 'BUSD Alpaca Accruing Strategy'
  },
  Bsc_15: {
    strategyId: 'BSC 15',
    strategyName: 'USDT Alpaca Accruing Strategy'
  },
  Bsc_16: {
    strategyId: 'BSC 16',
    strategyName: 'ETH Alpaca Accruing Strategy'
  },
  Bsc_17: {
    strategyId: 'BSC 17',
    strategyName: 'rUSD Strategy'
  },
  Bsc_18: {
    strategyId: 'BSC 18',
    strategyName: 'CAKE BNB LP Strategy'
  },
  Bsc_19: {
    strategyId: 'BSC 19',
    strategyName: 'BNB BUSD LP Strategy'
  },
  Bsc_20: {
    strategyId: 'BSC 20',
    strategyName: 'USDT BNB LP Strategy'
  },
  Bsc_21: {
    strategyId: 'BSC 21',
    strategyName: 'ETH BNB LP Strategy'
  },
  Bsc_22: {
    strategyId: 'BSC 22',
    strategyName: 'BTCB BNB LP Strategy'
  },
  Bsc_23: {
    strategyId: 'BSC 23',
    strategyName: 'CAKE BUSD LP Strategy'
  },
  Bsc_24: {
    strategyId: 'BSC 24',
    strategyName: 'BNB LINK LP Strategy'
  },
  Bsc_25: {
    strategyId: 'BSC 25',
    strategyName: 'TRX BNB LP Strategy'
  },
  Bsc_26: {
    strategyId: 'BSC 26',
    strategyName: 'DOT BNB LP Strategy'
  },
  Bsc_27: {
    strategyId: 'BSC 27',
    strategyName: 'CAKE USDT LP Strategy'
  },
  Bsc_28: {
    strategyId: 'BSC 28',
    strategyName: 'DOGE BNB LP Strategy'
  },
  Bsc_29: {
    strategyId: 'BSC 29',
    strategyName: 'BTCB Strategy'
  },
  Bsc_30: {
    strategyId: 'BSC 30',
    strategyName: 'LINK Strategy'
  },
  Bsc_31: {
    strategyId: 'BSC 31',
    strategyName: 'BTCB ETH LP Strategy'
  },
  Bsc_32: {
    strategyId: 'BSC 32',
    strategyName: 'BTCB BUSD LP Strategy'
  },
  Bsc_33: {
    strategyId: 'BSC 33',
    strategyName: 'ADA BNB LP Strategy'
  },
  Bsc_34: {
    strategyId: 'BSC 34',
    strategyName: 'XVS BNB LP Strategy'
  },
  Bsc_35: {
    strategyId: 'BSC 35',
    strategyName: 'BETA BNB LP Strategy'
  },
  Bsc_36: {
    strategyId: 'BSC 36',
    strategyName: 'AXS BNB LP Strategy'
  },
  Bsc_37: {
    strategyId: 'BSC 37',
    strategyName: 'RUSD RAMP LP Strategy'
  }
}

const newPolygonStrategyLabels: Record<string, { strategyId: string; strategyName: string }> = {
  Polygon_1: {
    strategyId: 'Polygon 1',
    strategyName: 'Ramp Staking Strategy'
  },
  Polygon_2: {
    strategyId: 'Polygon 2',
    strategyName: 'Matic/ETH Strategy'
  },
  Polygon_3: {
    strategyId: 'Polygon 3',
    strategyName: 'Ramp/ETH Strategy'
  },
  Polygon_4: {
    strategyId: 'Polygon 4',
    strategyName: 'rUSD/USDC Strategy'
  },
  Polygon_5: {
    strategyId: 'Polygon 5',
    strategyName: 'Matic/USDC Strategy'
  },
  Polygon_6: {
    strategyId: 'Polygon 6',
    strategyName: 'wBTC/USDC Strategy'
  },
  Polygon_7: {
    strategyId: 'Polygon 7',
    strategyName: 'DAI/wETH Strategy'
  },
  Polygon_8: {
    strategyId: 'Polygon 8',
    strategyName: 'ETH/USDC Strategy'
  },
  Polygon_9: {
    strategyId: 'Polygon 9',
    strategyName: 'LINK/ETH Strategy'
  },
  Polygon_10: {
    strategyId: 'Polygon 10',
    strategyName: 'rUSD Strategy'
  },
  Polygon_11: {
    strategyId: 'Polygon 11',
    strategyName: 'USDC/QUICK Strategy'
  },
  Polygon_12: {
    strategyId: 'Polygon 12',
    strategyName: 'ETH/QUICK Strategy'
  },
  Polygon_13: {
    strategyId: 'Polygon 13',
    strategyName: 'MATIC/QUICK Strategy'
  },
  Polygon_14: {
    strategyId: 'Polygon 14',
    strategyName: 'wMatic Strategy'
  },
  Polygon_15: {
    strategyId: 'Polygon 15',
    strategyName: 'wEth Strategy'
  },
  Polygon_16: {
    strategyId: 'Polygon 16',
    strategyName: 'wBtc Strategy'
  }
}
const newAvaxStrategyLabels: Record<string, { strategyId: string; strategyName: string }> = {
  Avax_1: {
    strategyId: 'Avax 1',
    strategyName: 'wAVAX Strategy'
  }
}

const newMoonbeamStrategyLabels: Record<string, { strategyId: string; strategyName: string }> = {
  Moonbeam_1: {
    strategyId: 'Moonbeam 1',
    strategyName: 'wGLMR Strategy'
  }
}

const newEthStrategyLabels: Record<string, { strategyId: string; strategyName: string }> = {
  Eth_1: {
    strategyId: 'ETH 1',
    strategyName: 'Ramp Staking Strategy'
  },
  Eth_2: {
    strategyId: 'ETH 2',
    strategyName: 'RAMP/ETH Uniswap'
  }
}

const StrategyList: React.FC<Props> = ({ network, tokens, activeToken }) => {
  const newStrategyLabels =
    network === 56 || network === 31337
      ? newBscStrategyLabels
      : network === 137
      ? newPolygonStrategyLabels
      : network === 43114
      ? newAvaxStrategyLabels
      : network === 1284
      ? newMoonbeamStrategyLabels
      : newEthStrategyLabels

  return (
    <ListGroup defaultActiveKey={activeToken} className="strategy-list-group-container border border-1">
      {Object.entries(tokens).map(([name, token], token_index) => {
        return (
          <StrategyListItem
            key={token_index}
            tokenIndex={token_index}
            strategyLabel={newStrategyLabels[token.strategy.name]}
            tokenName={name}
            tokenStrategy={token.strategy.name}
          />
        )
      })}
    </ListGroup>
  )
}

export default StrategyList
