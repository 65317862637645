import { Row as RowType, Header, LinkedValue } from '../types'
import classes from './index.module.css'
import { ethers } from 'ethers'

interface Props {
  row: RowType
  order: Array<string | number>
  headers: Array<Header>
}

const isANumber = (data: string | number | LinkedValue) => {
  if (data instanceof LinkedValue) {
    if (typeof data.value === 'string') {
      if (ethers.utils.isAddress(data.value) || data.value.includes('0x')) return false
      data = parseFloat(data.value)
    } else {
      data = data.value
    }
  }
  if (typeof data === 'string') {
    if (ethers.utils.isAddress(data)) return false
    data = parseFloat(data)
  }
  return !isNaN(data)
}

const Row: React.FC<Props> = ({ row, order, headers }) => {
  const orderedRow = order.map((o) => row[o] as string | number)
  return (
    <tr>
      {orderedRow.map((cell, index) => {
        const headerId = order[index]
        const header = headers.find((h) => h.id === headerId || h?.subHeaders?.find((sh) => sh.id === headerId))
        const subHeader = header?.subHeaders?.find((sh) => sh.id === headerId)
        const transform = subHeader?.transformData ?? header?.transformData
        const value = cell == null ? '-----' : cell
        const data = transform == null ? value : transform(value)
        const className = `${isANumber(data) ? classes.rightAlign : ''} ${
          row?.filter?.includes(headerId) ? classes.redHighlight : ''
        }`
        return (
          <td key={`${row.id}-${order[index]}`} className={className}>
            {data instanceof LinkedValue ? data.getLinkElement() : data}
          </td>
        )
      })}
    </tr>
  )
}

export default Row
