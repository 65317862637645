import SettingClass from '../WebAppSetting/SettingClass'
import Sdk from '../../../utils/WalletProvider/Sdk'
import { ContractConfig } from '../../../utils/ConfigProvider'
import { ethers } from 'ethers'

export const Ramp_Setting_IDs = {
  circulatingSupplyId: ethers.utils.id('ramp_circulating_supply')
}

class RampAppSettingClass extends SettingClass {
  private _walletNetwork: number

  constructor(sdk: Sdk, appSettings: ContractConfig, walletNetwork: number) {
    super(sdk, appSettings)
    this._sdk = sdk
    this._appSettingAddr = appSettings.address
    this._appSettingAbi = appSettings.abi
    this._walletNetwork = walletNetwork
  }

  async getInt(settingId: string): Promise<boolean> {
    if (this._walletNetwork !== 1) {
      return await this._sdk.send(this._appSettingAddr, 'uintStorage', [settingId], {
        abi: this._appSettingAbi
      })
    } else {
      return false
    }
  }

  setCirculatingSupply(settingId: string, value: number | string) {
    value = value.toString().replace(/,/gm, '')
    console.log('the value: ', value)
    const parsedvalue = ethers.utils.parseUnits(value.toString())
    console.log('the value: ', parsedvalue)
    console.log('the id: ', settingId)
    return this._sdk.send(this._appSettingAddr, 'setUint', [settingId, parsedvalue], {
      abi: this._appSettingAbi
    })
  }
}

export default RampAppSettingClass
