import React from 'react'
import { useConfig } from '../../utils/ConfigProvider/ConfigContext'
import { useQuery } from '../../utils/url'
import ExplorerLayout from './ExplorerLayout'
import useScan from '../../helpers/scanEvents/useScan'

const Explorer: React.FC = () => {
  const { config } = useConfig()
  const { query } = useQuery()

  const { eventLogs, fetchEventLogs, isFetching, completion } = useScan()

  const tokenOptions = Object.entries(config?.tokens).map(([name, { address }]) => {
    return { name, address }
  })

  React.useEffect(() => {
    if ((query && query.userAddr) || query.tokenAddr) {
      fetchEventLogs({
        userAddr: query.userAddr as string,
        tokenAddr: query.tokenAddr as string,
        daysBefore: Number(query.daysBefore as string) as number
      }).then()
    }
  }, [query.userAddr, query.tokenAddr, query.daysBefore])

  return (
    <ExplorerLayout tokenOptions={tokenOptions} isLoading={isFetching} eventLogs={eventLogs} completion={completion} />
  )
}

export default Explorer
