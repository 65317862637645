import React from 'react'
import { StrategyProps } from './types'
import { useWallet } from '../utils/WalletProvider/WalletContext'
import useFieldReducer, {
  parseToFieldAction,
  RecordField,
  RecordFieldMethods,
  resetFieldsValue
} from './useFieldReducer'
import { useMessages } from '../utils/MessageProvider/MessagesContext'
import BaseStrategy from './BaseStrategy'
import { ethers } from 'ethers'

const fieldConfigs: RecordField = {
  rewardPerBlock: {
    value: '',
    unit: 'ether',
    method: 'setRewardPerBlock'
  }
}

const StaticErcStrategy: React.FC<StrategyProps> = ({ tokenConfig, data }) => {
  const { sdk } = useWallet()
  const { addMessage } = useMessages()
  const [_fields, setFields, cancelSetFields, setLoading] = useFieldReducer(fieldConfigs)

  const fetchPoolInfo = React.useCallback(() => {
    resetFieldsValue(fieldConfigs, setFields)
    sdk
      .send(tokenConfig.strategy.address, 'poolInfo', [], {
        abi: tokenConfig.strategy.abi,
        mapOutput: true
      })
      .then((poolInfo) => {
        setFields(parseToFieldAction(poolInfo))
      })
      .catch((err) => {
        addMessage({
          type: 'error',
          message: err.data?.message || err.message
        })
      })
  }, [sdk, tokenConfig.address])

  React.useEffect(() => {
    fetchPoolInfo()
  }, [tokenConfig.address])

  const setRewardPerBlock = React.useCallback(
    (value: string) => {
      const parsedValue = ethers.utils.parseUnits(value, fieldConfigs.rewardPerBlock.unit)
      return sdk.send(tokenConfig.strategy.address, 'setRewardPerBlock', [parsedValue], {
        abi: tokenConfig.strategy.abi
      })
    },
    [sdk, tokenConfig.strategy.address]
  )

  const staticErcMethods: RecordFieldMethods = React.useMemo(() => {
    return {
      setRewardPerBlock
    }
  }, [setRewardPerBlock])

  return (
    <>
      <BaseStrategy
        tokenConfig={tokenConfig}
        data={(props) => {
          return data({
            fields: {
              ...props.fields,
              ..._fields
            },
            setFields,
            methods: {
              ...props.methods,
              ...staticErcMethods
            },
            reset: () => {
              props.reset()
              fetchPoolInfo()
            },
            setLoading
          })
        }}
      />
    </>
  )
}

export default StaticErcStrategy
