import React from 'react'
import Field from '../../../components/Field'
import Skeleton from 'react-loading-skeleton'
import { Row } from 'react-bootstrap'

type Props = {
  fields?: any
  method?: any
  onApply(method: string, settingId: string, value: any): void
}

const RampAppSettingLayout: React.FC<Props> = ({ fields, method, onApply }) => {
  return (
    <>
      {Object.entries(fields).map(([label, infos]: [string, any], field_index) => {
        if (infos.value) {
          return (
            <Field
              key={field_index}
              id={field_index}
              label={label}
              value={infos.value}
              onApply={infos.method ? (value) => onApply(infos.method as string, infos.prop?.id, value) : undefined}
            />
          )
        } else {
          return (
            <Row className="mt-2" key={field_index}>
              <Skeleton width={200} />
              <Skeleton />
            </Row>
          )
        }
      })}
    </>
  )
}

export default RampAppSettingLayout
