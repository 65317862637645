import React from 'react'
import { WalletContext } from './WalletContext'
import WalletWrapper, { WalletNames } from './WalletWrapper'
import { useMessages } from '../MessageProvider/MessagesContext'
import useProtectedState from '../hooks/useProtectedState'
import Sdk from './Sdk'

type Props = {
  ConnectPage: React.ReactElement
  LoadingPage: React.ReactElement
}

const WalletProvider: React.FC<Props> = ({ ConnectPage, LoadingPage, children }) => {
  const { addMessage } = useMessages()
  const [connected, setConnection, cancelConnectionState] = useProtectedState(false)
  const [isConnecting, setIsConnecting] = useProtectedState(false)
  const [sdk, setSdk] = useProtectedState(new Sdk())
  const [walletInfo, setWalletInfo, cancelSetWalletInfo] = useProtectedState({
    address: '',
    network: 0,
    provider: null as any
  })
  const [wallet] = React.useState<WalletWrapper>(
    new WalletWrapper({
      subscriptions: {
        wallet: (wallet, iWallet) => {
          addMessage({
            type: 'success',
            title: 'Wallet Status',
            message: `${wallet.name} connected!`
          })
          setWalletInfo({
            address: wallet.address,
            network: wallet.network,
            provider: wallet.provider
          })
          setSdk(new Sdk(iWallet))
          setConnection(true)
        }
      }
    })
  )

  React.useEffect(() => {
    console.log('walet: ', wallet)
    setIsConnecting(true)
    wallet
      .connectedWallet()
      .catch((err) => {
        addMessage({
          type: 'error',
          title: 'Error: ',
          message: err.data?.message || err.message
        })
      })
      .finally(() => {
        setIsConnecting(false)
      })
    return () => {
      cancelConnectionState()
      cancelSetWalletInfo()
    }
  }, [wallet])

  const connect = React.useCallback(
    async (name: WalletNames) => {
      try {
        setIsConnecting(true)
        await wallet.connect(name)
        await wallet.connectedWallet()
      } catch (err: any) {
        addMessage({
          type: 'error',
          title: 'Error: ',
          message: err.data?.message || err.message
        })
      } finally {
        setIsConnecting(false)
      }
    },
    [wallet]
  )

  const ethereum = (window as any).ethereum
  ethereum?.on('chainChanged', () => {
    window.location.reload()
  })

  const contextValue = React.useMemo(() => {
    return {
      connected,
      wallet: walletInfo,
      sdk,
      connect,
      isConnecting
    }
  }, [connected, walletInfo, sdk, connect, isConnecting])

  return (
    <WalletContext.Provider value={contextValue}>
      {!connected ? (isConnecting ? LoadingPage : ConnectPage) : children}
    </WalletContext.Provider>
  )
}

export default WalletProvider
