import Erc20Abi from '../abi/ERC20.json'
import AppSettingsAbi from '../abi/AppSettings.json'
import VaultAbi from '../abi/Vault.json'
import BankAbi from '../abi/Bank.json'
import ControllerAbi from '../abi/Controller.json'
import RampStakingStrategyAbi from '../abi/RampStakingStrategy.json'
import StaticErcStrategyAbi from '../abi/StaticErcStrategy.json'
import CakeLpStrategyAbi from '../abi/CakeLpStrategy.json'
import CakeLpStrategyAbiV6 from '../abi/CakeLpStrategy.v6.json'
import CakeAccruingStrategyAbi from '../abi/CakeAccruingStrategy.json'
import MintBurnHomeBridge from '../abi/MintBurnHomeBridgeErcToErc.json'
import MintBurnForeignBridge from '../abi/MintBurnForeignBridgeErc677ToErc677.json'
import AlpacaAccruingStrategy from '../abi/AlpacaAccruingStrategy.abi.json'
import BonusPoolAbi from '../abi/BonusPool.json'
import SingleAssetAccruingStrategy from '../abi/SingleAssetAccruingStrategy.json'
import NoDeploymentStrategy from '../abi/NoDeploymentStrategy.json'
import RusdHomeAMBErc677ToErc677 from '../abi/RusdHomeAMBErc677ToErc677.json'
import RusdForeignAMBErc677ToErc677 from '../abi/RusdForeignAMBErc677ToErc677.json'

export default {
  1: {
    accounts: {
      validator1: {
        address: '0x9D042F0BA502597775f959f82f39Df42215ebfC6',
        type: 'validator'
      },
      validator2: {
        address: '0x21E9113D7946f3E18957579122d94Ab302E19e69',
        type: 'validator'
      },
      treasury: {
        address: '',
        type: 'operator'
      }
    },
    bridge: {
      bscEth: {
        // address: '0xb1BB52029e91454941706E9020aE6f0525664D34',
        // Jun 29 8AM Demoing
        address: '0xb1BB52029e91454941706E9020aE6f0525664D34',
        abi: MintBurnForeignBridge
      },
      BSC_ETH_RUSD: {
        address: '0x6df6cC0b67F4bDe714fDF6fe32525910E368B7e8',
        abi: RusdForeignAMBErc677ToErc677
      },
      AVAX_ETH_RUSD: {
        address: '0xAc577B29CA67C222ccb5F2715E60108b7D88944B',
        abi: MintBurnForeignBridge
      },
      POLY_ETH_RUSD: {
        address: '0x23833C1391aE813A693CAb3C3B8DfE93F4d93d4a',
        abi: RusdForeignAMBErc677ToErc677
      }
    },
    contracts: {
      AppSettings: {
        address: '0xBf43C3C3182a56FDf5fEe52a6f4cD1364229C8a4',
        abi: AppSettingsAbi
      },
      Controller: {
        address: '0x3E84B21E2BF7C048992fD5dE5Ecd2a6Ad1351C02',
        abi: ControllerAbi
      },
      Bank: {
        address: '0x0390db7d64D38ceb5976F069410B24Cf315C53e4',
        abi: BankAbi
      },
      Vault: {
        address: '0xd87C52809a972FdAe2dBAB69D438510f17f7409A',
        abi: VaultAbi
      },
      RUSD: {
        address: '0xcf178a685471927e977a0ea8ee555c11b2aa6b7b',
        abi: Erc20Abi
      }
    },
    tokens: {
      Ramp: {
        address: '0x33D0568941C0C64ff7e0FB4fbA0B11BD37deEd9f',
        abi: Erc20Abi,
        strategy: {
          name: 'Eth_1',
          address: '0xCd239Bb5908a965baC1fd9A86F98b2688bC3c32a',
          abi: RampStakingStrategyAbi
        }
      },
      LpRampEth: {
        address: '0xECFe2c3c1aC0A5a59227A01f3Dd7044159b11a57',
        abi: Erc20Abi,
        strategy: {
          name: 'Eth_2',
          address: '0xdF6A572a0f7FBe9fb461A5b0e63d5328Ed8a83eE',
          abi: StaticErcStrategyAbi
        }
      }
    }
  },
  56: {
    accounts: {
      validator1: {
        address: '0x9492F913E5886B2Fe9558604b6A7747d1d4A16Bc',
        type: 'validator'
      },
      validator2: {
        address: '0x0D42355dC04Dd0Afdc7c5C96B467493936596968',
        type: 'validator'
      },
      validator3: {
        address: '0x68aa38EA795ddF59E02Fe883662C5f7eFac1bc4D',
        type: 'validator'
      },
      treasury: {
        address: '',
        type: 'operator'
      }
    },
    bridge: {
      bscEth: {
        address: '0x2099D05aa0210540cCAD180749DE26C52fD02B37',
        abi: MintBurnHomeBridge
      },
      polyBsc: {
        address: '0x764FD91e0A860fa4518709b717BC84DbCf7837F6',
        abi: MintBurnForeignBridge
      },
      BSC_ETH_RUSD: {
        address: '0x74d8b84f67e190ec00816C3d64371d02b4625738',
        abi: RusdHomeAMBErc677ToErc677
      },
      avaxBsc: {
        address: '0x29815889e8CD8d369c1d2aF892191DCE51Cc3343',
        abi: MintBurnForeignBridge
      }
    },
    contracts: {
      AppSettings: {
        address: '0xBf43C3C3182a56FDf5fEe52a6f4cD1364229C8a4',
        abi: AppSettingsAbi
      },
      Controller: {
        address: '0xCd239Bb5908a965baC1fd9A86F98b2688bC3c32a',
        abi: ControllerAbi
      },
      Bank: {
        address: '0xb1660202d4f14709cfdd835E0490b0C68321325f',
        abi: BankAbi
      },
      Vault: {
        address: '0xb7746f9eAc21285aBeF7D4E2c92ed415927b5285',
        abi: VaultAbi
      },
      RUSD: {
        address: '0x07663837218A003e66310a01596af4bf4e44623D',
        abi: Erc20Abi
      },
      BonusPool: {
        address: '0xD9D27dF5Ecc49718758956Ec566eAC03F03602cb',
        abi: BonusPoolAbi
      },
      BonusPool_Bank: {
        address: '0xF34b677622A764a2C6E280382F5BAD06D615C0C0',
        abi: BonusPoolAbi
      },
      BonusPool_Vault: {
        address: '0x02D8e0eb43066cB64d731D9411D64064f3a288da',
        abi: BonusPoolAbi
      }
    },
    tokens: {
      Ramp: {
        address: '0x8519ea49c997f50ceffa444d240fb655e89248aa',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_1',
          address: '0xA8Ccf29Cb3501E9932E5747a850f13A615CBf226',
          abi: RampStakingStrategyAbi
        }
      },
      PancakeLpRampBUsd: {
        address: '0xE834bf723f5bDff34a5D1129F3c31Ea4787Bc76a',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_2',
          address: '0x49dcc046301c4f2e831f64B485DCe671Cce581Cc',
          abi: CakeLpStrategyAbi
        }
      },
      LpRusdBusdPancake: {
        address: '0x59fac9e98479fc9979ae2a0c7422af50bcbb9b26',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_3',
          address: '0x9C9A578E7C3d9Fd7Ef174548bC3ef90e89bA4036',
          abi: CakeLpStrategyAbi
        }
      },
      PancakeLpNrvBnb: {
        address: '0xE482249Cd295C0d1e9D2baAEE71e66de21024C68',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_4',
          address: '0x7A66117c9604F735D776d379526f662ECf73E3F7',
          abi: CakeLpStrategyAbi
        }
      },
      NrvRUsd: {
        address: '0x870ee4d19c12a789c61de69e3e5efb42383e4434',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_5',
          address: '0x0403c30380276F9A377E60A0da00584b7d283966',
          abi: StaticErcStrategyAbi
        }
      },
      PancakeLpInjBnb: {
        address: '0x1BdCebcA3b93af70b58C41272AEa2231754B23ca',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_6',
          address: '0x475Bb9bC8507A08def99603ab76F5bc6EF660481',
          abi: CakeLpStrategyAbi
        }
      },
      Cake: {
        address: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_7',
          address: '0x65769e35c814560C5c81B6328B7ffBF2c2f83Aa5',
          abi: CakeAccruingStrategyAbi
        }
      },
      PancakeLpNulsBusd: {
        address: '0x853784B7BDe87d858555715c0123374242db7943',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_8',
          address: '0x0bbC9D6db712965Ea2Cb6218192946c5cd105373',
          abi: CakeLpStrategyAbi
        }
      },
      LpPancakeMcoinUst: {
        address: '0xBCf01a42f6BC42F3Cfe81B05519565044d65D22a',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_9',
          address: '0xC36F7cF4004F393DA1C16a29F3F27b3dA0007e28',
          abi: CakeLpStrategyAbi
        }
      },
      LpPancakeMirUst: {
        address: '0x89666d026696660e93Bf6edf57B71A68615768B7',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_10',
          address: '0x5edA5bbD0e722B6fcf5959ba644ECAce5ee9346F',
          abi: CakeLpStrategyAbi
        }
      },
      LpPancakeUstBusd: {
        address: '0x05faf555522Fa3F93959F86B41A3808666093210',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_11',
          address: '0x99cDCE0778F5a112e7eB27fDf24D4388F5fc35D2',
          abi: CakeLpStrategyAbi
        }
      },
      LpPancakeIotxBusd: {
        address: '0xc13aA76AAc067c86aE38028019F414D731b3D86A',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_12',
          address: '0x84F1b3CEAf40DBAB92220c04369753634080B06c',
          abi: CakeLpStrategyAbi
        }
      },
      WbnbAlpaca: {
        address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_13',
          address: '0x4d4C5509964426E25500DAEb17855Af6961287C1',
          abi: AlpacaAccruingStrategy
        }
      },
      BusdAlpaca: {
        address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_14',
          address: '0x256E3d5d600258519922a605776ddCa26D68716F',
          abi: AlpacaAccruingStrategy
        }
      },
      BscUsdAlpaca: {
        address: '0x55d398326f99059ff775485246999027b3197955',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_15',
          address: '0xA10793C8F2300b84832EDaC241cABf66C3203f2d',
          abi: AlpacaAccruingStrategy
        }
      },
      EthAlpaca: {
        address: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_16',
          address: '0xE5d57545A2F09e1aF52e0Ef156FDD81a688e8857',
          abi: AlpacaAccruingStrategy
        }
      },
      RusdAlpaca: {
        address: '0x07663837218A003e66310a01596af4bf4e44623D',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_17',
          address: '0x9C056DbBA1db552c72D1BF852882142dc5ebb5B1',
          abi: StaticErcStrategyAbi
        }
      },
      CakeBnb: {
        address: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_18',
          address: '0x63d55B46338CBE883d267aFcdF77E8F30f98900E',
          abi: CakeLpStrategyAbiV6
        }
      },
      BnbBusd: {
        address: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_19',
          address: '0x630a0089f820E60f569203F1a1986068c29208b0',
          abi: CakeLpStrategyAbiV6
        }
      },
      UsdtBnb: {
        address: '0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_20',
          address: '0x98Bc9Bf2b6b80614320d798A742CDE072c4da8Ed',
          abi: CakeLpStrategyAbiV6
        }
      },
      EthBnb: {
        address: '0x74E4716E431f45807DCF19f284c7aA99F18a4fbc',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_21',
          address: '0x4aBEe27Ff910FEf6e450f79C41A0C120cB58783E',
          abi: CakeLpStrategyAbiV6
        }
      },
      BtcbBnb: {
        address: '0x61EB789d75A95CAa3fF50ed7E47b96c132fEc082',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_22',
          address: '0xAA1dC4109bf180D14C75209AFd070f1B3141Ad00',
          abi: CakeLpStrategyAbiV6
        }
      },
      CakeBusd: {
        address: '0x804678fa97d91B974ec2af3c843270886528a9E6',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_23',
          address: '0x4f77e6472a377c7F50103f788c44f77E7BacFdA4',
          abi: CakeLpStrategyAbiV6
        }
      },
      BnbLink: {
        address: '0x824eb9faDFb377394430d2744fa7C42916DE3eCe',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_24',
          address: '0x4f91d16de6ECdd59af596Ff7f6B928c1be92A0c8',
          abi: CakeLpStrategyAbiV6
        }
      },
      TrxBnb: {
        address: '0x3cd338c3BB249B6b3C55799F85a589FEbBBFf9Dd',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_25',
          address: '0x79c6ad6Bf833E454480Ee70bd0BcbD8a7a89E4a7',
          abi: CakeLpStrategyAbiV6
        }
      },
      DotBnb: {
        address: '0xDd5bAd8f8b360d76d12FdA230F8BAF42fe0022CF',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_26',
          address: '0xd0d31044Cbe79859ff8AE731F8ffC521D403b766',
          abi: CakeLpStrategyAbiV6
        }
      },
      CakeUsdt: {
        address: '0xA39Af17CE4a8eb807E076805Da1e2B8EA7D0755b',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_27',
          address: '0x62864d5a3854A8E5aA22e5925636315E7B06ee34',
          abi: CakeLpStrategyAbiV6
        }
      },
      DogeBnb: {
        address: '0xac109C8025F272414fd9e2faA805a583708A017f',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_28',
          address: '0x9CB21525A1096847030F0A75d74cDbE48F7949C8',
          abi: CakeLpStrategyAbiV6
        }
      },
      Btcb: {
        address: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_29',
          address: '0x2094cF2e86576B7C47bc1019749c50873094934F',
          abi: CakeLpStrategyAbiV6
        }
      },
      Link: {
        address: '0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_30',
          address: '0x8C5A65986D156bbF1617d82520D51cF8064cE62a',
          abi: SingleAssetAccruingStrategy
        }
      },
      BtcbEth: {
        address: '0xd171b26e4484402de70e3ea256be5a2630d7e88d',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_31',
          address: '0x9B4540AF86E1FF32e86B7a32d318CB31Edb07603',
          abi: CakeLpStrategyAbi
        }
      },
      BtcbBusd: {
        address: '0xf45cd219aef8618a92baa7ad848364a158a24f33',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_32',
          address: '0xAC202BD93245c393A9A855be693cD07fb0cA2693',
          abi: CakeLpStrategyAbi
        }
      },
      AdaBnb: {
        address: '0x28415ff2c35b65b9e5c7de82126b4015ab9d031f',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_33',
          address: '0xbC20233565EE1F976f4EeCE03bb24c831A6c5038',
          abi: CakeLpStrategyAbi
        }
      },
      XvsBnb: {
        address: '0x7eb5d86fd78f3852a3e0e064f2842d45a3db6ea2',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_34',
          address: '0xf32676C0Dcf919A13Ea0E166980b8fA8E08C4a74',
          abi: CakeLpStrategyAbi
        }
      },
      BetaBnb: {
        address: '0x88a02d94f437799f06f8c256ff07aa397e6d0016',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_35',
          address: '0x4a40c984baCEF83AA35E1d9E5d95A9294C71A138',
          abi: CakeLpStrategyAbi
        }
      },
      AxsBnb: {
        address: '0xc2d00de94795e60fb76bc37d899170996cbda436',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_36',
          address: '0x1D6fc6303685A59E5D1B5784cC4Cf6f392E532B8',
          abi: CakeLpStrategyAbi
        }
      },
      RusdRamp: {
        address: '0x9c0809c3c1a51c429d4df1af34b0d0f777f8ac6d',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_37',
          address: '0x22640dD79445345CE2202ee9A17f46421bee924D',
          abi: StaticErcStrategyAbi
        }
      }
    }
  },
  137: {
    accounts: {
      validator1: {
        address: '0x9492F913E5886B2Fe9558604b6A7747d1d4A16Bc',
        type: 'validator'
      },
      validator2: {
        address: '0x0D42355dC04Dd0Afdc7c5C96B467493936596968',
        type: 'validator'
      },
      validator3: {
        address: '0x68aa38EA795ddF59E02Fe883662C5f7eFac1bc4D',
        type: 'validator'
      },
      treasury: {
        address: '',
        type: 'operator'
      }
    },
    bridge: {
      polyBsc: {
        // Jun 29 8AM Demoing
        // address: '0x822b2319b9e7cB3D1bf81cBE9e8d41126c27209d',
        address: '0x4C9ce26B057F6C51e5Ed7a619f161233A4D43453',
        abi: MintBurnHomeBridge
      },
      POLY_ETH_RUSD: {
        address: '0xE85AF4c5Fc714237EB876D6C4F056d6799246782',
        abi: RusdHomeAMBErc677ToErc677
      },
      POLY_AVAX_RUSD: {
        address: '0xCD9e204f3eFb81Eaf1f6e0d610acdbD18E8cD9a8',
        abi: RusdHomeAMBErc677ToErc677
      }
    },
    contracts: {
      AppSettings: {
        address: '0x9249bAD5794837561730916Cf3A96f166A791020',
        abi: AppSettingsAbi
      },
      Controller: {
        address: '0xdd1F59c63c7CD87b173e3013eB9D29991416D763',
        abi: ControllerAbi
      },
      Bank: {
        address: '0x5e8CE9c1B04E9b3F9C5170706230A4B1aeCBBE1E',
        abi: BankAbi
      },
      Vault: {
        address: '0xA88c84a25e1dD32df24801e2d48869466316DFb1',
        abi: VaultAbi
      },
      RUSD: {
        address: '0xfC40a4F89b410a1b855b5e205064a38fC29F5eb5',
        abi: Erc20Abi
      }
    },
    tokens: {
      Ramp: {
        address: '0xaECeBfcF604AD245Eaf0D5BD68459C3a7A6399c2',
        abi: Erc20Abi,
        strategy: {
          name: 'Polygon_1',
          address: '0x36f8435458673a7934B14E6bD1C8bcC25C8b12EE',
          abi: RampStakingStrategyAbi
        }
      },
      MaticEth: {
        address: '0xadbF1854e5883eB8aa7BAf50705338739e558E5b',
        abi: Erc20Abi,
        strategy: {
          name: 'Polygon_2',
          address: '0xBe01B3080eCc8b3D439344011270c590FED4872F',
          abi: CakeLpStrategyAbi
        }
      },
      RampEth: {
        address: '0xe55739e1feb9f9aed4ce34830a06ca6cc37494a0',
        abi: Erc20Abi,
        strategy: {
          name: 'Polygon_3',
          address: '0x3fE751e49B694e5B85304EE330366566a7817629',
          abi: CakeLpStrategyAbi
        }
      },
      RUsdUsdc: {
        address: '0x5ef8747d1dc4839e92283794a10d448357973ac0',
        abi: Erc20Abi,
        strategy: {
          name: 'Polygon_4',
          address: '0xB6488a96E6BDE6bBf16edf030E964Be64F1672f5',
          abi: CakeLpStrategyAbi
        }
      },
      MaticUsdc: {
        // Token Address
        address: '0x6e7a5fafcec6bb1e78bae2a1f0b612012bf14827',
        abi: Erc20Abi,
        strategy: {
          name: 'Polygon_5',
          // Strategy Address
          address: '0x2dDBaB9526E97cD1C153289A3b624C4855a5ca30',
          // Abi that will be used with the strategy
          abi: CakeLpStrategyAbi
        }
      },
      WbtcUsdc: {
        // Token Address
        address: '0xF6a637525402643B0654a54bEAd2Cb9A83C8B498',
        abi: Erc20Abi,
        strategy: {
          name: 'Polygon_6',
          // Strategy Address
          address: '0x0D927C4c5425B5cAC5817c65BA1EBF9bd6c74bE1',
          // Abi that will be used with the strategy
          abi: CakeLpStrategyAbi
        }
      },
      DaiwEth: {
        // Token Address
        address: '0x4A35582a710E1F4b2030A3F826DA20BfB6703C09',
        abi: Erc20Abi,
        strategy: {
          name: 'Polygon_7',
          // Strategy Address
          address: '0x0208013BC296d11138F2e5B3f488B031d45A832f',
          // Abi that will be used with the strategy
          abi: CakeLpStrategyAbi
        }
      },
      EthUsdc: {
        // Token Address
        address: '0x853Ee4b2A13f8a742d64C8F088bE7bA2131f670d',
        abi: Erc20Abi,
        strategy: {
          name: 'Polygon_8',
          // Strategy Address
          address: '0xAeb62f74657efA15afA2AEAA2C031983F48080E2',
          // Abi that will be used with the strategy
          abi: CakeLpStrategyAbi
        }
      },
      LinkEth: {
        // Token Address
        address: '0x5cA6CA6c3709E1E6CFe74a50Cf6B2B6BA2Dadd67',
        abi: Erc20Abi,
        strategy: {
          name: 'Polygon_9',
          // Strategy Address
          address: '0xb4bCBc9ee59236d888212ed9b942be853655b8cC',
          // Abi that will be used with the strategy
          abi: CakeLpStrategyAbi
        }
      },
      Rusd: {
        // Token Address
        address: '0xfC40a4F89b410a1b855b5e205064a38fC29F5eb5',
        abi: Erc20Abi,
        strategy: {
          name: 'Polygon_10',
          // Strategy Address
          address: '0x0abb35484db4C5E0625b6509A623bc72568ac39b',
          // Abi that will be used with the strategy
          abi: StaticErcStrategyAbi
        }
      },
      UsdcQuick: {
        // Token Address
        address: '0x1F1E4c845183EF6d50E9609F16f6f9cAE43BC9Cb',
        abi: Erc20Abi,
        strategy: {
          name: 'Polygon_11',
          // Strategy Address
          address: '0xfa4575b60Da551b2Bad0389D35a25bfE704504D2',
          // Abi that will be used with the strategy
          abi: CakeLpStrategyAbi
        }
      },
      EthQuick: {
        // Token Address
        address: '0x1Bd06B96dd42AdA85fDd0795f3B4A79DB914ADD5',
        abi: Erc20Abi,
        strategy: {
          name: 'Polygon_12',
          // Strategy Address
          address: '0xB76c1284d1b4CD1F72a61b3191FA52ebD0bd69CE',
          // Abi that will be used with the strategy
          abi: CakeLpStrategyAbi
        }
      },
      MaticQuick: {
        // Token Address
        address: '0x019ba0325f1988213D448b3472fA1cf8D07618d7',
        abi: Erc20Abi,
        strategy: {
          name: 'Polygon_13',
          // Strategy Address
          address: '0x63A6098EB138AEbfB840a786DE0A91e4D098cea6',
          // Abi that will be used with the strategy
          abi: CakeLpStrategyAbi
        }
      },
      wMatic: {
        // Token Address
        address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
        abi: Erc20Abi,
        strategy: {
          name: 'Polygon_14',
          // Strategy Address
          address: '0x768cbd2eD2af9Aa2A393250fEFC13309E8AD4309',
          // Abi that will be used with the strategy
          abi: CakeLpStrategyAbi
        }
      },
      wEth: {
        // Token Address
        address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
        abi: Erc20Abi,
        strategy: {
          name: 'Polygon_15',
          // Strategy Address
          address: '0xe83a7671A2524795d26e9401d6d02eC56b55A54e',
          // Abi that will be used with the strategy
          abi: CakeLpStrategyAbi
        }
      },
      wBtc: {
        // Token Address
        address: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
        abi: Erc20Abi,
        strategy: {
          name: 'Polygon_16',
          // Strategy Address
          address: '0x955eb613039244736734Bf9F025beAc14b05865C',
          // Abi that will be used with the strategy
          abi: CakeLpStrategyAbi
        }
      }
    }
  },
  43114: {
    contracts: {
      AppSettings: {
        address: '0x68D00380Ee3170e85D63BF9eDd68CDbC4ff02f62',
        abi: AppSettingsAbi
      },
      Controller: {
        address: '0x80f947318cfaf1Fde8c6E8917a23D7aCDA3C8F97',
        abi: ControllerAbi
      },
      Bank: {
        address: '0xb6D8F9AED6bEd11Bdcf3F5B859F6c856539d2Ba2',
        abi: BankAbi
      },
      Vault: {
        address: '0x43fd8Fc68CA83acDDe0C128403e48CAD3f0271E3',
        abi: VaultAbi
      },
      RUSD: {
        address: '0xAAFC37f3A34939cb0Ccd2d44334C11224CE7A5AC',
        abi: Erc20Abi
      },
      Ramp: {
        address: '0x4d4C5509964426E25500DAEb17855Af6961287C1',
        abi: Erc20Abi
      }
    },
    accounts: {
      validator1: {
        address: '0x48AAc5F7f153Eb30D6dE88181476ddA0C0E65338',
        type: 'validator'
      },
      validator2: {
        address: '0xEaD36Bb66b9E778C0C083C8CABbAF9EC6179cc7F',
        type: 'validator'
      },
      validator3: {
        address: '0x0b1837a12f40Bf4Dcb08ed385fDb3570cd2e1fbd',
        type: 'validator'
      },
      validator4: {
        address: '0xdfC1B7E16A5FCEaF1c9c8d355B5d37faB02e3a9D',
        type: 'validator'
      },
      treasury: {
        address: '',
        type: 'operator'
      }
    },
    bridge: {
      avaxBsc: {
        address: '0x8293BF95eD8591438dF5065E0477FCf1560AF562',
        abi: MintBurnHomeBridge
      },
      AVAX_ETH_RUSD: {
        address: '0xAb043DD8932aF1274D1962Bea3d34AB99766a75E',
        abi: MintBurnHomeBridge
      },
      POLY_AVAX_RUSD: {
        address: '0xCa93110d79700DC26B2CF1180603b408cbd4fb75',
        abi: RusdForeignAMBErc677ToErc677
      }
    },
    tokens: {
      WAvax: {
        address: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
        abi: Erc20Abi,
        strategy: {
          name: 'Avax_1',
          address: '0x6c1dEF1481F23f6237dFbAF20C71F0e78Cdb3308',
          abi: SingleAssetAccruingStrategy
        }
      }
    }
  },
  1284: {
    contracts: {
      AppSettings: {
        address: '0xaCA6b3fc6E4959Fe7936421AAA29417c1B1Ca403',
        abi: AppSettingsAbi
      },
      Controller: {
        address: '0x00cB0B91095d4bE126C98b8A57870b4E573b6aad',
        abi: ControllerAbi
      },
      Bank: {
        address: '0xE5d57545A2F09e1aF52e0Ef156FDD81a688e8857',
        abi: BankAbi
      },
      Vault: {
        address: '0xA10793C8F2300b84832EDaC241cABf66C3203f2d',
        abi: VaultAbi
      },
      RUSD: {
        address: '0xAAFC37f3A34939cb0Ccd2d44334C11224CE7A5AC',
        abi: Erc20Abi
      }
    },
    tokens: {
      wGLMR: {
        address: '0xAcc15dC74880C9944775448304B263D191c6077F',
        abi: Erc20Abi,
        strategy: {
          name: 'Moonbeam_1',
          address: '0x6Cc580e2e581c3270179669d0594B62cc5974740',
          abi: NoDeploymentStrategy
        }
      }
    }
  }
}
