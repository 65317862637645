import React from 'react'
import { StrategyProps } from './types'
import { useWallet } from '../utils/WalletProvider/WalletContext'
import useFieldReducer, { parseToFieldAction, resetFieldsValue } from './useFieldReducer'
import { useMessages } from '../utils/MessageProvider/MessagesContext'
import BaseStrategy from './BaseStrategy'

const fieldConfigs = {
  poolId: {
    value: ''
  },
  tokenA: {
    value: ''
  },
  tokenB: {
    value: ''
  }
}

const CakeLpStrategy: React.FC<StrategyProps> = ({ tokenConfig, data }) => {
  const { sdk } = useWallet()
  const { addMessage } = useMessages()
  const [_fields, setFields, cancelSetFields, setLoading] = useFieldReducer(fieldConfigs)

  const fetchPoolInfo = React.useCallback(() => {
    resetFieldsValue(fieldConfigs, setFields)
    sdk
      .send(tokenConfig.strategy.address, 'tokenPoolInfo', [tokenConfig.address], {
        abi: tokenConfig.strategy.abi,
        mapOutput: true
      })
      .then((poolInfo) => {
        setFields(parseToFieldAction(poolInfo))
      })
      .catch((err) => {
        addMessage({
          type: 'error',
          title: 'Error: ',
          message: err.data?.message || err.message
        })
      })
  }, [sdk, tokenConfig.strategy.address, tokenConfig.address])

  React.useEffect(() => {
    fetchPoolInfo()
    return () => {
      cancelSetFields()
    }
  }, [fetchPoolInfo])

  return (
    <>
      <BaseStrategy
        tokenConfig={tokenConfig}
        data={(props) => {
          return data({
            fields: {
              ...props.fields,
              ..._fields
            },
            setFields,
            methods: props.methods,
            reset: () => {
              props.reset()
              fetchPoolInfo()
            },
            setLoading
          })
        }}
      />
    </>
  )
}

export default CakeLpStrategy
