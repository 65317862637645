import { IWallet, Options, Wallet } from './IWallet'
import detectEthereumProvider from '@metamask/detect-provider'
import { ethers } from 'ethers'

class Metamask implements IWallet {
  network?: number
  private _signer?: ethers.Signer
  private _provider?: ethers.providers.Web3Provider

  async connect(): Promise<void> {
    const provider = await detectEthereumProvider()
    if (!provider) return

    const ethereum = (window as any).ethereum
    if (ethereum && ethereum.providers?.length) {
      ethereum.providers = ethereum?.providers?.filter(({ isMetaMask }: { isMetaMask: boolean }) => isMetaMask)
      ethereum.setSelectedProvider(ethereum.providers[0])
    }

    await ethereum.request({
      method: 'eth_requestAccounts'
    })
  }

  async connected(timeout?: number): Promise<Wallet | undefined> {
    let provider: any = await detectEthereumProvider()
    if (!provider) return

    const ethereum = (window as any).ethereum
    if (ethereum && ethereum.providers?.length) {
      ethereum.providers = ethereum?.providers?.filter(({ isMetaMask }: { isMetaMask: boolean }) => isMetaMask)
      ethereum.setSelectedProvider(ethereum.providers[0])
    }

    const info = await Promise.race([
      Promise.all([
        ethereum.request({
          method: 'eth_accounts'
        }),
        ethereum.request({
          method: 'eth_chainId'
        })
      ]),
      new Promise((resolve) => {
        setTimeout(resolve, timeout || 2000)
      })
    ])
    if (!info) return

    const [accounts, network] = info as [string[], string]
    const account = accounts[0]
    if (!account) return

    provider = new ethers.providers.Web3Provider(ethereum)

    this._provider = provider as ethers.providers.Web3Provider
    this._signer = this._provider.getSigner(account)
    this.network = Number.parseInt(network)
    const activeAddress = await this._signer.getAddress()

    return {
      type: 'inject',
      name: 'Metamask',
      address: activeAddress,
      network: Number.parseInt(network),
      provider
    }
  }

  async send(address: string, abi: any[], method: string, args: any[], options: Options): Promise<any | boolean> {
    const contract = new ethers.Contract(address, abi, this._signer)

    const result: any | boolean = await new Promise((resolve, reject) => {
      contract[method](...args)
        .then((res: any) => {
          if (res.hash) {
            this._provider?.once(res.hash, () => {
              resolve(true)
            })
          } else {
            resolve(res)
          }
        })
        .catch(reject)
    })

    if (typeof result === 'boolean') {
      return result
    }

    if (!options.mapOutput) return result

    const newResult: any = {}

    if (options.mapOutput) {
      const outputs = this.findMethodOutput(contract, method)
      outputs?.forEach((output) => {
        newResult[output.name] = result[output.name]
      })
    }

    return newResult
  }

  private findMethodOutput(contract: ethers.Contract, method: string) {
    return Object.values(contract.interface.functions).find((f) => f.name === method)?.outputs
  }

  async getLatestBlockNr(): Promise<number> {
    if (!this._provider) throw new Error('Connect to wallet metamask first.')
    return this._provider.getBlockNumber()
  }
}

export default Metamask
