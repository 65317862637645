import React from 'react'
import { RecordField } from '../../contractHooks/useFieldReducer'
import { Validator } from './BridgeClass'
import Field from '../../components/Field'
import ScanLink from '../../components/ScanLink'
import { useConfig } from '../../utils/ConfigProvider/ConfigContext'
import { useQuery } from '../../utils/url'
import { Button, Col, Row } from 'react-bootstrap'
import ContainerLabeler from '../../components/ContainerLabeler'
import { useWallet } from '../../utils/WalletProvider/WalletContext'
import Skeleton from 'react-loading-skeleton'
import { BridgeListProps } from './Bridge'

type Props = {
  validators: Record<string, Validator>
  fields: RecordField
  isValidatorsLoading?: boolean
  isFieldsLoading?: boolean
  onRefresh(): void
  bridgeInfos: Record<string, string>
  onApply(methodName: string, value: any): void
  bridgeSampleList?: BridgeListProps
}

const BridgeLayout: React.FC<Props> = ({
  validators,
  fields,
  isValidatorsLoading,
  isFieldsLoading,
  onRefresh,
  bridgeInfos,
  onApply,
  bridgeSampleList
}) => {
  const { config } = useConfig()
  const { query, setQuery } = useQuery()
  const { wallet } = useWallet()
  const activeBridgename = query.name || Object.keys(config.bridge)[0]
  const priceSymbol =
    wallet.network === 1 ? 'ETH' : wallet.network === 56 ? 'BNB' : wallet.network === 43114 ? 'AVAX' : 'MATIC'
  const [networkHandler, setNetworkhandler] = React.useState<number>(wallet.network)
  const [buttonStartActive, setButtonStartActive] = React.useState<string>()
  const tt = React.useMemo(() => {
    console.log('fields: ', fields)
    return fields
  }, [fields])

  React.useEffect(() => {
    let isMounted = true

    if (bridgeSampleList) {
      if (isMounted) {
        bridgeSampleList
          .filter((asd, i) => {
            return asd.name === activeBridgename && asd.network === wallet.network
          })
          .map((d) => {
            setButtonStartActive(d.label)
          })

        isMounted = false
      }
    }

    return function cleanup() {
      isMounted = false
    }
  }, [])

  const [buttonHandler, setButtonHandler] = React.useState<string>(buttonStartActive as string)

  const queryAction = (value: string, network: number) => {
    setNetworkhandler(network)
    setQuery('name', value)
  }

  function isActive(name: string, walletNetwork: number, label: string) {
    if (!buttonHandler) {
      return name === activeBridgename && walletNetwork === wallet.network ? 'active-tab' : ''
    } else {
      return name === activeBridgename && label === buttonHandler ? 'active-tab' : ''
    }
  }

  function isDisable() {
    return isValidatorsLoading ? 'disabled-button' : ''
  }

  function isCorrectNetwork(network: number) {
    return network === wallet?.network ? (
      <>
        <Row>
          <ContainerLabeler label={'Info'}>
            <Col className="bridge-info-container">
              {Object.entries(bridgeInfos).map(([label, info], index) => {
                if (info)
                  return (
                    <div key={index}>
                      <span>Address: </span>
                      <ScanLink search={info?.toLowerCase()} type={'address'} showAll={true}>
                        <span>{info?.toLowerCase()}</span>
                      </ScanLink>
                    </div>
                  )
              })}
            </Col>
          </ContainerLabeler>
          <ContainerLabeler label={'Validators'}>
            {!isValidatorsLoading ? (
              <Col className="bridge-validators">
                {Object.entries(validators).map(([validatorName, info], index) => {
                  return (
                    <div key={index} className={'each-validator-content'}>
                      <div className="each-validator-content__header-container">
                        <h6>Validator {index + 1}</h6>
                      </div>

                      <span>
                        Address:{' '}
                        <ScanLink search={info.address} type={'address'} showAll={true}>
                          {info.address}
                        </ScanLink>
                      </span>

                      <span className="bridge-validators-balance">
                        Balance:{' '}
                        <span className="bridge-validators-balance__value">{info.balance + ' ' + priceSymbol}</span>
                      </span>
                    </div>
                  )
                })}
              </Col>
            ) : (
              <div className="skeleton-validator-container">
                <div className="skeleton-validator-items">
                  <div>
                    <Skeleton width={200} />
                  </div>
                  <div>
                    <Skeleton count={2} />
                  </div>
                </div>
                <div className="skeleton-validator-items">
                  <div>
                    <Skeleton width={200} />
                  </div>
                  <div>
                    <Skeleton count={2} />
                  </div>
                </div>
              </div>
            )}
          </ContainerLabeler>

          <ContainerLabeler label={'Fields'}>
            <Col className="bridge-fields">
              {Object.entries(tt).map(([fieldLabel, info], field_index) => {
                return info.disabled ? undefined : !isFieldsLoading ? (
                  <Field
                    id={field_index}
                    key={field_index}
                    label={fieldLabel}
                    onApply={
                      info.method
                        ? (value) => {
                            onApply(info.method as string, value)
                          }
                        : undefined
                    }
                    value={info.value}
                  />
                ) : (
                  <React.Fragment key={field_index}>
                    <div className="skeleton-validator-container">
                      <div className="skeleton-validator-items">
                        <div>
                          <Skeleton width={200} />
                        </div>
                        <div>
                          <Skeleton count={1} />
                        </div>
                      </div>
                      <div className="skeleton-validator-items">
                        <div>
                          <Skeleton width={200} />
                        </div>
                        <div>
                          <Skeleton count={1} />
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )
              })}
            </Col>
          </ContainerLabeler>
        </Row>
      </>
    ) : (
      <Row className="inc-net-container">
        <Col className="inc-net-body">
          <h4>Incorrect network</h4>
          <span className="mb-4">Please connect to the right network</span>
          <Button className="switch-button" disabled>
            Switch to -- network (Soon)
          </Button>
        </Col>
      </Row>
    )
  }

  React.useEffect(() => {
    isCorrectNetwork(networkHandler)
  }, [fields])

  React.useEffect(() => {
    console.log('validators: ', validators)
  }, [validators])

  return (
    <>
      <Row className="d-flex flex-column">
        <Col className="bridge-tabs-container">
          {bridgeSampleList?.map((bSL, bSL_index) => {
            return (
              <button
                className={
                  'bridge-tab-button ' + isActive(bSL.name as string, bSL.network as number, bSL.label as string)
                }
                id={`bridge-tab-${bSL_index}`}
                key={bSL_index}
                onClick={() => {
                  setButtonHandler(bSL.label)
                  queryAction(bSL.name as string, bSL.network as number)
                }}
              >
                {bSL.label}
              </button>
            )
          })}

          <button
            className="bridge-tab-button bridge-tabs-refresh"
            onClick={() => {
              onRefresh()
            }}
          >
            <i className="bi bi-arrow-repeat" />
          </button>
        </Col>
        <Row className="bridge-sub-body">{isCorrectNetwork(networkHandler)}</Row>
      </Row>
    </>
  )
}

export default BridgeLayout
