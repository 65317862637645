import React from 'react'

const useProtectedState = <S>(
  initialState: S | (() => S)
): [S, React.Dispatch<React.SetStateAction<S>>, () => void] => {
  let isActive = true
  const [values, setValues] = React.useState(initialState)

  const _setValues: React.Dispatch<React.SetStateAction<S>> = (state): void => {
    if (typeof state === 'function') {
      if (!isActive) return
      setValues((state as (prevState: S | undefined) => S)(values))
    } else {
      if (!isActive) return
      setValues(state)
    }
  }

  const cancel = () => {
    isActive = false
  }

  return [values, _setValues, cancel]
}

export default useProtectedState
