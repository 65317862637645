import React from 'react'
import { BigNumber, ethers } from 'ethers'
import _ from 'lodash'

export type FieldValue = string | boolean

export type Field<P> = {
  value: FieldValue
  type?: string
  unit?: string
  commify?: boolean
  method?: string
  prop?: P
  description?: string
  optionalLabel?: string
  loading?: boolean
  noDecimal?: boolean
  disabled?: boolean
  disabledNew?: Array<string>
  infoText?: string
}

export type RecordField<P = any> = Record<string, Field<P>>

export type RecordFieldMethods = Record<string, (value: any) => Promise<any>>

export type FieldAction = {
  prop: string
  value: FieldValue | ethers.BigNumber
  loading?: boolean
  options?: {
    parse?: boolean
    unit?: boolean
    commify?: boolean
  }
}

export type LoadingAction = {
  prop: string
  loading: boolean
}

export const parseToFieldAction = (object: Record<string, string | boolean>): FieldAction[] => {
  return (Object.entries(object) as [string, FieldValue][]).map(([prop, value]) => {
    return {
      prop,
      value
    }
  })
}

export const resetFieldsValue = (fields: RecordField, set: React.Dispatch<FieldAction | FieldAction[]>): void => {
  const actions: FieldAction[] = []
  for (const prop of Object.keys(fields)) {
    actions.push({
      prop,
      value: ''
    })
  }
  set(actions)
}

const reducer = (fields: RecordField, action: FieldAction | FieldAction[] | LoadingAction) => {
  const fieldsCopy = { ...fields }

  let fieldConfig
  if (_.isArray(action)) {
    const actions = action as FieldAction[]
    for (const act of actions) setField(act)
  } else if ((action as FieldAction).value === undefined) {
    const act = action as LoadingAction
    setLoading(act)
  } else {
    const act = action as FieldAction
    setField(act)
  }

  function setLoading(act: LoadingAction) {
    fieldConfig = fieldsCopy[act.prop]

    if (!fieldConfig) return
    fieldConfig.loading = act.loading || false
  }

  function setField(act: FieldAction) {
    let formattedValue: FieldValue = 'undefined'
    fieldConfig = fieldsCopy[act.prop]

    if (!fieldConfig) return
    fieldConfig.loading = act.loading || false

    if (!fieldConfig) return
    if (BigNumber.isBigNumber(act.value)) {
      if (fieldConfig.unit && fieldConfig.unit !== '0' && fieldConfig.unit !== 'wei') {
        formattedValue = ethers.utils.formatUnits(act.value, fieldConfig.unit)
      } else {
        formattedValue = act.value.toString()
      }
    } else {
      formattedValue = act.value
    }

    if (fieldConfig.commify) {
      //Custom for mint Capacity only
      if (fieldConfig.method === 'updateMintCapacity' || fieldConfig.method === 'setCirculatingSupply') {
        const noDecimalWithCommifyStep1 = ethers.utils.commify(formattedValue.toString())
        const noDecimalWithCommifyStep2 = noDecimalWithCommifyStep1.split(',').join('')
        const noDecimalWithCommifyStep3 = Math.trunc(parseInt(noDecimalWithCommifyStep2)).toString()
        formattedValue = ethers.utils.commify(noDecimalWithCommifyStep3.toString())
      } else {
        formattedValue = ethers.utils.commify(formattedValue.toString())
      }
    }

    fieldConfig.value = formattedValue
  }

  return fieldsCopy
}

const useFieldReducer = (
  defaultFields: RecordField
): [
  RecordField,
  React.Dispatch<FieldAction | FieldAction[]>,
  () => void,
  (action: { prop: string; loading: boolean }) => void
] => {
  let isActive = true
  const [fields, setFields] = React.useReducer(reducer, defaultFields)

  const _setFields: React.Dispatch<FieldAction | FieldAction[]> = (action) => {
    if (!isActive) return
    setFields(action)
  }

  const _setLoading = (action: LoadingAction) => {
    if (!isActive) return
    setFields(action)
  }

  const cancel = () => {
    isActive = false
  }

  return [fields, _setFields, cancel, _setLoading]
}

export default useFieldReducer
