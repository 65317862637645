import { toTitle } from '../helpers'
import { Header } from '../types'

interface Props {
  headers: Array<Header>
  isToTitle?: boolean
}

const Headers: React.FC<Props> = ({ headers, isToTitle = true }) => {
  const withSubHeaders = headers.filter((h) => h.subHeaders?.length)
  const subHeaders = withSubHeaders
    .map((wsh) =>
      wsh.subHeaders?.map((sh) => {
        return { ...sh, id: `${wsh.id}-${sh.id}` }
      })
    )
    .flat()
  const isSubHeaders = subHeaders.length > 0

  return (
    <thead>
      <tr>
        {headers.map((h) => {
          return (
            <th key={h.id} colSpan={h.subHeaders?.length ?? 1} rowSpan={!h.subHeaders?.length ? 2 : 1}>
              {isToTitle ? toTitle(h.name) : h.name}
            </th>
          )
        })}
      </tr>
      {isSubHeaders && (
        <tr>
          {subHeaders.map((sh) => {
            return <th key={sh?.id}>{isToTitle ? toTitle(sh?.name ?? '') : sh?.name}</th>
          })}
        </tr>
      )}
    </thead>
  )
}

export default Headers
