import Sdk from '../../utils/WalletProvider/Sdk'
import { fetchNativeBalance } from '../../helpers/api'
import { AccountConfig } from '../../utils/ConfigProvider'
import { ethers } from 'ethers'

export type Validator = {
  address: string
  balance: string
}

class BridgeClass {
  private _sdk: Sdk
  private _bridgeAddr: string
  private _bridgeAbi: any[]
  private _validatorsAddr: Record<string, AccountConfig>

  constructor(sdk: Sdk, bridgeAddr: string, bridgeAbi: any[], validatorsAddr: Record<string, AccountConfig>) {
    this._sdk = sdk
    this._bridgeAddr = bridgeAddr
    this._bridgeAbi = bridgeAbi
    this._validatorsAddr = validatorsAddr
  }

  fetchValidatorsRampBalance(network: number, rampAddr: string): Promise<Record<string, Validator>> {
    const promises = []
    for (const name in this._validatorsAddr) {
      // console.log('asdasdasd: ', network, this._validatorsAddr[name].address)
      promises.push(fetchNativeBalance(network, this._validatorsAddr[name].address))
    }
    return Promise.all(promises).then((balances) => {
      const validators: Record<string, Validator> = {}
      for (const index in balances) {
        console.log('balance index: ', balances[index])
        validators['validator' + (parseInt(index) + 1)] = {
          address: this._validatorsAddr[`validator${parseInt(index) + 1}`].address,
          balance: balances[index]
        }
      }
      return validators
    })
  }

  outputBridgeInfo(): Record<string, string> {
    const infos: Record<string, string> = {
      bridgeAddress: this._bridgeAddr
    }
    return infos
  }

  setFieldValues(address: string, method: string) {
    console.log('contract action: ', address, method)
    return this._sdk.send(address, method, [], { abi: this._bridgeAbi })
  }

  setFeeManagerContract(value: any) {
    console.log('check value: ', value)
    return this._sdk.send(this._bridgeAddr, 'setFeeManagerContract', [value], {
      abi: this._bridgeAbi
    })
  }

  setGasPrice(value: any) {
    const parsedValue = ethers.utils.parseUnits(value, 'gwei')
    console.log('check value: ', parsedValue)

    return this._sdk.send(this._bridgeAddr, 'setGasPrice', [parsedValue], {
      abi: this._bridgeAbi
    })
  }

  setDailyLimit(value: any) {
    const removeCommaValue = parseFloat(value.toString().replace(/,/g, '')).toString()
    const parsedValue = ethers.utils.parseUnits(removeCommaValue, 'ether')
    console.log('check value: ', parsedValue)

    return this._sdk.send(this._bridgeAddr, 'setDailyLimit', [parsedValue], {
      abi: this._bridgeAbi
    })
  }

  setExecutionDailyLimit(value: any) {
    const removeCommaValue = parseFloat(value.toString().replace(/,/g, '')).toString()
    const parsedValue = ethers.utils.parseUnits(removeCommaValue, 'ether')
    console.log('check value: ', parsedValue)

    return this._sdk.send(this._bridgeAddr, 'setExecutionDailyLimit', [parsedValue], {
      abi: this._bridgeAbi
    })
  }

  setExecutionMaxPerTx(value: any) {
    const removeCommaValue = parseFloat(value.toString().replace(/,/g, '')).toString()
    const parsedValue = ethers.utils.parseUnits(removeCommaValue, 'ether')
    console.log('check value: ', parsedValue)

    return this._sdk.send(this._bridgeAddr, 'setExecutionMaxPerTx', [parsedValue], {
      abi: this._bridgeAbi
    })
  }

  setRequiredBlockConfirmations(value: any) {
    console.log('check value: ', value)

    return this._sdk.send(this._bridgeAddr, 'setRequiredBlockConfirmations', [value], {
      abi: this._bridgeAbi
    })
  }

  setMaxPerTx(value: any) {
    const removeCommaValue = parseFloat(value.toString().replace(/,/g, '')).toString()
    const parsedValue = ethers.utils.parseUnits(removeCommaValue, 'ether')
    console.log('check value: ', parsedValue)

    return this._sdk.send(this._bridgeAddr, 'setMaxPerTx', [parsedValue], {
      abi: this._bridgeAbi
    })
  }

  setMinPerTx(value: any) {
    const removeCommaValue = parseFloat(value.toString().replace(/,/g, '')).toString()
    const parsedValue = ethers.utils.parseUnits(removeCommaValue, 'ether')
    console.log('check value: ', parsedValue)

    return this._sdk.send(this._bridgeAddr, 'setMinPerTx', [parsedValue], {
      abi: this._bridgeAbi
    })
  }

  setHomeFee(value: any) {
    const removeCommaValue = parseFloat(value.toString().replace(/,/g, '')).toString()
    const parsedValue = ethers.utils.parseUnits(removeCommaValue, 'ether')
    console.log('check address: ', this._bridgeAddr)
    console.log('check value: ', parsedValue)

    return this._sdk.send(this._bridgeAddr, 'setHomeFee', [parsedValue], {
      abi: this._bridgeAbi
    })
  }

  setForeignFee(value: any) {
    const parsedValue = ethers.utils.parseUnits(value, 'ether')
    console.log('check value: ', parsedValue)

    return this._sdk.send(this._bridgeAddr, 'setForeignFee', [parsedValue], {
      abi: this._bridgeAbi
    })
  }
}

export default BridgeClass
