import useFetchTokens from '../../hooks/useFetchTokens'
import { useCallback, useEffect } from 'react'
import Table from '../../components/Table'
import { BasicHeader } from '../../components/Table/types'
import Token from '../../models/token'
import { ethers } from 'ethers'

const accounts = [
  {
    name: 'Old Deployer',
    address: '0xB12779D4D00dB5b851834b057DF2ff6B8E87779A',
    minBalance: {
      BNB: 1
    }
  },
  {
    name: 'Old Operator',
    address: '0x5baE2B131919f91b272d2Fa3F192397Ab1969679',
    minBalance: {
      ETH: 0.2
    }
  },
  {
    name: 'Gnosis_Signatory',
    address: '0x36f1BB392841827D3808a3B209031A12235c8BCE',
    description: 'Multisig',
    minBalance: {
      BNB: 0.25
    }
  },
  {
    name: 'Interest Receiver',
    address: '0x98D7eF12c1b301a89F2A66Ba18cDe9073a3C7074'
  },
  {
    name: 'Home_Owner',
    address: '0x180DA6C4625e249edB0D432F1f819fdd8083AFae',
    description: 'Bridge'
  },
  {
    name: 'Old Deployer 2',
    address: '0x9aF49b6B4c4Ea23d254d224Dd7a360494Df54F44'
  },
  {
    name: 'Validator 1',
    address: '0x9492F913E5886B2Fe9558604b6A7747d1d4A16Bc',
    minBalance: {
      ETH: 3,
      BNB: 5,
      MATIC: 5
    },
    description: 'Bridge'
  },
  {
    name: 'Validator 2',
    address: '0x0D42355dC04Dd0Afdc7c5C96B467493936596968',
    minBalance: {
      ETH: 3,
      BNB: 5,
      MATIC: 5
    },
    description: 'Bridge'
  },
  {
    name: 'Validator 3',
    address: '0x68aa38EA795ddF59E02Fe883662C5f7eFac1bc4D',
    minBalance: {
      BNB: 5,
      MATIC: 5
    },
    description: 'Bridge'
  },
  {
    name: 'Validator 4',
    address: '0x4304F8f24610ae0e7978dd25CA1C9351A5e639d0',
    minBalance: {
      BNB: 5,
      MATIC: 5
    },
    description: 'Bridge'
  },
  {
    name: 'Validator 5',
    address: '0x1d500DD6C841d6660cCa18D5B3b88F0852485CFe',
    minBalance: {
      ETH: 3,
      BNB: 5
    },
    description: 'Bridge'
  },
  {
    name: 'Validator 6',
    address: '0x753b352566ADC5cd850DaA16FCc0F17EC92e37CA',
    minBalance: {
      ETH: 3,
      BNB: 5
    },
    description: 'Bridge'
  },
  {
    name: 'Validator 7',
    address: '0x753b352566ADC5cd850DaA16FCc0F17EC92e37CA',
    minBalance: {
      BNB: 5,
      AVAX: 5
    },
    description: 'Bridge'
  },
  {
    name: 'Validator 8',
    address: '0xEaD36Bb66b9E778C0C083C8CABbAF9EC6179cc7F',
    minBalance: {
      BNB: 5,
      AVAX: 5
    },
    description: 'Bridge'
  },
  {
    name: 'Validator 9',
    address: '0x0b1837a12f40Bf4Dcb08ed385fDb3570cd2e1fbd',
    minBalance: {
      ETH: 3,
      AVAX: 5
    },
    description: 'Bridge'
  },
  {
    name: 'Validator 10',
    address: '0xdfC1B7E16A5FCEaF1c9c8d355B5d37faB02e3a9D',
    minBalance: {
      ETH: 3,
      AVAX: 5
    },
    description: 'Bridge'
  },
  {
    name: 'Validator 11',
    address: '0x2D7c7C506EFd6180cDaB02fdF5caE41B4b66dbBb',
    minBalance: {
      MATIC: 3,
      AVAX: 3
    },
    description: 'Bridge'
  },
  {
    name: 'Validator 12',
    address: '0x06b86fD0B83b3F5E09967C83C7413b280369C6a1',
    minBalance: {
      MATIC: 3,
      AVAX: 3
    },
    description: 'Bridge'
  },
  {
    name: 'Validator 13',
    address: '0x197Dd736B31ee3f536a316c04A1b1d7C492220a4',
    minBalance: {
      ETH: 3,
      MATIC: 3
    },
    description: 'Bridge'
  },
  {
    name: 'Validator 14',
    address: '0x6D575Cb84266EB98293306852889eCB9d7485733',
    minBalance: {
      ETH: 3,
      MATIC: 3
    },
    description: 'Bridge'
  },
  {
    name: 'KMS Operator',
    address: '0x037c56d5cb99ef3a56c34c30c27f61d260d5ee1a',
    description: 'Liquidator',
    minBalance: {
      ETH: 2,
      BNB: 5,
      MATIC: 5,
      AVAX: 2
    }
  },
  {
    name: 'KMS Deployer',
    address: '0xC76BB6d6f1802701E758A326558CBf224b683c06',
    minBalance: {
      BNB: 1
    },
    description: 'Contract deployments'
  },
  {
    name: 'Development',
    address: '0x091e6ac0600114de461647165e8424ff63c128f8'
  },
  {
    name: 'Marketmaker',
    address: '0x9b506812797b0201720e56843b6df19055386448',
    minBalance: {
      BUSD: 20_000,
      USDC: 2_000
    }
  },
  {
    name: 'Caspar: #1 Operator',
    address: '0x00fe6e487B826d51576Eac50d51f0E4f83eC970B'
  },
  {
    name: 'Caspar: #2 FeeReceiver + PolygonFarmingWallet',
    address: '0x25c475C6ABc07a4C49c2a0441620Dff26e29E8b9'
  },
  {
    name: 'Farming',
    address: '0x29c324e806047075b179dc0d21ae27612EfC2555',
    minBalance: {
      'RAMP (ETH)': 200000
    },
    description: 'Master Farming Wallet'
  },
  {
    name: 'Farming Hotwallet 1',
    address: '0x21038F075e2d0EE78c9fd55A03e5d4aDcb634763',
    minBalance: {
      'RAMP (ETH)': 200000
    },
    description: 'Ramp Staking Strategy'
  },
  {
    name: 'Farming Hotwallet 2',
    address: '0xC6704b07C496550674146D6c779CbF06b1D36EC8',
    minBalance: {
      'RAMP (ETH)': 200000
    },
    description: 'RAMP-ETH UNI'
  },
  {
    name: 'PrivateSaleVesting',
    address: '0xd3c8f4362706A21B613d45904D9E9F200B4E7b85'
  },
  {
    name: 'FarmingHotwallet A',
    address: '0xA42d846a51cCab1dD3a6ce469A313F85f7178b75',
    minBalance: {
      'RAMP (POLY)': 200000
    },
    description: 'Bonus Pool'
  },
  {
    name: 'FarmingHotwallet B',
    address: '0x3716C0Caf03046C885893110f7f822DC55c4601A',
    minBalance: {
      'RAMP (BSC)': 400000
    },
    description: 'Bonus Pool'
  }
]

const ethAddresses = [
  {
    address: '0x33D0568941C0C64ff7e0FB4fbA0B11BD37deEd9f',
    name: 'RAMP (ETH)'
  }
]

const bscAddresses = [
  {
    address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    name: 'BUSD'
  },
  {
    address: '0x8519ea49c997f50ceffa444d240fb655e89248aa',
    name: 'RAMP (BSC)'
  }
]

const polyAddresses = [
  {
    address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    name: 'USDC'
  },
  {
    address: '0xaECeBfcF604AD245Eaf0D5BD68459C3a7A6399c2',
    name: 'RAMP (POLY)'
  }
]

const addMinimumBalanceHeader = (tokens: Array<Token>) => {
  const withMinBalance: Array<BasicHeader> = []
  tokens.forEach(({ token }) => {
    withMinBalance.push({
      id: token.name,
      name: token.name
    })
    withMinBalance.push({
      id: `Minimum Balance - ${token.name}`,
      name: `Minimum Balance - ${token.name}`
    })
  })
  return withMinBalance
}

const AccountBalances: React.FC = () => {
  const {
    data: polygon,
    loading: polyLoading,
    queryData: queryPolygonData,
    queryNativeBalance: queryMatic
  } = useFetchTokens('polygon', polyAddresses)
  const { data: bsc, loading: bscLoading, queryData: queryBSCData, queryNativeBalance: queryBNB } = useFetchTokens(
    'bsc',
    bscAddresses
  )
  const { data: eth, loading: ethLoading, queryData: queryEthData, queryNativeBalance: queryEth } = useFetchTokens(
    'eth',
    ethAddresses
  )
  const { data: avax, loading: avaxLoading, queryNativeBalance: queryAvax } = useFetchTokens('avax', [])

  const queryData = useCallback(
    (accounts) => {
      queryMatic(accounts)
      queryPolygonData(accounts)
      queryBSCData(accounts)
      queryBNB(accounts)
      queryEthData(accounts)
      queryEth(accounts)
      queryAvax(accounts)
    },
    [queryPolygonData, queryBSCData, queryEthData, queryMatic, queryBNB, queryEth]
  )

  useEffect(() => {
    queryData(accounts)
  }, [queryData])

  const headers = [
    {
      id: 'name',
      name: 'name'
    },
    {
      id: 'address',
      name: 'address'
    },
    {
      id: 'description',
      name: 'description'
    },
    {
      id: 'eth',
      name: 'Ethereum',
      subHeaders: addMinimumBalanceHeader(eth.tokens)
    },
    {
      id: 'bsc',
      name: 'Binance Smart Chain',
      subHeaders: addMinimumBalanceHeader(bsc.tokens)
    },
    {
      id: 'polygon',
      name: 'Polygon',
      subHeaders: addMinimumBalanceHeader(polygon.tokens)
    },
    {
      id: 'avax',
      name: 'Avalanche',
      subHeaders: addMinimumBalanceHeader(avax.tokens)
    }
  ]
  const rows = accounts.map((a) => {
    const balances: Record<string, string> = {}
    const filter: Array<string | number> = []
    polygon.tokens.forEach((pt) => {
      balances[pt.token.name] = ethers.utils.commify(pt.balances[a.name] ?? '')
      for (const [name, amount] of Object.entries(a?.minBalance ?? {})) {
        if (pt.token.name === name) {
          if (typeof amount === 'number') {
            balances[`Minimum Balance - ${name}`] = amount.toLocaleString()
            if (amount > parseFloat(pt.balances[a.name])) {
              filter.push(name)
            }
          }
        }
      }
    })
    bsc.tokens.forEach((bt) => {
      balances[bt.token.name] = ethers.utils.commify(bt.balances[a.name] ?? '')
      for (const [name, amount] of Object.entries(a?.minBalance ?? {})) {
        if (bt.token.name === name) {
          if (typeof amount === 'number') {
            balances[`Minimum Balance - ${name}`] = amount.toLocaleString()
            if (amount > parseFloat(bt.balances[a.name])) {
              filter.push(name)
            }
          }
        }
      }
    })
    eth.tokens.forEach((et) => {
      balances[et.token.name] = ethers.utils.commify(et.balances[a.name] ?? '')
      for (const [name, amount] of Object.entries(a?.minBalance ?? {})) {
        if (et.token.name === name) {
          if (typeof amount === 'number') {
            balances[`Minimum Balance - ${name}`] = amount.toLocaleString()
            if (amount > parseFloat(et.balances[a.name])) {
              filter.push(name)
            }
          }
        }
      }
    })
    avax.tokens.forEach((et) => {
      balances[et.token.name] = ethers.utils.commify(et.balances[a.name] ?? '')
      for (const [name, amount] of Object.entries(a?.minBalance ?? {})) {
        if (et.token.name === name) {
          if (typeof amount === 'number') {
            balances[`Minimum Balance - ${name}`] = amount.toLocaleString()
            if (amount > parseFloat(et.balances[a.name])) {
              filter.push(name)
            }
          }
        }
      }
    })
    return {
      id: a.address,
      address: a.address,
      name: a.name,
      label: a.name,
      description: a?.description ?? '',
      filter,
      ...balances
    }
  })
  console.log(rows)

  const handleRefresh = () => queryData(accounts)

  const isLoading =
    eth.tokens.length !== ethAddresses.length + 1 ||
    bsc.tokens.length !== bscAddresses.length + 1 ||
    polygon.tokens.length !== polyAddresses.length + 1 ||
    avax.tokens.length !== 1
  return (
    <>
      {isLoading && <p>Loading...</p>}
      <Table
        headers={headers}
        rows={rows}
        rowFilterLabel={'Accounts'}
        refresh={isLoading ? undefined : { onRefresh: handleRefresh }}
      />
    </>
  )
}

export default AccountBalances
