import Erc20Abi from '../abi/ERC20.json'
import AppSettingsAbi from '../abi/AppSettings.json'
import VaultAbi from '../abi/Vault.json'
import BankAbi from '../abi/Bank.json'
import ControllerAbi from '../abi/Controller.json'
import RampStakingStrategyAbi from '../abi/RampStakingStrategy.json'
import MintBurnHomeBridge from '../abi/MintBurnHomeBridgeErcToErc.json'
import MintBurnForeignBridge from '../abi/MintBurnForeignBridgeErc677ToErc677.json'
import StaticErcStrategyAbi from '../abi/StaticErcStrategy.json'
import AlpacaAccruingStrategy from '../abi/AlpacaAccruingStrategy.abi.json'
import CakeAccruingStrategyAbi from '../abi/CakeAccruingStrategy.json'
import BonusPoolAbi from '../abi/BonusPool.json'
import SingleAssetAccruingStrategy from '../abi/SingleAssetAccruingStrategy.json'
import NoDeploymentStrategy from '../abi/NoDeploymentStrategy.json'

export default {
  1: {
    accounts: {
      validator1: {
        address: '0x9D042F0BA502597775f959f82f39Df42215ebfC6',
        type: 'validator'
      },
      validator2: {
        address: '0x21E9113D7946f3E18957579122d94Ab302E19e69',
        type: 'validator'
      },
      treasury: {
        address: '',
        type: 'operator'
      }
    },
    bridge: {
      bscEth: {
        address: '0xb1BB52029e91454941706E9020aE6f0525664D34',
        abi: MintBurnForeignBridge
      }
    },
    contracts: {
      AppSettings: {
        address: '0xBf43C3C3182a56FDf5fEe52a6f4cD1364229C8a4',
        abi: AppSettingsAbi
      },
      Controller: {
        address: '0x3E84B21E2BF7C048992fD5dE5Ecd2a6Ad1351C02',
        abi: ControllerAbi
      },
      Bank: {
        address: '0x0390db7d64D38ceb5976F069410B24Cf315C53e4',
        abi: BankAbi
      },
      Vault: {
        address: '0xd87C52809a972FdAe2dBAB69D438510f17f7409A',
        abi: VaultAbi
      },
      RUSD: {
        address: '0xcf178a685471927e977a0ea8ee555c11b2aa6b7b',
        abi: Erc20Abi
      }
    },
    tokens: {}
  },
  56: {
    accounts: {
      validator1: {
        address: '0x9492F913E5886B2Fe9558604b6A7747d1d4A16Bc',
        type: 'validator'
      },
      validator2: {
        address: '0x0D42355dC04Dd0Afdc7c5C96B467493936596968',
        type: 'validator'
      },
      validator3: {
        address: '0x68aa38EA795ddF59E02Fe883662C5f7eFac1bc4D',
        type: 'validator'
      },
      treasury: {
        address: '',
        type: 'operator'
      }
    },
    bridge: {
      bscEth: {
        address: '0x2099D05aa0210540cCAD180749DE26C52fD02B37',
        abi: MintBurnHomeBridge
      },
      polyBsc: {
        address: '0x14Cb3c3F7bB3b628cb9eBAe3AA963C55593B4053',
        // address: '0x764FD91e0A860fa4518709b717BC84DbCf7837F6',
        abi: MintBurnForeignBridge
      }
    },
    contracts: {
      AppSettings: {
        address: '0xBf43C3C3182a56FDf5fEe52a6f4cD1364229C8a4',
        abi: AppSettingsAbi
      },
      Controller: {
        address: '0x15Bd782509f2233A35c383496e88fAC4B84C87e8',
        abi: ControllerAbi
      },
      Bank: {
        address: '0x38cBC1295D0D33BCCfF084e728d3BCdEf67D53E9',
        abi: BankAbi
      },
      BonusPool: {
        address: '0x1995cDc093c422d4Ed67cf71fA4e02F18B0f8dBa',
        abi: BonusPoolAbi
      },
      Vault: {
        address: '0xbacca65b8887313189c4E1139810E917854F6a01',
        abi: VaultAbi
      },
      RUSD: {
        address: '0x04a4247E7a3394BAE06D3772E8432f62656FD452',
        abi: Erc20Abi
      }
    },
    tokens: {
      Ramp: {
        address: '0x8519ea49c997f50ceffa444d240fb655e89248aa',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_1',
          address: '0x0759dafDEE2347c4eFB53cfc81e64717b5BB4278',
          abi: RampStakingStrategyAbi
        }
      },
      LpRusdBusdPancake: {
        address: '0x59fac9e98479fc9979ae2a0c7422af50bcbb9b26',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_3',
          address: '0xFA030475314Ac65927C9610f7caACdd88a53cD69',
          abi: StaticErcStrategyAbi
        }
      },
      Cake: {
        address: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_7',
          address: '0x0b2C52488e557FA60cACE6155fC62B98508579dB',
          abi: CakeAccruingStrategyAbi
        }
      },
      BscUsdAlpaca: {
        address: '0x55d398326f99059ff775485246999027b3197955',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_15',
          address: '0xdc68aea804353cFF33474A5911D2bC3A3b5949b8',
          abi: AlpacaAccruingStrategy
        }
      },
      Link: {
        address: '0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
        abi: Erc20Abi,
        strategy: {
          name: 'Bsc_30',
          address: '0x6708DF939538aC1C943Fce7eC4DD6FBcD3821cD1',
          abi: SingleAssetAccruingStrategy
        }
      }
    }
  },
  137: {
    accounts: {
      validator1: {
        address: '0x9492F913E5886B2Fe9558604b6A7747d1d4A16Bc',
        type: 'validator'
      },
      validator2: {
        address: '0x0D42355dC04Dd0Afdc7c5C96B467493936596968',
        type: 'validator'
      },
      validator3: {
        address: '0x68aa38EA795ddF59E02Fe883662C5f7eFac1bc4D',
        type: 'validator'
      },
      treasury: {
        address: '',
        type: 'operator'
      }
    },
    bridge: {
      polyBsc: {
        // Jun 29 8AM Demoing
        address: '0x822b2319b9e7cB3D1bf81cBE9e8d41126c27209d',
        // address: '0x4C9ce26B057F6C51e5Ed7a619f161233A4D43453',
        abi: MintBurnHomeBridge
      }
    },
    contracts: {
      AppSettings: {
        address: '0xDcd4468Cb9745de6c4Ec3075C4FD067a99fdac9E',
        abi: AppSettingsAbi
      },
      Controller: {
        address: '0x00cB0B91095d4bE126C98b8A57870b4E573b6aad',
        abi: ControllerAbi
      },
      Bank: {
        address: '0xE5d57545A2F09e1aF52e0Ef156FDD81a688e8857',
        abi: BankAbi
      },
      Vault: {
        address: '0xA10793C8F2300b84832EDaC241cABf66C3203f2d',
        abi: VaultAbi
      },
      RUSD: {
        address: '0xaCA6b3fc6E4959Fe7936421AAA29417c1B1Ca403',
        abi: Erc20Abi
      }
    },
    tokens: {
      Ramp: {
        address: '0xaECeBfcF604AD245Eaf0D5BD68459C3a7A6399c2',
        abi: Erc20Abi,
        strategy: {
          name: 'Polygon_1',
          address: '0x3449Aa59bEae803B200513B37Ac68Ca38c4fd950',
          abi: RampStakingStrategyAbi
        }
      }
    }
  },
  43114: {
    contracts: {
      Controller: {
        address: '0xA4a97A51E0FA6cC60D76aA91f3585265964452D1',
        abi: ControllerAbi
      },
      Bank: {
        address: '0xFcf38144be49e1f55367c17D542a464c42D73896',
        abi: BankAbi
      },
      Vault: {
        address: '0xE772b19f2F88B49B9faA999646ed9Dce9608699a',
        abi: VaultAbi
      },
      RUSD: {
        address: '0x3449Aa59bEae803B200513B37Ac68Ca38c4fd950',
        abi: Erc20Abi
      },
      Ramp: {
        address: '0xb5Ac2c8dFB87DC679F076BF4427aC518F9D8A7cd',
        abi: Erc20Abi
      }
    },
    tokens: {
      WAvax: {
        address: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
        abi: Erc20Abi,
        strategy: {
          name: 'Avax_1',
          address: '0x1E9399FB93Ca36ebDbB6E2921b3D707a3e56b5C1',
          abi: SingleAssetAccruingStrategy
        }
      }
    }
  },
  1284: {
    contracts: {
      AppSettings: {
        address: '0xaCA6b3fc6E4959Fe7936421AAA29417c1B1Ca403',
        abi: AppSettingsAbi
      },
      Controller: {
        address: '0x00cB0B91095d4bE126C98b8A57870b4E573b6aad',
        abi: ControllerAbi
      },
      Bank: {
        address: '0xE5d57545A2F09e1aF52e0Ef156FDD81a688e8857',
        abi: BankAbi
      },
      Vault: {
        address: '0xA10793C8F2300b84832EDaC241cABf66C3203f2d',
        abi: VaultAbi
      },
      RUSD: {
        address: '0xAAFC37f3A34939cb0Ccd2d44334C11224CE7A5AC',
        abi: Erc20Abi
      }
    },
    tokens: {
      wGLMR: {
        address: '0xAcc15dC74880C9944775448304B263D191c6077F',
        abi: Erc20Abi,
        strategy: {
          name: 'Moonbeam_1',
          address: '0x6Cc580e2e581c3270179669d0594B62cc5974740',
          abi: NoDeploymentStrategy
        }
      }
    }
  }
}
