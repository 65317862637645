export type Row<
  T = {
    [key: string]: any
    id: number | string
    label?: string
    filter?: Array<string | number>
  }
> = T

export class LinkedValue {
  link: string
  value: string | number

  constructor(link: string, value: string | number) {
    this.link = link
    this.value = value
  }

  getLinkElement() {
    return <a href={this.link}>{this.value}</a>
  }
}

export interface BasicHeader {
  id: number | string
  name: string
  transformData?: (data: any) => string | number | LinkedValue
  editData?: (data: any) => void
}

export interface Header extends BasicHeader {
  subHeaders?: Array<BasicHeader>
}
