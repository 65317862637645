import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { EventLog } from '../../helpers/scanEvents/ScanSdk'
import style from './EventStyle'
import ScanLink from '../../components/ScanLink'

type Props = {
  events: EventLog[]
}

const Events: React.FC<Props> = ({ events }) => {
  return (
    <Col>
      {events.length > 0 ? (
        events.map((event, event_index) => {
          return (
            <Row style={style.card} key={event_index}>
              <Row>
                <Col>
                  <b>Block No.: </b>
                  {event.blockNumber}
                </Col>
                <Col>
                  <h6>{event.eventName}</h6>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <b>User Address:</b> <ScanLink search={event.account} type={'address'} />
                </Col>
                <Col>
                  {Object.entries(event.data).map(([name, value], index) => {
                    return (
                      <Row key={index}>
                        <Col md={3}>
                          <b>{name}</b>
                        </Col>
                        <Col>{value + ''}</Col>
                      </Row>
                    )
                  })}
                </Col>
              </Row>
            </Row>
          )
        })
      ) : (
        <div className="no-events-window">
          <span>No Events Found</span>
        </div>
      )}
    </Col>
  )
}

export default Events
