import { Row } from '../../types'
import { ChangeEvent, useState } from 'react'
import { Dropdown, Form } from 'react-bootstrap'
import { useHiddenRowsActions, useHiddenRows } from '../../index'
import classes from './index.module.css'

interface Props {
  rows: Array<Row>
  label?: string
}

const RowFilters: React.FC<Props> = ({ rows, label = 'Rows' }) => {
  const hiddenRows = useHiddenRows()
  const { hide, show } = useHiddenRowsActions()

  const handleChange = (value: ChangeEvent<HTMLInputElement>) => {
    if (value.target.checked) show(value.target.id)
    else hide(value.target.id)
  }

  return (
    <Dropdown autoClose={'outside'} className={'d-inline'}>
      <Dropdown.Toggle variant={'success'}>{label}</Dropdown.Toggle>
      <Dropdown.Menu className={classes.rowFilter}>
        {rows.map((r) => {
          const sId = typeof r.id === 'number' ? r.id.toString() : r.id
          return (
            <Form.Check
              type={'checkbox'}
              label={r?.label ? r?.label : r.id}
              name={sId}
              id={sId}
              checked={!hiddenRows.includes(r.id)}
              onChange={handleChange}
              key={r.id}
              className={classes.item}
            />
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default RowFilters
