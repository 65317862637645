import React from 'react'
import { TokenContractConfig } from '../../utils/ConfigProvider'
import { FieldAction, LoadingAction, RecordField, RecordFieldMethods } from '../../contractHooks/useFieldReducer'
import CakeLpStrategy from '../../contractHooks/CakeLpStrategy'
import StaticErcStrategy from '../../contractHooks/StaticErcStrategy'
import CakeAccruingStrategy from '../../contractHooks/CakeAccruingStrategy'
import RampStakingStrategy from '../../contractHooks/RampStakingStrategy'
import BaseStrategy from '../../contractHooks/BaseStrategy'
import NoDeploymentStrategy from '../../contractHooks/NoDeploymentStrategy'

export type RenderFields = (props: {
  fields: RecordField
  setFields: React.Dispatch<FieldAction | FieldAction[]>
  methods: RecordFieldMethods
  reset(): void
  setLoading: React.Dispatch<LoadingAction>
}) => any

type Props = {
  tokenConfig: TokenContractConfig
  renderFields: RenderFields
}

const strategiesSelection = {
  rampStaking: ['eth_1', 'bsc_1', 'polygon_1'],
  cakeLpStaking: [
    'bsc_2',
    'bsc_4',
    'bsc_6',
    'bsc_8',
    'bsc_9',
    'bsc_10',
    'bsc_11',
    'bsc_12',
    'bsc_18',
    'bsc_19',
    'bsc_20',
    'bsc_21',
    'bsc_22',
    'bsc_23',
    'bsc_24',
    'bsc_25',
    'bsc_26',
    'bsc_27',
    'bsc_28',
    'bsc_31',
    'bsc_32',
    'bsc_33',
    'bsc_34',
    'bsc_35',
    'bsc_36'
  ],
  staticStaking: ['eth_2', 'bsc_3', 'bsc_5', 'bsc_17', 'bsc_37', 'polygon_10'],
  cakeAccruingStaking: ['bsc_7'],
  baseStrategy: [
    'polygon_2',
    'polygon_3',
    'polygon_4',
    'polygon_5',
    'polygon_6',
    'polygon_7',
    'polygon_8',
    'polygon_9',
    'polygon_11',
    'polygon_12',
    'polygon_13',
    'polygon_14',
    'polygon_15',
    'polygon_16',
    'bsc_13',
    'bsc_14',
    'bsc_15',
    'bsc_16',
    'bsc_29',
    'bsc_30',
    'avax_1',
    'moonbeam_1'
  ],
  noDeploymentStrategy: ['moonbeam_1']
}

const Strategy: React.FC<Props> = ({ tokenConfig, renderFields }) => {
  return (
    <>
      {strategiesSelection.rampStaking.includes(tokenConfig.strategy.name.toLowerCase()) ? (
        <RampStakingStrategy tokenConfig={tokenConfig} data={renderFields} />
      ) : strategiesSelection.cakeLpStaking.includes(tokenConfig.strategy.name.toLowerCase()) ? (
        <CakeLpStrategy tokenConfig={tokenConfig} data={renderFields} />
      ) : strategiesSelection.staticStaking.includes(tokenConfig.strategy.name.toLowerCase()) ? (
        <StaticErcStrategy tokenConfig={tokenConfig} data={renderFields} />
      ) : strategiesSelection.cakeAccruingStaking.includes(tokenConfig.strategy.name.toLowerCase()) ? (
        <CakeAccruingStrategy tokenConfig={tokenConfig} data={renderFields} />
      ) : strategiesSelection.noDeploymentStrategy.includes(tokenConfig.strategy.name.toLowerCase()) ? (
        <NoDeploymentStrategy tokenConfig={tokenConfig} data={renderFields} />
      ) : strategiesSelection.baseStrategy.includes(tokenConfig.strategy.name.toLowerCase()) ? (
        <BaseStrategy tokenConfig={tokenConfig} data={renderFields} />
      ) : (
        <></>
      )}
    </>
  )
}

export default Strategy
