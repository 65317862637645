import React from 'react'
import Field from '../../../components/Field'
import { RecordField } from '../../../contractHooks/useFieldReducer'
import { useWallet } from '../../../utils/WalletProvider/WalletContext'
import { useConfig } from '../../../utils/ConfigProvider/ConfigContext'
import { formatUnits } from 'ethers/lib/utils'
import { useMessages } from '../../../utils/MessageProvider/MessagesContext'
import { Contract, ethers } from 'ethers'

type LiquidationSettingsProps = {
  liquidationSettingsConfig: RecordField
}

const LiquidationSettings: React.FC<LiquidationSettingsProps> = ({ liquidationSettingsConfig }) => {
  const { sdk } = useWallet()
  const { config } = useConfig()
  const { addMessage } = useMessages()
  const [isLoading, setLoading] = React.useState<boolean>(false)

  return (
    <div className="liquidation-settings-container">
      <div className="liquidation-settings-container__items">
        {liquidationSettingsConfig &&
          Object.entries(liquidationSettingsConfig).map(([label, field], settings_index) => {
            return (
              <Field
                key={settings_index}
                id={`liquidation-settings-${settings_index}`}
                label={label}
                optionalLabel={field.optionalLabel}
                value={field.value ? field.value : '0'}
                isLoading={isLoading}
                alternativeButtonText={'Burn'}
                onApply={
                  field.method
                    ? (value) => {
                        setLoading(true)
                        const parsedValue = ethers.utils.parseUnits(
                          value as any,
                          liquidationSettingsConfig.burnLiquidatedRUsd.unit
                        )
                        console.log('parsed value: ', parsedValue)
                        return sdk
                          .send(config.contracts.Bank.address, field.method as string, [parsedValue], {
                            abi: config.contracts.Bank.abi
                          })
                          .then((res: any) => {
                            addMessage({
                              type: 'success',
                              title: 'Burn liquidation success',
                              message: `Function successful: ${res}`
                            })
                          })
                          .catch((err: any) => {
                            addMessage({
                              type: 'error',
                              title: 'Sending Error:',
                              message: err.data?.message || err.message
                            })
                          })
                          .finally(() => {
                            setLoading(false)
                          })
                      }
                    : undefined
                }
                infoText={field.infoText}
              />
            )
          })}
      </div>
    </div>
  )
}

export default LiquidationSettings
