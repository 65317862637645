import React from 'react'
import { Col, ListGroup, Row } from 'react-bootstrap'
import Strategy from './Strategy'
import { useConfig } from '../../utils/ConfigProvider/ConfigContext'
import { useQuery } from '../../utils/url'
import StrategyFields from './StrategyFields'
import { useWallet } from '../../utils/WalletProvider/WalletContext'
import StrategyListItem from './StrategyListItem'
import StrategyList from './StrategyList'

const StrategyContainer: React.FC = () => {
  const { config } = useConfig()
  const { wallet } = useWallet()
  const { query, setQuery } = useQuery()
  const activeToken = React.useMemo(() => {
    const tokenId: number = Number.parseInt(query.tokenId /*|| '0'*/)
    const conf = config.tokens[Object.keys(config.tokens)[tokenId]]
    return conf
  }, [config.tokens, query.tokenId])

  return (
    <Row>
      <Col xs={3} className="strategy-list-container">
        <StrategyList network={wallet.network} tokens={config.tokens} activeToken={query.tokenId} />
      </Col>
      <Col>
        {activeToken ? (
          <Strategy
            tokenConfig={activeToken}
            renderFields={({ fields, setFields, methods, reset }) => {
              return <StrategyFields fields={fields} setFields={setFields} methods={methods} reset={reset} />
            }}
          />
        ) : (
          <Col className="no-selected-strategy-window">
            <span>Choose a strategy</span>
          </Col>
        )}
      </Col>
    </Row>
  )
}

export default StrategyContainer
