import React from 'react'
import { Button, Col, FormLabel, InputGroup, Row } from 'react-bootstrap'
import { useQuery } from '../../utils/url'
import useScan from '../../helpers/scanEvents/useScan'

type Props = {
  tokenAddr: string
}

const SearchContainer: React.FC<Props> = ({ tokenAddr }) => {
  const { daysBefore, setDaysBefore } = useScan()
  const { query, setQuery } = useQuery()
  const [userAddr, setUserAddr] = React.useState(query.userAddr || '')
  const [onMount, setOnMount] = React.useState(true)

  const handleSearchClick = React.useCallback(() => {
    setQuery('userAddr', userAddr)
    setQuery('tokenAddr', tokenAddr)
    setQuery('daysBefore', daysBefore.toString())
  }, [userAddr, tokenAddr, daysBefore])

  React.useEffect(() => {
    if (onMount) {
      handleSearchClick()
      setOnMount(false)
    }
  }, [onMount])

  return (
    <Row>
      <Col className="search-container">
        <div className="search-container-block-items">
          <InputGroup>
            <FormLabel>User Address: </FormLabel>
            <input
              className="custom-input"
              type="text"
              value={userAddr}
              onChange={(event) => {
                setUserAddr(event.target.value)
              }}
            />
          </InputGroup>
          <InputGroup>
            <FormLabel>Query days before: </FormLabel>
            <input
              className="custom-input"
              type="text"
              value={daysBefore.toString()}
              onChange={(event) => setDaysBefore(Number(event.target.value))}
            />
          </InputGroup>
        </div>
        <div className="mt-2">
          <Button
            className="d"
            onClick={() => {
              console.log('clicked')
              handleSearchClick()
            }}
          >
            Search
          </Button>
        </div>
      </Col>
    </Row>
  )
}

export default SearchContainer
