import Metamask from './Metamask'
import { IWallet, Wallet } from './IWallet'
import Gnosis from './Gnosis'

export type WalletNames = 'metamask' | 'gnosis'

type Subscriptions = {
  wallet?(wallet: Wallet, iWallet: IWallet): void
}

type Options = {
  subscriptions?: Subscriptions
}

class WalletWrapper {
  private _subs?: Subscriptions
  network?: number

  constructor(opts?: Options) {
    this._subs = opts?.subscriptions
  }

  async connectedWallet(): Promise<void> {
    const walletList = [new Gnosis(), new Metamask()]
    let currWallet: IWallet | undefined
    let currWalletConnection: Wallet | undefined

    for (const wallet of walletList) {
      currWallet = wallet
      currWalletConnection = await wallet.connected()
      if (currWalletConnection) break
    }

    if (currWalletConnection && currWallet) this._subs?.wallet?.(currWalletConnection, currWallet)

    // if (wallet) {
    //   switch (wallet.name) {
    //     case 'Metamask':
    //       // this._subs?.iWallet?.( new Metamask())
    //       break;
    //     case 'Gnosis Safe':
    //       // this._subs?.iWallet?.( new Gnosis())
    //       break;
    //   }
    // }
  }

  async connect(name: WalletNames) {
    let promise
    switch (name) {
      case 'metamask':
        promise = new Metamask().connect()
        break
    }

    await promise
  }
}

export default WalletWrapper
