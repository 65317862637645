export default {
  1: {
    url: 'https://api-cn.etherscan.com/api',
    key: process.env.REACT_APP_ETH_API_KEY
  },
  56: {
    url: 'https://api.bscscan.com/api',
    key: process.env.REACT_APP_BSC_API_KEY
  },
  137: {
    url: 'https://api.polygonscan.com/api',
    key: process.env.REACT_APP_POLYGON_API_KEY
  },
  31337: {
    url: 'https://api.bscscan.com/api',
    key: process.env.REACT_APP_BSC_API_KEY
  },
  43114: {
    url: 'https://api.snowtrace.io/api',
    key: process.env.REACT_APP_AVAX_API_KEY
  }
}
