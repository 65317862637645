import Row from './Row'
import Headers from './Headers'
import { Row as RowType, Header } from './types'
import classes from './index.module.css'
import React, { createContext, useContext, useState } from 'react'
import RowFilters from './Filters/Row'
import ColumnFilters from './Filters/Column'
import { Button } from 'react-bootstrap'

interface Props {
  headers: Array<Header>
  rows: Array<RowType>
  rowFilterLabel?: string
  refresh?: {
    label?: string
    onRefresh: () => void
  }
}

const HiddenHeaders = createContext<Array<number | string>>([])
const HiddenHeadersActions = createContext({
  hide: (headerId: string | number) => {
    console.log(headerId)
  },
  show: (headerId: string | number) => {
    console.log(headerId)
  }
})

export const useHiddenHeadersActions = () => useContext(HiddenHeadersActions)
export const useHiddenHeaders = () => useContext(HiddenHeaders)

const HiddenRows = createContext<Array<number | string>>([])
const HiddenRowsActions = createContext({
  hide: (rowId: string | number) => {
    console.log(rowId)
  },
  show: (rowId: string | number) => {
    console.log(rowId)
  }
})

export const useHiddenRowsActions = () => useContext(HiddenRowsActions)
export const useHiddenRows = () => useContext(HiddenRows)

const Table: React.FC<Props> = ({ headers, rows, rowFilterLabel, refresh }) => {
  const [hiddenHeaders, setHiddenHeaders] = useState<Array<number | string>>([])
  const [hiddenRows, setHiddenRows] = useState<Array<number | string>>([])

  const hideHeader = (headerId: string | number) =>
    setHiddenHeaders((prevState) => {
      const mainHeaderIndex = headers.findIndex((h) => headerId === h.id)
      const isMainHeader = mainHeaderIndex !== -1

      if (isMainHeader) {
        const subHeadersToHide = headers[mainHeaderIndex]?.subHeaders?.map((hsh) => hsh.id) ?? []
        return [...prevState, headerId, ...subHeadersToHide]
      }

      // finding the main header for the subHeader
      const mainHeader = headers.find(
        (h) => h.subHeaders?.findIndex((hsh) => hsh.id === headerId) !== -1 && h.subHeaders
      )
      const hiddenSubHeaders = mainHeader?.subHeaders?.filter((msh) => prevState.includes(msh.id)) ?? []

      // return mainHeaderId as well if no subHeaders are present
      if (hiddenSubHeaders.length + 1 === mainHeader?.subHeaders?.length) return [...prevState, headerId, mainHeader.id]

      return [...prevState, headerId]
    })
  const showHeader = (headerId: string | number) =>
    setHiddenHeaders((prevState) => {
      const mainHeaderIndex = headers.findIndex((h) => headerId === h.id)
      const isMainHeader = mainHeaderIndex !== -1

      if (isMainHeader) {
        const subHeadersToShow = headers[mainHeaderIndex]?.subHeaders?.map((hsh) => hsh.id) ?? []
        return prevState.filter((ps) => ps !== headerId && (!subHeadersToShow.length || !subHeadersToShow.includes(ps)))
      } else {
        const mainHeader = headers.find(
          (h) => h.subHeaders?.findIndex((hsh) => hsh.id === headerId) !== -1 && h.subHeaders
        )
        return prevState.filter((ps) => ps !== headerId && ps !== mainHeader?.id)
      }
    })

  const hideRow = (rowId: string | number) => setHiddenRows((prevState) => [...prevState, rowId])
  const showRow = (rowId: string | number) => setHiddenRows((prevState) => prevState.filter((ps) => ps !== rowId))

  const fHeaders = headers.filter((h) => {
    return (
      !hiddenHeaders.includes(h.id) &&
      (!h?.subHeaders?.length ||
        (h?.subHeaders?.length && (h?.subHeaders?.filter((fhsh) => !hiddenHeaders.includes(fhsh.id))).length))
    )
  })
  const mHeaders = fHeaders.map((fh) => {
    return {
      ...fh,
      subHeaders: fh?.subHeaders?.filter((fhsh) => !hiddenHeaders.includes(fhsh.id))
    }
  })

  const renderRows = () => {
    const order: Array<string | number> = []
    mHeaders.forEach((h) => {
      if (h.subHeaders?.length) {
        h.subHeaders?.forEach((sh) => {
          order.push(sh.id)
        })
      } else {
        order.push(h.id)
      }
    })

    const fRows = rows.filter((r) => !hiddenRows.includes(r.id))

    return fRows.map((r) => {
      return <Row key={r.id} row={r} order={order} headers={mHeaders} />
    })
  }

  return (
    <HiddenHeaders.Provider value={hiddenHeaders}>
      <HiddenHeadersActions.Provider value={{ hide: hideHeader, show: showHeader }}>
        <HiddenRows.Provider value={hiddenRows}>
          <HiddenRowsActions.Provider value={{ hide: hideRow, show: showRow }}>
            <div>
              {refresh && (
                <Button className={'d-inline'} onClick={refresh.onRefresh}>
                  {refresh?.label ?? 'Refresh'}
                </Button>
              )}
              <RowFilters rows={rows} label={rowFilterLabel} />
              <ColumnFilters headers={headers} />
            </div>
            <div className={classes.table}>
              <table>
                <Headers headers={mHeaders} />
                <tbody>{renderRows()}</tbody>
              </table>
            </div>
          </HiddenRowsActions.Provider>
        </HiddenRows.Provider>
      </HiddenHeadersActions.Provider>
    </HiddenHeaders.Provider>
  )
}

export default Table
