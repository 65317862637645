import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import MessageRender from '../utils/MessageProvider/MessageRender'
type Props = {
  title?: string
  renderDrawer?: React.ReactElement
}

const MainLayout: React.FC<Props> = ({ title, renderDrawer, children }) => {
  const demolessTitle = title?.replace('Demo', '')
  return (
    <Container fluid className="vh-100">
      <MessageRender />
      <Row className="vh-100">
        {renderDrawer ? (
          <Col xs={5} md={4} lg={3} xl={2} className="drawerContainer">
            {renderDrawer}
          </Col>
        ) : undefined}
        <Col xs={7} md={8} lg={9} xl={10} className="ms-0 me-0 p-0 main-layout-column">
          {title ? (
            <div className="header-test">
              <div className="header-test-sub">
                {title.includes('Demo') ? (
                  <>
                    <h4>{demolessTitle}</h4>
                    <span className="demo-text-style">DEMO</span>
                  </>
                ) : (
                  <h4>{title}</h4>
                )}
              </div>
            </div>
          ) : undefined}
          <Row className="main-content">{children}</Row>
        </Col>
      </Row>
    </Container>
  )
}

export default MainLayout
