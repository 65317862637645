import React from 'react'
import { ethers } from 'ethers'
import { useWallet } from '../utils/WalletProvider/WalletContext'
import { useMessages } from '../utils/MessageProvider/MessagesContext'

const useContractRole = () => {
  const { sdk, wallet } = useWallet()
  const { addMessage } = useMessages()
  const roles = React.useMemo(() => {
    return {
      OPERATOR_ROLE: ethers.utils.id('OPERATOR_ROLE')
    }
  }, [])

  const hasRole = React.useCallback(
    (
      contractAddressOrName: string,
      abi: any[],
      role: keyof typeof roles,
      walletAddress?: string
    ): Promise<{ hasRole: boolean; walletAddress: string; role: string }> => {
      const _walletAddress = walletAddress || wallet.address
      const roleId = roles[role]

      return sdk
        .send(contractAddressOrName, 'hasRole', [roleId, _walletAddress], {
          abi
        })
        .then((hasRole) => {
          return {
            hasRole,
            walletAddress: _walletAddress,
            role
          }
        })
        .catch((err) => {
          addMessage({
            type: 'error',
            title: 'Error',
            message: err.data?.message || err.message
          })
          return {
            hasRole: false,
            walletAddress: _walletAddress,
            role
          }
        })
    },
    [wallet.address]
  )

  return { roles, hasRole }
}

export default useContractRole
