import React from 'react'
import { StrategyProps } from './types'
import { useWallet } from '../utils/WalletProvider/WalletContext'
import useFieldReducer, {
  parseToFieldAction,
  RecordField,
  RecordFieldMethods,
  resetFieldsValue
} from './useFieldReducer'
import { useMessages } from '../utils/MessageProvider/MessagesContext'
import BaseStrategy from './BaseStrategy'
import { ethers } from 'ethers'

const fieldConfigs: RecordField = {
  withdrawalFeePercentage: {
    value: '',
    unit: 'wei',
    method: 'setWithdrawalFeePercentage'
  }
}

const NoDeploymentStrategy: React.FC<StrategyProps> = ({ tokenConfig, data }) => {
  const { sdk } = useWallet()
  const { addMessage } = useMessages()
  const [_fields, setFields, cancelSetFields, setLoading] = useFieldReducer(fieldConfigs)

  const fetchWithdrawalFeePercentage = React.useCallback(() => {
    resetFieldsValue(fieldConfigs, setFields)
    sdk
      .send(tokenConfig.strategy.address, 'withdrawalFeePercentage', [], {
        abi: tokenConfig.strategy.abi
      })
      .then((withdrawalFeePercentage) => {
        setFields({
          prop: 'withdrawalFeePercentage',
          value: withdrawalFeePercentage
        })
      })
      .catch((err) => {
        addMessage({
          type: 'error',
          message: err.data?.message || err.message
        })
      })
  }, [sdk])

  React.useEffect(() => {
    fetchWithdrawalFeePercentage()
    return () => {
      cancelSetFields()
    }
  }, [fetchWithdrawalFeePercentage])

  const setWithdrawalFeePercentage = React.useCallback(
    (value: string) => {
      const parsedValue = ethers.utils.parseUnits(value, fieldConfigs.withdrawalFeePercentage.unit)
      return sdk.send(tokenConfig.strategy.address, 'setWithdrawalFeePercentage', [parsedValue], {
        abi: tokenConfig.strategy.abi
      })
    },
    [sdk, tokenConfig.strategy.address]
  )

  const noDeploymentStrategyMethods: RecordFieldMethods = React.useMemo(() => {
    return {
      setWithdrawalFeePercentage
    }
  }, [setWithdrawalFeePercentage])

  return (
    <>
      <BaseStrategy
        tokenConfig={tokenConfig}
        data={(props) => {
          return data({
            fields: {
              ...props.fields,
              ..._fields
            },
            setFields,
            methods: {
              ...props.methods,
              ...noDeploymentStrategyMethods
            },
            reset: () => {
              props.reset()
              fetchWithdrawalFeePercentage()
            },
            setLoading
          })
        }}
      />
    </>
  )
}

export default NoDeploymentStrategy
