import React from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import { useMessages } from './MessagesContext'

const TIME_OUT = 4000

const MessageRender: React.FC = () => {
  const { messages, removeMessage } = useMessages()

  const expire = React.useCallback(
    (index: number) => {
      setTimeout(() => {
        removeMessage(index)
      }, TIME_OUT)
    },
    [removeMessage]
  )

  React.useEffect(() => {
    if (messages.length > 0) {
      let indexCounter = 0
      while (indexCounter < messages.length) {
        if (messages[indexCounter].type !== 'error') {
          expire(indexCounter)
          break
        } else {
          indexCounter++
        }
      }
    }
  }, [expire, messages])

  return (
    <div className={'messageContainer'}>
      {messages.map((msg, msg_index) => {
        return (
          <div key={msg_index} className={`message message-${msg.type} fadeIn px-4 shadow-xl`}>
            <Row className="mt-2">
              <Col className="d-flex align-items-center title-col">
                <h5 className="mb-0">{msg.title}</h5>
              </Col>
              <Col className="col-2 d-flex align-items-start justify-content-end exit-col">
                <Button
                  className="exit-button p-1 py-0"
                  onClick={() => {
                    removeMessage(msg_index)
                  }}
                >
                  <i className="bi bi-x-circle" />
                </Button>
              </Col>
              <Row className="my-1">
                <Col>
                  <p className="mb-0">{msg.message}</p>
                </Col>
              </Row>
            </Row>
          </div>
        )
      })}
    </div>
  )
}

export default MessageRender
