import React from 'react'
import ScanLink from '../../../components/ScanLink'
import { Table } from 'react-bootstrap'
import { useConfig } from '../../../utils/ConfigProvider/ConfigContext'
import { EventLog } from '../../../helpers/scanEvents/ScanSdk'
import { ethers } from 'ethers'

type Props = {
  isLoading: boolean
  eventLog: EventLog[]
}

const LiquidationLayout: React.FC<Props> = ({ isLoading, eventLog }) => {
  const { config } = useConfig()

  function getTokenName(address: string) {
    const tokenInfo = Object.entries(config.tokens).find(([, token]) => {
      return token.address.toLowerCase() === address.toLowerCase()
    })
    return tokenInfo?.[0] || address
  }

  return (
    <div
      style={{
        marginTop: '75px'
      }}
    >
      <Table striped bordered hover responsive bsPrefix="table">
        <thead>
          <tr>
            <th>Address</th>
            <th>Token</th>
            <th>Date</th>
            <th>Usd Amount</th>
            <th>Asset Price</th>
            <th>Collateral Ratio</th>
            <th>Assets Liquidated</th>
            <th>Interest</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <div style={{ margin: '5px', background: 'transparent' }}>
                <span>Loading ...</span>
              </div>
            </tr>
          ) : eventLog.length === 0 ? (
            <tr>No Events found.</tr>
          ) : (
            eventLog.map((event, event_index) => {
              return (
                <tr key={event_index}>
                  <td>
                    <ScanLink search={event.account} type="address" />
                  </td>
                  <td>{getTokenName(event.token)}</td>
                  <td style={{ whiteSpace: 'nowrap' }}>{event.timestamp}</td>
                  <td>{event.data['Usd Amount']}</td>
                  <td>{(parseFloat(event.data['Asset Price'] as string) * 10).toFixed(8)}</td>
                  <td>{event.data['Collateral Ratio']}</td>
                  <td>{event.data['Assets Liquidated']}</td>
                  <td>N/A</td>
                </tr>
              )
            })
          )}
        </tbody>
      </Table>
    </div>
  )
}

export default LiquidationLayout
