import React from 'react'
import fetch from 'node-fetch'
import { ConfigContext } from './ConfigContext'
import { useMessages } from '../MessageProvider/MessagesContext'
import { useWallet } from '../WalletProvider/WalletContext'
import { Button, Col, Row } from 'react-bootstrap'
import appv2Config from '../../config/contracts.appv2'
import demoConfig from '../../config/contracts.demo'

const CONFIG_URL = 'https://config.rampdefi.com/config/contracts/:chainId/:appId'

type Props = {
  appId: string
}

export type ContractConfig = {
  address: string
  abi: any[]
}

export type TokenContractConfig = ContractConfig & {
  strategy: ContractConfig & {
    name: string
  }
}

export type AccountType = 'validator' | 'operator'

export type AccountConfig = {
  address: string
  type: AccountType
}

export type ConfigResult = {
  accounts: Record<string, AccountConfig>
  bridge: Record<string, ContractConfig>
  contracts: Record<string, ContractConfig>
  tokens: Record<string, TokenContractConfig>
}

const ConfigProvider: React.FC<Props> = ({ appId, children }) => {
  const { wallet } = useWallet()
  const [loading, setLoading] = React.useState(true)
  const [_config, setConfig] = React.useState<ConfigResult>({} as ConfigResult)

  React.useEffect(() => {
    setLoading(true)
    // let isActive = true
    console.log(appId)
    let config
    if (appId === 'appv2') config = appv2Config
    else if (appId === 'demo') config = demoConfig
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setConfig(config[wallet.network] || config[56]) // if no network, default to network `1`
    setLoading(false)
    // let url = CONFIG_URL.replace(':chainId', wallet.network + '')
    // url = url.replace(':appId', appId)
    // fetch(url)
    //   .then((res) => res.json())
    //   .then((config) => {
    //     if (!isActive) return
    //     setConfig(config)
    //   })
    //   .catch(async (err) => {
    //     if (!isActive) return
    //     addMessage({
    //       title: 'Config Provider',
    //       type: 'error',
    //       message: err.message
    //     })
    //     setConfig({} as ConfigResult)
    //   })
    //   .finally(() => {
    //     if (!isActive) return
    //     setLoading(false)
    //   })

    // return () => {
    //   isActive = false
    // }
  }, [appId, wallet.network])

  return (
    <ConfigContext.Provider value={{ config: _config }}>
      {loading ? (
        <div className="loading-container">
          <div className="loadingio-spinner-eclipse-80ful76r3w3">
            <div className="ldio-qmca8kj37i">
              <div></div>
            </div>
          </div>
          <div className="loading-text">
            <h6>Loading Config</h6>
          </div>
        </div>
      ) : Object.values(_config).length === 0 ? (
        <div className="auth-main">
          <div className="maintenance-page-container">
            <Row>
              <Col className="maintenance-ramp-logo">
                <img className="ramp-logo" src="https://rampdefi.com/icons/ramp.svg" alt="ramp-logo" />
              </Col>
            </Row>
            <Row className="maintenance-details">
              <Col>
                <h3 className="text-detail">No config values, please try again.</h3>
                <Button
                  className="maintenance-refresh-button"
                  onClick={() => {
                    location.reload()
                  }}
                >
                  Try again
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        children
      )}
    </ConfigContext.Provider>
  )
}

export default ConfigProvider
