import React from 'react'
import { useWallet } from '../utils/WalletProvider/WalletContext'
import { Button, Col, Row } from 'react-bootstrap'

const AuthenticationPage: React.FC = () => {
  const wallet = useWallet()

  return (
    <div className="auth-main">
      <div className="authentication-page-container">
        <Row>
          <Col className="authentication-ramp-logo">
            <img className="ramp-logo" src="https://rampdefi.com/icons/ramp.svg" alt="ramp-logo" />
          </Col>
        </Row>
        <Row className="authentication-details">
          {/*<Col>*/}
          {/*  <h4>Welcome to Dashboard</h4>*/}
          {/*</Col>*/}
          <Col>
            <Button
              className="connect-button"
              onClick={() => {
                wallet.connect('metamask').then()
              }}
            >
              <img
                className="metamask-icon"
                src="https://cdn.iconscout.com/icon/free/png-512/metamask-2728406-2261817.png"
                alt="metamask-icon"
              />
              Connect To Metamask
            </Button>
          </Col>
        </Row>
        {/*<div className="banner-background">*/}
        {/*  <div className="banner-front">/!*Silent*!/</div>*/}
        {/*  <img src="https://appv2.rampdefi.com/assets/images/hero_banner@2x.png" alt="ramp-banner"/>*/}
        {/*</div>*/}
      </div>
    </div>
  )
}

export default AuthenticationPage
