import React, { useCallback } from 'react'
import { Message, MessagesContext } from './MessagesContext'

type AddMessage = {
  message: Message
}

type RemoveMessage = {
  index: number
}

const MessagesProvider: React.FC = ({ children }) => {
  const [messages, setMessage] = React.useReducer<React.Reducer<Message[], AddMessage | RemoveMessage>>(
    (messages, action) => {
      const newMessages = [...messages]

      if ((action as AddMessage).message) {
        const _act = action as AddMessage
        newMessages.push(_act.message)
      } else {
        const _act = action as RemoveMessage
        newMessages.splice(_act.index, 1)
      }

      return newMessages
    },
    []
  )

  const addMessage = useCallback(
    (message: Message) => {
      setMessage({
        message
      })
    },
    [setMessage]
  )

  const removeMessage = useCallback(
    (index: number) => {
      setMessage({
        index
      })
    },
    [setMessage]
  )

  const contextValue = React.useMemo(() => {
    return {
      messages,
      addMessage,
      removeMessage
    }
  }, [messages, addMessage, removeMessage])

  return <MessagesContext.Provider value={contextValue}>{children}</MessagesContext.Provider>
}

export default MessagesProvider
