import React from 'react'
import { WalletNames } from './WalletWrapper'
import Sdk from './Sdk'

export const WalletContext = React.createContext({
  connected: false,
  wallet: {
    address: '',
    network: 0,
    provider: null as any
  },
  sdk: new Sdk(),
  connect: (name: WalletNames): Promise<void> => {
    return new Promise((resolve) => resolve(undefined))
  },
  isConnecting: false
})

export const useWallet = () => {
  // TODO: if (context) throw new Error('You probably forgot to use <WalletProvider>.')
  return React.useContext(WalletContext)
}
