import { ContractConfig } from '../../../utils/ConfigProvider'
import { useCallback, useState, useMemo } from 'react'
import { useWallet } from '../../../utils/WalletProvider/WalletContext'
import { useConfig } from '../../../utils/ConfigProvider/ConfigContext'
import { Header, LinkedValue, Row } from '../../../components/Table/types'
import _ from 'lodash'
import { BigNumber, ethers } from 'ethers'
import { formatEther, formatUnits } from 'ethers/lib/utils'

const newBscStrategyLabels: Record<string, { id: string; name: string }> = {
  Bsc_1: {
    id: 'BSC 1',
    name: 'Ramp Staking Strategy'
  },
  Bsc_2: {
    id: 'BSC 2',
    name: 'RAMP BUSD LP Strategy'
  },
  Bsc_3: {
    id: 'BSC 3',
    name: 'rUSD BUSD LP Strategy'
  },
  Bsc_4: {
    id: 'BSC 4',
    name: 'NRV BNB Strategy'
  },
  Bsc_5: {
    id: 'BSC 5',
    name: 'NRV rUSD Strategy'
  },
  Bsc_6: {
    id: 'BSC 6',
    name: 'INJ BNB Strategy'
  },
  Bsc_7: {
    id: 'BSC 7',
    name: 'Cake Accruing Strategy'
  },
  Bsc_8: {
    id: 'BSC 8',
    name: 'NULS BUSD LP Strategy'
  },
  Bsc_9: {
    id: 'BSC 9',
    name: 'MCoin UST Strategy'
  },
  Bsc_10: {
    id: 'BSC 10',
    name: 'MIR UST Strategy'
  },
  Bsc_11: {
    id: 'BSC 11',
    name: 'UST BUSD Strategy'
  },
  Bsc_12: {
    id: 'BSC 12',
    name: 'IOTX BUsd Strategy'
  },
  Bsc_13: {
    id: 'BSC 13',
    name: 'WBNB Alpaca Accruing Strategy'
  },
  Bsc_14: {
    id: 'BSC 14',
    name: 'BUSD Alpaca Accruing Strategy'
  },
  Bsc_15: {
    id: 'BSC 15',
    name: 'USDT Alpaca Accruing Strategy'
  },
  Bsc_16: {
    id: 'BSC 16',
    name: 'ETH Alpaca Accruing Strategy'
  },
  Bsc_17: {
    id: 'BSC 17',
    name: 'rUSD Strategy'
  },
  Bsc_18: {
    id: 'BSC 18',
    name: 'CAKE BNB LP Strategy'
  },
  Bsc_19: {
    id: 'BSC 19',
    name: 'BNB BUSD LP Strategy'
  },
  Bsc_20: {
    id: 'BSC 20',
    name: 'USDT BNB LP Strategy'
  },
  Bsc_21: {
    id: 'BSC 21',
    name: 'ETH BNB LP Strategy'
  },
  Bsc_22: {
    id: 'BSC 22',
    name: 'BTCB BNB LP Strategy'
  },
  Bsc_23: {
    id: 'BSC 23',
    name: 'CAKE BUSD LP Strategy'
  },
  Bsc_24: {
    id: 'BSC 24',
    name: 'BNB LINK LP Strategy'
  },
  Bsc_25: {
    id: 'BSC 25',
    name: 'TRX BNB LP Strategy'
  },
  Bsc_26: {
    id: 'BSC 26',
    name: 'DOT BNB LP Strategy'
  },
  Bsc_27: {
    id: 'BSC 27',
    name: 'CAKE USDT LP Strategy'
  },
  Bsc_28: {
    id: 'BSC 28',
    name: 'DOGE BNB LP Strategy'
  },
  Bsc_29: {
    id: 'BSC 29',
    name: 'BTCB Strategy'
  },
  Bsc_30: {
    id: 'BSC 30',
    name: 'LINK Strategy'
  },
  Bsc_31: {
    id: 'BSC 31',
    name: 'BTCB ETH LP Strategy'
  },
  Bsc_32: {
    id: 'BSC 32',
    name: 'BTCB BUSD LP Strategy'
  },
  Bsc_33: {
    id: 'BSC 33',
    name: 'ADA BNB LP Strategy'
  },
  Bsc_34: {
    id: 'BSC 34',
    name: 'XVS BNB LP Strategy'
  },
  Bsc_35: {
    id: 'BSC 35',
    name: 'BETA BNB LP Strategy'
  },
  Bsc_36: {
    id: 'BSC 36',
    name: 'AXS BNB LP Strategy'
  },
  Bsc_37: {
    id: 'BSC 37',
    name: 'RUSD RAMP LP Strategy'
  }
}

const newPolygonStrategyLabels: Record<string, { id: string; name: string }> = {
  Polygon_1: {
    id: 'Polygon 1',
    name: 'Ramp Staking Strategy'
  },
  Polygon_2: {
    id: 'Polygon 2',
    name: 'Matic/ETH Strategy'
  },
  Polygon_3: {
    id: 'Polygon 3',
    name: 'Ramp/ETH Strategy'
  },
  Polygon_4: {
    id: 'Polygon 4',
    name: 'rUSD/USDC Strategy'
  },
  Polygon_5: {
    id: 'Polygon 5',
    name: 'Matic/USDC Strategy'
  },
  Polygon_6: {
    id: 'Polygon 6',
    name: 'wBTC/USDC Strategy'
  },
  Polygon_7: {
    id: 'Polygon 7',
    name: 'DAI/wETH Strategy'
  },
  Polygon_8: {
    id: 'Polygon 8',
    name: 'ETH/USDC Strategy'
  },
  Polygon_9: {
    id: 'Polygon 9',
    name: 'LINK/ETH Strategy'
  },
  Polygon_10: {
    id: 'Polygon 10',
    name: 'rUSD Strategy'
  },
  Polygon_11: {
    id: 'Polygon 11',
    name: 'USDC/QUICK Strategy'
  },
  Polygon_12: {
    id: 'Polygon 12',
    name: 'ETH/QUICK Strategy'
  },
  Polygon_13: {
    id: 'Polygon 13',
    name: 'MATIC/QUICK Strategy'
  },
  Polygon_14: {
    id: 'Polygon 14',
    name: 'wMatic Strategy'
  },
  Polygon_15: {
    id: 'Polygon 15',
    name: 'wEth Strategy'
  },
  Polygon_16: {
    id: 'Polygon 16',
    name: 'wBtc Strategy'
  }
}

const newEthStrategyLabels: Record<string, { id: string; name: string }> = {
  Eth_1: {
    id: 'ETH 1',
    name: 'Ramp Staking Strategy'
  },
  Eth_2: {
    id: 'ETH 2',
    name: 'RAMP/ETH Uniswap'
  }
}

const newAvaxStrategyLabels: Record<string, { id: string; name: string }> = {
  Avax_1: {
    id: 'Avax 1',
    name: 'wAVAX Strategy'
  }
}

const networkLinkPrefix = {
  1: 'https://etherscan.io',
  56: 'https://bscscan.com',
  31337: 'https://bscscan.com',
  137: 'https://polygonscan.com',
  43114: 'https://snowtrace.io/'
}

const headersOrder = [
  'strategy',
  'token',
  'baseStrategyFields',
  'bonusPoolBank',
  'bonusPoolVault',
  'cakeAccruingStrategy',
  'cakeLPStrategy',
  'strategyPoolInfo'
]
const initialHeaders = [
  {
    id: 'strategy',
    name: 'Strategy'
  },
  {
    id: 'token',
    name: 'Token'
  },
  {
    id: 'baseStrategyFields',
    name: 'Base Strategy Fields',
    subHeaders: []
  }
]

const useFetchStrategies = () => {
  const [headers, setHeaders] = useState<Array<Header>>(initialHeaders)
  const { wallet, sdk } = useWallet()
  const {
    config: { tokens, contracts }
  } = useConfig()
  const { network } = wallet
  const [error, setError] = useState<any | null>(null)
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const newStrategyLabels =
    network === 56 || network === 31337
      ? newBscStrategyLabels
      : network === 137
      ? newPolygonStrategyLabels
      : network === 43114
      ? newAvaxStrategyLabels
      : newEthStrategyLabels

  const tokenEntries = Object.entries(tokens)

  const transformDataMethod = useMemo(() => {
    return {
      poolId: (result: any) => {
        if (BigNumber.isBigNumber(result)) return formatUnits(result, 'wei')
        return result?.toString() ?? ''
      },
      devFeePercentage: (result: any) => {
        if (BigNumber.isBigNumber(result)) return `${formatUnits(result, 'wei')}%`
        return result?.toString() ?? ''
      },
      settingsInterest: (result: any) => {
        if (BigNumber.isBigNumber(result)) return ethers.utils.commify(formatUnits(result, '6'))
        return result?.toString() ?? ''
      },
      lifecycleState: (result: any) => {
        const lifeCycle = parseInt(result)
        switch (lifeCycle) {
          case 0:
            return 'Deploying'
          case 1:
            return 'Paused'
          case 2:
            return 'Inactive'
          case 3:
            return 'Active'
          case 4:
            return 'MassWithdraw'
          case 5:
            return 'Withdrawing'
          default:
            return result?.toString() ?? ''
        }
      },
      interestRate: (result: any) => {
        if (BigNumber.isBigNumber(result)) result = formatEther(result)
        if (typeof result === 'number') result = result.toLocaleString(undefined, { minimumFractionDigits: 0 })
        if (typeof result === 'string') {
          result = parseFloat(result)
          if (isNaN(result)) return '-----'
          return `${result}%`
        } else return result?.toString() ?? ''
      },
      interestUpdatedTimestamp: (result: any) => {
        if (typeof result === 'number') {
          result = result * 1_000
          result = new Date(result)
          return result.toUTCString()
        }
        if (BigNumber.isBigNumber(result)) {
          result = result.mul(1_000)
          result = new Date(formatEther(result))
          return result.toUTCString()
        } else return result?.toString() ?? ''
      },
      rTokenAddress: (result: any) => {
        if (result === '-----') return result
        if (typeof result === 'string') {
          const link = `${_.get(networkLinkPrefix, network, '')}/token/${result}`
          const truncatedString = result.substring(0, 6)
          return new LinkedValue(link, `${truncatedString}...`)
        }
        return result?.toString() ?? ''
      },
      default: (result: any) => {
        if (typeof result === 'string') return result
        if (BigNumber.isBigNumber(result)) return ethers.utils.commify(formatEther(result))
        if (typeof result === 'number') return result.toLocaleString(undefined, { minimumFractionDigits: 0 })
        return result?.toString() ?? ''
      }
    }
  }, [network])

  const [rows, setRows] = useState<Array<Row>>(
    tokenEntries.map(([tokenName, tokenConfig]) => {
      const currentStrategy = newStrategyLabels[tokenConfig.strategy.name]
      return {
        id: tokenName,
        token: tokenName,
        strategy: currentStrategy.name,
        label: `(${currentStrategy.id}) ${currentStrategy.name}`
      }
    })
  )

  const fetchInfo = useCallback(
    (
      contract: ContractConfig | null,
      method: string,
      args: Array<any> = [],
      mappingArgs?: { key?: string; toOmit?: Array<string> | string; toPick?: Array<string> | string }
    ) => {
      if (!contract) return Promise.reject('undefined contract')
      return sdk
        .send(contract.address, method, args, {
          abi: contract.abi,
          mapOutput: mappingArgs?.key == null
        })
        .then((result) => {
          if (!mappingArgs) return result
          const { key, toOmit, toPick } = mappingArgs
          let toReturn: string | Record<string, any> = result
          if (key) toReturn = { [key]: toReturn }
          if (toOmit && typeof toReturn !== 'string') toReturn = _.omit(toReturn, toOmit)
          if (toPick && typeof toReturn !== 'string') toReturn = _.pick(toReturn, toPick)
          if (typeof toReturn === 'string') toReturn = { info: toReturn }
          return toReturn
        })
    },
    []
  )

  const setData = (data: Record<string, any>, rowId: string, headerId = 'baseStrategyFields') => {
    data = _.mapKeys(data, function (value, key) {
      return `${headerId}-${key}`
    })
    setRows((prevRows) => {
      const currentRow = prevRows.find((pr) => pr.id === rowId) ?? { id: 'cannotDetermine' }

      const updatedRow = {
        ...currentRow,
        ...data
      }
      return prevRows.map((pr) => {
        if (pr.id !== rowId) return pr
        return updatedRow
      })
    })

    setHeaders((prevHeaders) => {
      const currentHeader = prevHeaders.find((ph) => ph.id === headerId)
      const resultHeaders = Object.keys(data)
      const currentSubHeaders = currentHeader?.subHeaders ?? []
      const mSubHeaders = currentSubHeaders.map((ph) => (typeof ph.id === 'number' ? ph.id.toString() : ph.id))
      const newSubHeaders = _.difference(resultHeaders, mSubHeaders)

      if (!newSubHeaders.length) return prevHeaders

      const newSubHeadersToPush = newSubHeaders.map((nh: string) => {
        const name = nh.replace(`${headerId}-`, '')
        const transformData = _.get(transformDataMethod, name, transformDataMethod.default)
        return {
          id: nh,
          name,
          transformData
        }
      })

      if (!currentHeader) {
        const newHeader = {
          id: headerId,
          name: headerId,
          subHeaders: newSubHeadersToPush
        }
        const lastIndex = prevHeaders.length - 1
        const lastHeader = prevHeaders[lastIndex]
        const lastHeaderOrder = headersOrder.findIndex((ho) => ho === lastHeader.id)

        const elementIndex = headersOrder.findIndex((ho) => ho === newHeader.id)

        if (elementIndex > lastHeaderOrder) return [...prevHeaders, newHeader]

        if (elementIndex === lastHeaderOrder) return [...prevHeaders]

        const left = prevHeaders.filter((ph) => {
          const phOrder = headersOrder.findIndex((ho) => ho === ph.id)
          return phOrder < elementIndex
        })

        const right = prevHeaders.filter((ph) => {
          const phOrder = headersOrder.findIndex((ho) => ho === ph.id)
          return phOrder > elementIndex
        })

        return left.concat([newHeader], right)
      }

      return prevHeaders.map((ph) => {
        if (ph.id !== headerId) return ph
        const currentSubHeaders = ph?.subHeaders ?? []
        return {
          ...ph,
          subHeaders: [...currentSubHeaders, ...newSubHeadersToPush]
        }
      })
    })
  }

  const fetchStrategies = useCallback(async () => {
    setLoading(true)
    const delay = (time: number) => {
      return new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve()
        }, time)
      })
    }

    let totalPromisesLoaded = 0
    const promisesToLoad = tokenEntries.length * 5

    const bankTokenInfoToOmit = ['oracle', 'bridged', 'interestReceived', 'accInterestPerShare']
    const vaultTokenInfoToOmit = ['rToken', 'decimals', 'exists', 'bridged']

    for (const [tokenName, tokenConfig] of tokenEntries) {
      // base strategy fields
      await delay(100)
      Promise.allSettled([
        fetchInfo(contracts.Bank, 'tokens', [tokenConfig.address], { toOmit: bankTokenInfoToOmit }),
        fetchInfo(contracts.Vault, 'tokens', [tokenConfig.address], { toOmit: vaultTokenInfoToOmit }),
        fetchInfo(tokenConfig.strategy, 'devFeePercentage', [], { key: 'devFeePercentage' })
      ])
        .then((results) => {
          let data: Record<string, any> = {}
          results.forEach((r) => {
            if (r.status === 'fulfilled') data = { ...data, ...r.value }
          })
          setData(data, tokenName)
        })
        .catch((e) => {
          if (e?.code === error?.code) return
          setError(e)
        })
        .finally(() => {
          totalPromisesLoaded++
          setProgress((totalPromisesLoaded / promisesToLoad) * 100)
          if (totalPromisesLoaded !== promisesToLoad) return
          setLoading(false)
        })

      await delay(100)
      fetchInfo(tokenConfig.strategy, 'tokenPoolInfo', [tokenConfig.address])
        .then((result) => setData(result, tokenName, 'cakeLPStrategy'))
        .catch((e) => {
          if (e?.code === error?.code) return
          setError(e)
        })
        .finally(() => {
          totalPromisesLoaded++
          setProgress((totalPromisesLoaded / promisesToLoad) * 100)
          if (totalPromisesLoaded !== promisesToLoad) return
          setLoading(false)
        })

      await delay(100)
      fetchInfo(tokenConfig.strategy, 'poolInfo', [])
        .then((result) => setData(result, tokenName, 'strategyPoolInfo'))
        .catch((e) => {
          if (e?.code === error?.code) return
          setError(e)
        })
        .finally(() => {
          totalPromisesLoaded++
          setProgress((totalPromisesLoaded / promisesToLoad) * 100)
          if (totalPromisesLoaded !== promisesToLoad) return
          setLoading(false)
        })

      await delay(100)
      fetchInfo(contracts?.BonusPool_Bank, 'poolInfo', [tokenConfig.address], { toPick: 'rewardsPerYear' })
        .then((result) => {
          setData(result, tokenName, 'bonusPoolBank')
        })
        .catch((e) => {
          if (e?.code === error?.code) return
          setError(e)
        })
        .finally(() => {
          totalPromisesLoaded++
          setProgress((totalPromisesLoaded / promisesToLoad) * 100)
          if (totalPromisesLoaded !== promisesToLoad) return
          setLoading(false)
        })

      await delay(100)
      fetchInfo(contracts?.BonusPool_Vault, 'poolInfo', [tokenConfig.address], { toPick: 'rewardsPerYear' })
        .then((result) => {
          setData(result, tokenName, 'bonusPoolVault')
        })
        .catch((e) => {
          if (e?.code === error?.code) return
          setError(e)
        })
        .finally(() => {
          totalPromisesLoaded++
          setProgress((totalPromisesLoaded / promisesToLoad) * 100)
          if (totalPromisesLoaded !== promisesToLoad) return
          setLoading(false)
        })
    }
  }, [])

  return {
    table: {
      headers,
      rows
    },
    states: {
      error,
      loading,
      progress
    },
    fetchStrategies
  }
}

export default useFetchStrategies
