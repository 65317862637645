import React from 'react'
import { Row } from 'react-bootstrap'
import StrategyContainer from './StrategyContainer'
import ContainerLabeler from '../../components/ContainerLabeler'
import { useWallet } from '../../utils/WalletProvider/WalletContext'
import RampAppSetting from './RampAppSetting/RampAppSetting'
import { useLocation } from 'react-router-dom'

const Protocol: React.FC = () => {
  const { wallet } = useWallet()
  const { pathname } = useLocation()
  return (
    <Row>
      {wallet.network === 56 ? (
        !pathname.includes('demo') ? (
          <>
            {/*<Row>*/}
            {/*  <ContainerLabeler label={'Web App Settings'}>*/}
            {/*    <WebAppSettingWrapper />*/}
            {/*  </ContainerLabeler>*/}
            {/*</Row>*/}
            <Row>
              <ContainerLabeler label={'Ramp Settings'}>
                <RampAppSetting />
              </ContainerLabeler>
            </Row>
          </>
        ) : undefined
      ) : undefined}

      <Row>
        <ContainerLabeler label={'Strategies'}>
          <StrategyContainer />
        </ContainerLabeler>
      </Row>
    </Row>
  )
}

export default Protocol
