import { ethers } from 'ethers'
import retryFetch from '../../utils/retryFetch.js'
import { requestLogs, requestNativeBalance, requestTokenBalance, requestTokenTotalSupply } from './builder.js'

function retryLimit(request) {
  return retryFetch(request)
    .then((res) => res.json())
    .then(({ result }) => {
      if (result.includes('Max rate limit reached')) {
        console.log('Eth/Bsc hitting max rate limit')
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(retryLimit(request))
          }, 1000)
        })
      }
      return result
    })
    .catch((err) => {
      if (err.message.includes('Unexpected token < in JSON at position 0')) {
        console.debug('Polygon hitting max rate limit')
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(retryLimit(request))
          }, 1000)
        })
      }
      throw err
    })
}

export async function fetchTokenSupply(network, tokenAddr) {
  return retryLimit(requestTokenTotalSupply(network, tokenAddr)).then((balance) => ethers.utils.formatUnits(balance))
}

export async function fetchTokenBalance(network, tokenAddr, accountAddr) {
  return retryLimit(requestTokenBalance(network, tokenAddr, accountAddr)).then((balance) =>
    ethers.utils.formatUnits(balance)
  )
}

export async function fetchNativeBalance(network, accountAddr) {
  return retryLimit(requestNativeBalance(network, accountAddr)).then((balance) => ethers.utils.formatUnits(balance))
}

export async function fetchLogs(network, contractAddr, fromBlock, toBlock, topics) {
  return retryLimit(requestLogs(network, contractAddr, fromBlock, toBlock, topics))
}

export async function fetchLogsMaxHandler(network, contractAddr, fromBlock, toBlock, topics) {
  let currentFromBlock = fromBlock
  let resultStorage = []
  let stopper = true
  while (stopper) {
    const result = await fetch(requestLogs(network, contractAddr, currentFromBlock, toBlock, topics))
    const data = await result.json()

    resultStorage = resultStorage.concat(data.result)
    currentFromBlock = parseInt(data.result[data.result.length - 1]?.blockNumber)

    if (data.result.length < 999) stopper = false
  }
  return resultStorage
}
