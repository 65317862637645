import Multicall from '../models/Mutlicall'

const BALANCE_OF = {
  abi: ['function balanceOf(address account) view returns (uint256)'],
  method: 'balanceOf'
}

export function pushDefaultTransactions(address: string, accounts: Array<string>, multicall: Multicall) {
  for (const account of accounts) {
    multicall.add({
      address,
      abi: BALANCE_OF.abi,
      method: BALANCE_OF.method,
      args: [account]
    })
  }
}
