import { Header } from '../../types'
import { ChangeEvent, useState } from 'react'
import { Dropdown, Form } from 'react-bootstrap'
import { toTitle } from '../../helpers'
import classes from './index.module.css'
import { useHiddenHeaders, useHiddenHeadersActions } from '../../index'

interface Props {
  headers: Array<Header>
  isToTitle?: boolean
}

const ColumnFilters: React.FC<Props> = ({ headers, isToTitle = true }) => {
  const hiddenHeaders = useHiddenHeaders()
  const { hide, show } = useHiddenHeadersActions()

  const handleChange = (value: ChangeEvent<HTMLInputElement>) => {
    if (value.target.checked) show(value.target.id)
    else hide(value.target.id)
  }

  return (
    <Dropdown autoClose={'outside'} className={'d-inline'}>
      <Dropdown.Toggle>Columns</Dropdown.Toggle>
      <Dropdown.Menu className={classes.columnFilter}>
        {headers.map((h) => {
          const sId = typeof h.id === 'number' ? h.id.toString() : h.id
          return (
            <>
              <Form.Check
                type={'checkbox'}
                label={isToTitle ? toTitle(h.name) : h.name}
                name={h.name}
                id={sId}
                onChange={handleChange}
                checked={!hiddenHeaders.includes(h.id)}
                key={h.id}
                className={classes.item}
              />
              {h.subHeaders?.length && (
                <div className={classes.moveLeft}>
                  {h?.subHeaders.map((sh) => {
                    const sId = typeof sh.id === 'number' ? sh.id.toString() : sh.id
                    return (
                      <Form.Check
                        type={'checkbox'}
                        label={isToTitle ? toTitle(sh.name) : sh.name}
                        key={sh.id}
                        id={sId}
                        checked={!hiddenHeaders.includes(sh.id)}
                        onChange={handleChange}
                        className={classes.item}
                      />
                    )
                  })}
                </div>
              )}
            </>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default ColumnFilters
