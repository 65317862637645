import fetch from 'node-fetch'

export function getCurrentTimeStamp(daysBefore?: number): number {
  const currentTimestamp = new Date()
  if (daysBefore) return parseInt((currentTimestamp.setDate(currentTimestamp.getDate() - daysBefore) / 1000).toString())
  return Math.floor(new Date().getTime() / 1000.0)
}

export async function getBlockNumberFromTimestamp(chainId: number, timestamp: number) {
  let url

  switch (chainId) {
    case 56:
      url = `https://api.bscscan.com/api?module=block&action=getblocknobytime&timestamp=${timestamp}&closest=before&apikey=${process.env.REACT_APP_BSC_API_KEY}`
      break
    case 137:
      url = `https://api.polygonscan.com/api?module=block&action=getblocknobytime&timestamp=${timestamp}&closest=before&apikey=${process.env.REACT_APP_POLYGON_API_KEY}`
      break
    case 1:
      url = `https://api.etherscan.io/api?module=block&action=getblocknobytime&timestamp=${timestamp}&closest=before&apikey=${process.env.REACT_APP_ETH_API_KEY}`
      break
    case 43114:
      url = `https://api.snowtrace.io/api?module=block&action=getblocknobytime&timestamp=${timestamp}&closest=before&apikey=${process.env.REACT_APP_AVAX_API_KEY}`
      break
    default:
      console.error(`Chaid ID of ${chainId} is not supported`)
      break
  }

  const data = await fetch(url as string)
  const result = (await data.json()).result

  return parseInt(result)
}
