import { useHistory, useLocation } from 'react-router-dom'

export function searchToJson(str: string): Record<string, string> {
  const search: Record<string, string> = {}
  const splitSearch = str.split('&')
  for (const item of splitSearch) {
    const [key, value] = item.split('=')
    search[key] = value
  }
  return search
}

export function jsonToSearch(json: Record<string, string>): string {
  let composedSearch = ''
  Object.entries(json).forEach(([key, value], index) => {
    composedSearch += `${key}=${value}`
    if (index !== Object.entries(json).length - 1) {
      composedSearch += '&'
    }
  })
  return composedSearch
}

export function useQuery() {
  const history = useHistory()

  const setQuery = (key: string, value: string) => {
    if (history.location.search === '') {
      history.push({
        search: `${key}=${value}`
      })
    }
    const searchJson = searchToJson(history.location.search.substring(1))
    searchJson[key] = value
    history.push({
      search: jsonToSearch(searchJson)
    })
  }

  return {
    query: searchToJson(useLocation().search.substring(1)),
    setQuery
  }
}
