import hosts from './hosts.js'

export function requestTokenTotalSupply(network, tokenAddr) {
  const config = hosts[network]
  const url = new URL(config.url)
  url.searchParams.append('module', 'stats')
  url.searchParams.append('action', 'tokensupply')
  url.searchParams.append('contractaddress', tokenAddr)
  url.searchParams.append('apikey', config.key)
  return url.href
}

export function requestTokenBalance(network, tokenAddr, accountAddr) {
  const config = hosts[network]
  const url = new URL(config.url)
  url.searchParams.append('module', 'account')
  url.searchParams.append('action', 'tokenbalance')
  url.searchParams.append('contractaddress', tokenAddr)
  url.searchParams.append('address', accountAddr)
  url.searchParams.append('tag', 'latest')
  url.searchParams.append('apikey', config.key)
  return url.href
}

export function requestNativeBalance(network, accountAddr) {
  const config = hosts[network]
  const url = new URL(config.url)
  url.searchParams.append('module', 'account')
  url.searchParams.append('action', 'balance')
  url.searchParams.append('address', accountAddr)
  url.searchParams.append('tag', 'latest')
  url.searchParams.append('apikey', config.key)
  return url.href
}

export function requestLogs(network, contractAddr, fromBlock, toBlock, topics) {
  console.log(`fromBlock of ${topics[0]}: `, `${fromBlock} to ${toBlock}`)
  const config = hosts[network]
  const url = new URL(config.url)
  url.searchParams.append('module', 'logs')
  url.searchParams.append('action', 'getLogs')
  url.searchParams.append('address', contractAddr)
  url.searchParams.append('fromBlock', fromBlock)
  url.searchParams.append('toBlock', toBlock)
  url.searchParams.append('apikey', config.key)
  for (let index in topics) {
    if (topics[index]) url.searchParams.append('topic' + index, topics[index])
  }
  return url.href
}

// https://api.bscscan.com/api?module=logs& action=getLogs &fromBlock=6525513& toBlock=latest  &address=0xb1660202d4f14709cfdd835E0490b0C68321325f &apikey=2UG94AMVW4HD3Y5FIWY8Y9F6988I7AFMT2 &topic0=0xe172bdfb015d23d2f483f0d54914dd2d4a7d6fe181476343880d299d827566ae&topic1=0x0000000000000000000000000E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82
// https://api.bscscan.com/api?module=logs& action=getLogs &fromBlock=4993830& toBlock=4993832 &address=0xe561479bebee0e606c19bb1973fc4761613e3c42 &topic0=0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef&apikey=YourApiKeyToken
